import React from 'react';
import { Box, Card, CardHeader, CardContent, Typography, Avatar } from '@mui/material';
import moment from 'moment';

interface ApplicationUsageCustomTooltipsProps {
    id: string;
    value: number;
    indexValue: string;
    datum: any; // or provide a more specific type if available
    color:string;
  }
const ApplicationUsageCustomTooltips: React.FC<any> = ({data,id,type,colorCode}:any) => {
  return (
    <Box
      sx={{
        position: 'relative',
        padding: '8px',
        background: 'black',
        border: '1px solid #000',
        borderRadius: '5px',
        color: '#fff',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderTop: '8px solid black',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-1px)',
          width: 0,
          height: 0,
          borderLeft: '7px solid transparent',
          borderRight: '7px solid transparent',
          borderTop: '7px solid #000',
        },
      }}
    >
      <Card sx={{ maxWidth: 345, backgroundColor: 'rgb(0,0,0,0.5)', color: '#fff', '&:hover': { backgroundColor: '#333' } }}>
      <Box sx={{ display:'flex',flexDirection:'column',rowGap:'10px', padding: '10px', backgroundColor: 'rgb(0,0,0,5%)', borderRadius: '5px' }}>
        <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{fontSize:'12px',color:'#C1B8B8'}}>Activity Period</Box>
            {data.type == "daily" &&
            <Box sx={{fontSize:'14px'}}>
              {moment(data.startDate).format('MMM-DD-YYYY')}
            </Box>}

            {data.type == "weekly" &&
              <Box style={{display:"flex",flexDirection:"column"}}>
                {data.label}
                <span style={{fontSize:"12px"}}>{`(${moment(data.startDate).format('MMM-DD-YYYY')} - ${moment(data.startEnd).format('MMM-DD-YYYY')})`}</span>
              </Box>
             }

            {data.type == "monthly" &&
              <Box style={{display:"flex",flexDirection:"column"}}>
                {data.label}
                <span style={{fontSize:"12px"}}>{`(${moment(data.startDate).format('MMM-DD-YYYY')} - ${moment(data.startEnd).format('MMM-DD-YYYY')})`}</span>
              </Box>
             }
        </Box>

        <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{fontSize:'12px',color:'#C1B8B8'}}>{type == "appTrendsRole" ? data.roleTypeFilter == "loggedIn" ? "Logged IN" : "Not Logged In" : "Logged In vs Not Logged In"}</Box>
            <Box sx={{fontSize:'14px'}}>{type == "appTrendsRole" ? data[id] : id == "notLoggedIn" ? data.notLoggedIn : data.loggedIn}</Box>
        </Box>

        <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{fontSize:'12px',color:'#C1B8B8'}}>Login Status</Box>
            <Box sx={{display:'flex',flexDirection:'row',columnGap:'10px',alignItems:'center',fontSize:'14px'}}><Box sx={{width:'10px',height:'10px',backgroundColor: type == "appTrendsRole" ? colorCode : id == "notLoggedIn" ? "#EA2D51" : "#4AB46E"}}></Box><Box>{type == "appTrendsRole" ? data.roleTypeFilter == "notLoggedIn" ? "Not Logged In" : "Logged In" : id == "notLoggedIn" ? "Not Logged In" : "Logged In"}</Box></Box>
        </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ApplicationUsageCustomTooltips;