import React, { useEffect, useState } from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { Paper, Typography, useMediaQuery, useTheme, Box, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomTooltip from '../../../Common/CustomTooltipComponent/CustomTooltip';
import { useTooltip, BasicTooltip } from '@nivo/tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import { getofferMgtEffectiveness } from '../../../../services/DashboardService';
import "./OfferManagementWidget.scss"
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { ReactComponent as DashboardLaunch } from '../../../../assets/icons/dashboardLaunch.svg'
// Define the type for your data
// interface DataItem extends BarDatum {
//   labelname: string;
//   value: number;
//   color: string;
//   evaulatepercentage: number;
// }

// CustomBar component
const CustomBar: React.FC<any> = ({
  bar: { x, y, width, height, color, data },
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();


  const handleMouseMove = (event: React.MouseEvent) => {
    
    const datum = data as any;
    console.log("eval :",datum)
    showTooltipFromEvent(
      <CustomTooltip
        id={datum.labelname}
        value={datum.value}
        color={color}
        label={datum.labelname}
        tooltipstatus={{
          enable: true,
          showstatuscolor: true,
          tooltipvaluewithpercentage: datum.value,
          evaulatepercentage: datum.evaulatepercentage,
        }}
        />,
      event
    );
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  const borderRadius = 10; // Adjust the radius as needed

  return (
    <g transform={`translate(${x}, ${y})`} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <rect
        x={0}
        y={borderRadius}
        width={width}
        height={height - borderRadius}
        fill={color}
        rx={0}
        ry={0}
      />
      <rect
        x={0}
        y={0}
        width={width}
        height={borderRadius+10}
        fill={color}
        rx={borderRadius}
        ry={borderRadius}
      />
    </g>
  );
};

// interface DataItem extends BarDatum {
//   labelname: string;
//   value: number;
//   color: string;
//   evaulatepercentage: number;
// }

const OfferManagementWidget:any = ({reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels,userRoleObjectSelected,userDependendObj}:any) => {
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)
  const navigate = useNavigate();
  const [offerMgtEffectivenessData, setOfferMgtEffectivenessData] = useState<any[]>([]);
    const getofferMgtEffectivenessDataApi = async () => {
      const reportSubmissionAPIDataJson:any = {}
         if(reportSubmissionAPIData !=  null){
             if(Object.keys(reportSubmissionAPIData).length > 0){
                 Object.keys(reportSubmissionAPIData).map((item:any) => {
                     if(reportSubmissionAPIData[item].length > 0){
                     reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                     }
                 })
                }  
	         }
        const requestPayload = {
          ...reportSubmissionAPIDataJson,
        }  
          requestPayload["userID"] = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
          delete requestPayload["userId"]
        try{
          const response = await getofferMgtEffectiveness(requestPayload);
          console.log("My Offer Management Data : ", response?.data)
          setOfferMgtEffectivenessData(response?.data)
        }
          catch(error:any){
            console.log(error)        
          }
      }

      useEffect(() => {
        getofferMgtEffectivenessDataApi()
      },[reportSubmissionAPIData])

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const minBarChartHeight = 10;
  const height = isSmallScreen ? '300px' : isMediumScreen ? '400px' : '500px';

  const formatTick = (value: number): string => {
    if (value === 0) return '0';
    if (value >= 20 && value < 40) return '20L';
    if (value >= 40 && value < 60) return '40L';
    if (value >= 60 && value < 80) return '60L';
    if (value >= 80 && value < 100) return '80L';
    if (value >= 100 && value < 2000) return '1M';
    if (value >= 2000 && value < 4000) return '20M';
    if (value >= 4000 && value < 6000) return '40M';
    if (value >= 6000) return '60M';
    return value.toString();
  };

  const customTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 8, // Customize the font size for axis ticks
        },
      },
      legend: {
        text: {
          fontSize: 14, // Customize the font size for axis legend
        },
      },
    },
    grid: {
      line: {
          stroke: '#ccc',
          strokeWidth: 1,
          strokeOpacity: 0.3, // Adjust opacity here
      },
  },
  text: { fontSize: 14 }
  };


  const findHighestValue = (data: any[]) => {
    const arrayList:any = []
    data.map((max:any) => {arrayList.push(max.value)})
    const higVal = Math.max(...arrayList);
    return higVal;
  };

  const highestValue:any = findHighestValue(offerMgtEffectivenessData);

  const offerMgtClickHandler = () => {
  const userRoleObjectSelectedData = userRoleObjectSelected;
  if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
    navigate(`/OfferMgtEffective`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels,userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj} });
  }

  return (
    <Paper className='dashboardWidgetSmallCard'>
      <Box className="dashWidgetHeader">
        <Typography variant="h6" gutterBottom>{`Post Select - Candidate Status Distribution`}</Typography>
        <Box className="dashWidgetActionSect" sx={{display:'flex', columnGap:'5px',paddingTop:'5px'}}>
          <Tooltip title="Launch"><DashboardLaunch className='launchIcon' onClick={offerMgtClickHandler}/></Tooltip>
          {/* <MoreVertIcon style={{ fontSize: '22px', fontWeight: 'bold', paddingBottom: '2px' }} /> */}
        </Box>
      </Box>
      {/* {offerMgtEffectivenessData.map((item)=>item)} */}
      <Box className="offerMgtWidgetSect" sx={{ height: '440px', width: '100%' }}>
        <ResponsiveBar
          data={offerMgtEffectivenessData.map((item)=>item)}
          keys={["value"]}
          indexBy="labelname"
          enableGridX={false}
          margin={{
            top: 20,
            right: 0,
            bottom: 40,
            left: 50
          }}
          borderRadius={3}
          padding={0.4}
          groupMode="grouped"
          colors={({ data }) => data.color}
          axisTop={null}
          axisRight={null}
          enableGridY
          enableLabel={false}
          maxValue={ highestValue > minBarChartHeight ? highestValue + (highestValue.toString().length == 1 ?  Math.round((highestValue) * 1) : Math.round((highestValue/100) * 20)) : minBarChartHeight}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            renderTick: (tick) => {
              // Split the label into words and render them in separate <tspan> elements for multi-line display
              let lines = tick.value.split(' ');
              if(lines.length == 3) {
                lines = [`${lines[0]} ${lines[1]}`,lines[2]]
              }
              return (
                  <g transform={`translate(${tick.x},${tick.y + 22})`}>
                      <text
                          textAnchor="middle"
                          style={{ fill: 'black', fontSize: '11px' }}
                      >
                          {lines.map((line:any, i:any) => (
                              <tspan key={i} x={0} dy={i === 0 ? 0 : 12}>
                                  {line}
                              </tspan>
                          ))}
                      </text>
                  </g>
              );
          }
          }}
          valueScale={{ type: "linear" }}
          animate={true}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // format: formatTick,
            legendPosition: "middle",
            legendOffset: -40
          }}
          enableTotals={true}
          theme={customTheme}
          // barComponent={CustomBar}
          tooltip={() => (<></>)}
        />
      </Box>
    </Paper>
  );
};

export default OfferManagementWidget;