import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/lightcloseIcon.svg'
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import '../HiringDemandCard/HiringDemandCardDetail.scss'
import { IMaskInput } from 'react-imask';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactComponent as ClientIcon } from '../../../../assets/icons/widgets/Client.svg'
import { ReactComponent as MandateIcon } from '../../../../assets/icons/widgets/Mandate.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/icons/widgets/candidate.svg'
import { getFunnelConversionListApi, gethiringdemandlistapi, getJobWithCandiListApi, getOfferMgtListApi } from '../../../../services/DashboardService';
import DashboardFilterIndex from '../../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import HiringDemandTableSection from '../HiringDemandCard/HiringDemandTableSection';

const JobsWithCandidateWidgetDetails = () => {

    
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    console.log("view Hiring : ",id,location)
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>(location)
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [exportdatareqest,setexportdatareqest] = useState<any>({})
    const [candidates, setCandidates] = useState<any>({});
    const [candidateData, setCandidateData] = useState<any | any[]>([]);
    const [initalCount, setInitalCount] = useState(0)
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [userDependendObj,setUserDependendObj] = useState({})
    const [hiringDemandTabDetail,setHiringDemandTabDetail] = useState<any>({
        sortColumn: "",
        sortDirection: "",
        paginationCountSize: {
          inputPageSize: 10,
          pageSize: 1,
          inputPageNo: 1,
          totalRecords: 0,
        },
        textFieldValues: ""
      })

      function toCamelCaseAndAppend(str:string, appendWord:string) {
        const camelCaseStr = str
            .toLowerCase()
            .split('_')
            .map((word, index) =>
                index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join('');
        
        return camelCaseStr + appendWord.charAt(0).toUpperCase() + appendWord.slice(1);
    }
    
    const offerMgtListApi = async () => {
      const reqObj: any = {     
        "userID": [userId], 
        "reportType": HirningDemandParamsDetailsCopy?.state?.reportType, 
        "secondaryFilter": HirningDemandParamsDetailsCopy?.state?.secondaryFilter,
        "pageSize": hiringDemandTabDetail.paginationCountSize.inputPageSize,
        "pageNo": hiringDemandTabDetail.paginationCountSize.inputPageNo,
        "sortColumn":"total_experience",
        "sortDirection": ""
      };
      const HirningDemandParamsDetailsData = HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {}
      const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
      if(Object.keys(reportSubmissionAPIData).length > 0){if("coe" in reportSubmissionAPIData){reqObj["coe"] = reportSubmissionAPIData?.coe}}else if("coe" in HirningDemandParamsDetailsData){reqObj["coe"] = HirningDemandParamsDetailsData?.coe}
        // if(Object.keys(reportSubmissionAPIData).length > 0){if("deliveryManagerUserID" in reportSubmissionAPIData){reqObj["deliverymanager"] = reportSubmissionAPIData?.deliveryManagerUserID}}else if(HirningDemandParamsDetailsData?.deliveryManagerUserID.length > 0){reqObj["deliverymanager"] = HirningDemandParamsDetailsData?.deliveryManagerUserID}
        // if(Object.keys(reportSubmissionAPIData).length > 0){if("teamLeadUserID" in reportSubmissionAPIData){reqObj["teamLead"]=reportSubmissionAPIData?.teamLeadUserID}}else if(HirningDemandParamsDetailsData?.deliveryManagerUserID.length > 0){reqObj["teamLead"] = HirningDemandParamsDetailsData?.deliveryManagerUserID}
        if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["jobTitle"] = reportSubmissionAPIData?.jobTitle}else if("jobTitle" in HirningDemandParamsDetailsData){reqObj["jobTitle"] = HirningDemandParamsDetailsData?.jobTitle}
        // if(Object.keys(reportSubmissionAPIData).length > 0){if("RecruiterUserID" in reportSubmissionAPIData){reqObj["Recruiter"]= reportSubmissionAPIData?.RecruiterUserID}}else if(HirningDemandParamsDetailsData?.RecruiterUserID.length > 0){reqObj["Recruiter"] = HirningDemandParamsDetailsData?.RecruiterUserID}
        if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["clientId"] = reportSubmissionAPIData?.client}else if("client" in HirningDemandParamsDetailsData){reqObj["clientId"] = HirningDemandParamsDetailsData?.client}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.joLocation.length > 0)reqObj["joLocation"] = reportSubmissionAPIData?.joLocation}else if("joLocation" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.joLocation.length > 0)reqObj["joLocation"] = HirningDemandParamsDetailsData?.joLocation}
        if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["businessUnit"] = reportSubmissionAPIData?.businessUnit}else if("businessUnit" in HirningDemandParamsDetailsData){reqObj["businessUnit"] = HirningDemandParamsDetailsData?.businessUnit}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.mandateType.length > 0)reqObj["mandateType"] = reportSubmissionAPIData?.mandateType}else if("mandateType" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.mandateType.length > 0)reqObj["mandateType"] = HirningDemandParamsDetailsData?.mandateType}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.jobMode.length > 0)reqObj["jobMode"] = reportSubmissionAPIData?.jobMode}else if("jobMode" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.jobMode.length > 0)reqObj["jobMode"] = HirningDemandParamsDetailsData?.jobMode}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.mandateSPOC.length > 0)reqObj["mandateSPOC"] = reportSubmissionAPIData?.mandateSPOC}else if("mandateSPOC" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.mandateSPOC.length > 0)reqObj["mandateSPOC"] = HirningDemandParamsDetailsData?.mandateSPOC}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.roles.length > 0)reqObj["roles"] = reportSubmissionAPIData?.roles}else if("roles" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.roles.length > 0)reqObj["roles"] = HirningDemandParamsDetailsData?.roles}
        if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.primarySkills.length > 0)reqObj["primarySkills"] = reportSubmissionAPIData?.primarySkills}else if("primarySkills" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.primarySkills.length > 0)reqObj["primarySkills"] = HirningDemandParamsDetailsData?.primarySkills}
        // if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.industry.length > 0)reqObj["industry"] = reportSubmissionAPIData?.industry}else if("industry"in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.industry.length > 0)reqObj["industry"] = HirningDemandParamsDetailsData?.industry}
        if(Object.keys(reportSubmissionAPIData).length > 0){if("customEndDate" in reportSubmissionAPIData) reqObj["customEndDate"] = reportSubmissionAPIData?.customEndDate.toString()}else if("customEndDate" in HirningDemandParamsDetailsData){reqObj["customEndDate"] = HirningDemandParamsDetailsData?.customEndDate.toString()}
        if(Object.keys(reportSubmissionAPIData).length > 0){if("customStartDate" in reportSubmissionAPIData) reqObj["customStartDate"] = reportSubmissionAPIData?.customStartDate.toString()}else if("customStartDate" in HirningDemandParamsDetailsData){reqObj["customStartDate"] = HirningDemandParamsDetailsData?.customStartDate.toString()}
        // if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}
        Object.keys(hiringDemandTabDetail.textFieldValues).map((item:any) => {
          if(hiringDemandTabDetail.textFieldValues[item].length > 0)reqObj[toCamelCaseAndAppend(item,"search")] = hiringDemandTabDetail.textFieldValues[item]
        })
        if(hiringDemandTabDetail.sortColumn.length > 0 && hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn}
        if(hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection.toUpperCase()}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userID"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userID"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}

        try {
            const res: any = await getJobWithCandiListApi(reqObj);
            setCandidates({"data":res?.data,"requestData":res?.data.data});
            setexportdatareqest(reqObj)
        } catch (error: any) {
            console.log(error);
        }
    };

        useEffect(() => {
          offerMgtListApi();
        }, [hiringDemandTabDetail]);
        
        useEffect(() => {
          if(initalCount == 0){
            setInitalCount(1)
          }else{
            setHiringDemandTabDetail({
              sortColumn: "",
              sortDirection: "",
              paginationCountSize: {
                inputPageSize: hiringDemandTabDetail.paginationCountSize.inputPageSize,
                inputPageNo: 1,
                pageSize: 10,
                totalRecords: 0,
              },
              textFieldValues: ""
            })
          }
        }, [reportSubmissionAPIData,HirningDemandParamsDetails])

        const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card">
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }

      const HiringHeader = `<Box>
          <p class="breadcrumbFilter">Jobs with Candidate Activity, Across Clients (${candidates?.data?.totalRecords})</p>
          <Typography display='flex' gap={'5px'} class='dashboardDetailHeaderTitle' style="margin-top:-15px;margin-bottom:10px;cursor:pointer">
              <span style="color:gray">Overview Dashboard > </span><span style="color:#0033a0">Jobs with Candidate Activity, Across Clients</span>
          </Typography>
        </Box>`

  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px'}}>
            <Box className="jobsWithCandidateSect">
              <Box sx={{padding: '10px' }}>
              <Box sx={{paddingX:'5px'}}><KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/dashboard",{ state: {dashboardfilterObj:HirningDemandParamsDetailsCopy?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetailsCopy?.state?.filterList ,filterListLabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels, clientDependedObj: HirningDemandParamsDetailsCopy?.state?.clientDependedObj } })}}/></Box>
              <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={HiringHeader} downloadName={`Jobs with Candidate Activity`} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={"subScreen"} screenType={"jobsWithCandidateDrill"}/>
                </Box>
                
                {Object.keys(candidates).length > 0 ? <HiringDemandTableSection candidates={candidates} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={""}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
            </Box>
        </div>
    </div>
  )
}

export default JobsWithCandidateWidgetDetails