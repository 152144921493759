import React, { useEffect, useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

const NumberIncrementComp = ({ min = 0, max = 100, step = 1, onChange,fieldvalue }: any) => {
    const [value, setValue] = useState(fieldvalue);

    const handleIncrement = () => {
        const newValue = (value === '' ? min : Number(value)) + step;
        if (newValue <= max) {
            setValue(newValue.toString());
            onChange && onChange(newValue);
        }
    };

    const handleDecrement = () => {
        const newValue = (value === '' ? min : Number(value)) - step;
        if (newValue >= min) {
            setValue(newValue.toString());
            onChange && onChange(newValue);
        }
    };

    const handleInputChange = (e: any) => {
        const inputValue = e.target.value;
        if (inputValue === '' || (Number(inputValue) >= min && Number(inputValue) <= max)) {
            setValue(inputValue);
            onChange && onChange(inputValue === '' ? '' : Number(inputValue));
        }
    };
    useEffect(() => {
        setValue(fieldvalue)
    },[fieldvalue])
    return (
        <Box display="flex" alignItems="center">
            <TextField
                value={value}
                onChange={handleInputChange}
                variant="outlined"
                size="small"
                inputProps={{
                    style: { textAlign: 'left' },
                    min: min,
                    max: max,
                    type: 'text', // Changed to 'text' to allow empty input
                }}
            />
            <Box display="flex" className="actionBtn" flexDirection="column" ml={0.5}>
                <IconButton size="small" onClick={handleIncrement}>
                    <ArrowDropUp />
                </IconButton>
                <IconButton size="small" onClick={handleDecrement}>
                    <ArrowDropDown />
                </IconButton>
            </Box>
        </Box>
    );
};

export default NumberIncrementComp;