import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/lightcloseIcon.svg'
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import '../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail.scss'
import { IMaskInput } from 'react-imask';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactComponent as ClientIcon } from '../../../assets/icons/widgets/Client.svg'
import { ReactComponent as MandateIcon } from '../../../assets/icons/widgets/Mandate.svg'
import { ReactComponent as CandidateIcon } from '../../../assets/icons/widgets/candidate.svg'
import { getFunnelConversionListApi, gethiringdemandlistapi, getJobOrderAgeingDrillApi } from '../../../services/DashboardService';
import { useAppSelector } from '../../../store/hooks';
import DashboardFilterIndex from '../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }


const ScrollableBox = styled(Box)({
  height: '300px',  // Set the desired height
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0px',  // Hide scrollbar for WebKit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  scrollbarWidth: 'none',  // Hide scrollbar for Firefox
  msOverflowStyle: 'none',  // Hide scrollbar for IE 10+
});

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
	function TextMaskCustom(props, ref) {
	  const { onChange, ...other } = props;
	  return (
		<IMaskInput
		  {...other}
		  mask="0000-00-00"
		  definitions={{
			'#': /[1-9]/,
		  }}
		  inputRef={ref}
		  onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
		  overwrite
		/>
	  );
	},
  );
  


const JobOrderAgeingWidgetDetails = () => {

    
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>(location)
    const [jobOrderAgeingDetails, setJobOrderAgeingDetails] = useState(HirningDemandParamsDetails?.state?.jobOrderAgeingDetails)
    const [exportdatareqest,setexportdatareqest] = useState<any>({})
    const [candidates, setCandidates] = useState<any[]>([]);
    const [candidateData, setCandidateData] = useState<any | any[]>([]);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('');
    const [columnsort, setcolumnsort] = useState<string>('');
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [initalCount, setInitalCount] = useState(0)
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [userDependendObj,setUserDependendObj] = useState({})
    const [paginationCountSize, setPaginationCountSize] = useState({
        inputPageSize: 10,
        pageSize: 1,
        inputPageNo: 1,
        totalRecords: 0,
      });

    const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: any }>({});

    const handleTextFieldChange = (fieldKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValues(prevState => {
          const newState = { ...prevState, [fieldKey]: event.target.value };
          return newState;
        });
      };

      const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
      const [applyBtnActive, setApplyBtnActive] = useState("")
    const hiringListApi = async () => {
      const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
      const reqObj:any = {
        "reportType":jobOrderAgeingDetails.reportType,  
        "reportStage":jobOrderAgeingDetails.reportStage, 
        "clientID_JO":parseInt(jobOrderAgeingDetails.clientID_JO), 
        "BUID_JO":parseInt(jobOrderAgeingDetails.BUID_JO), 
        "coe": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.coe ?? [] : HirningDemandParamsDetails?.state?.filterList?.coe,
        "deliverymanager": Object.keys(reportSubmissionAPIData).length > 0 ? "deliveryManagerUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.deliveryManagerUserID : "" : HirningDemandParamsDetails?.state?.filterList?.deliveryManagerUserID || "",
        "candidateName": textFieldValues.candidateName || "",
        "mandateName": textFieldValues.mandateName || "",
        "teamLead": Object.keys(reportSubmissionAPIData).length > 0 ? "teamLeadUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.teamLeadUserID : "" : HirningDemandParamsDetails?.state?.filterList?.teamLeadUserID || "",
        "clientName": textFieldValues.clientName || "",
        "createdAt": "",
        "createdBy": "",
        "currentCTC": textFieldValues.currentCTC || "",
        "currentLocation": textFieldValues.currentLocation || "",
        "currentOrganization": textFieldValues.currentOrganization || "",
        "cvSentDate": textFieldValues.cvSentDate || "",
        "email": textFieldValues.email || "",
        "expectedCTC": textFieldValues.expectedCTC || "",
        "jobTitle":  Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobTitle : HirningDemandParamsDetails?.state?.filterList?.jobTitle,
        "jobTitleSearch":textFieldValues.jobTitle || "",
        "noticePeriod": textFieldValues.noticePeriod || "",
        "phoneNumber": textFieldValues.phoneNumber || "",
        "preferredLocation": textFieldValues.preferredLocation || "",
        "profileEntryDate": textFieldValues.profileEntryDate || "",
        "profileLastActiveDate": "",
        "profileLastModifiedDate": textFieldValues.profileLastModifiedDate || "",
        "recruiterName": textFieldValues.recruiterName || "",
        "relevantExperience": textFieldValues.relevantExperience || "",
        "sortColumn": sortColumn,
        "sortDirection": sortDirection,
        "source": textFieldValues.source || "",
        "stage": textFieldValues.stage || "",
        "status": textFieldValues.status || "",
        "Recruiter": Object.keys(reportSubmissionAPIData).length > 0 ? "RecruiterUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.RecruiterUserID : "": HirningDemandParamsDetails?.state?.filterList?.RecruiterUserID || "",
        "candidateProcessingStage": textFieldValues.candidateProcessingStage || "",
        "totalExperience": textFieldValues.totalExperience || "",
        "userId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.userId : HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId] : [userId],
        "pageSize": paginationCountSize.inputPageSize,
        "pageNo": paginationCountSize.inputPageNo,
        "clientId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.client : HirningDemandParamsDetails?.state?.filterList?.client || [],
        "joLocation": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.joLocation : HirningDemandParamsDetails?.state?.filterList?.joLocation || [],
        "businessUnit": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.businessUnit : HirningDemandParamsDetails?.state?.filterList?.businessUnit || [],
        "mandateType": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateType : HirningDemandParamsDetails?.state?.filterList?.mandateType || [],
        "jobMode": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobMode : HirningDemandParamsDetails?.state?.filterList?.jobMode || [],
        "mandateSPOC": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateSPOC : HirningDemandParamsDetails?.state?.filterList?.mandateSPOC || [],
        "roles":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.roles : HirningDemandParamsDetails?.state?.filterList?.roles || [],
        "primarySkills":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.primarySkills : HirningDemandParamsDetails?.state?.filterList?.primarySkills || [],
        "customEndDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customEndDate : HirningDemandParamsDetails?.state?.filterList?.customEndDate || "",
        "customStartDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customStartDate : HirningDemandParamsDetails?.state?.filterList?.customStartDate || "",
        "industry":[]
      };

        try {
            const res: any = await getJobOrderAgeingDrillApi(reqObj);
            setCandidates(res?.data?.data);
            setCandidateData(res?.data)
            setPaginationCountSize(prev => ({
                ...prev,
                totalRecords: res.data.totalRecords,
                pageSize: res.data.pageSize, // page Size
                inputPageNo: res.data.inputPageNo, // Page Current
                inputPageSize: res.data.inputPageSize // current Page Size
              }));

              setexportdatareqest(reqObj)

        } catch (error: any) {
            console.log(error);
        }
    };


    
    const { handleSubmit, control, reset, watch, getValues, setValue } = useForm({});

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        setPaginationCountSize(prev => ({
            ...prev,
            inputPageSize: event.target.value as number,
            inputPageNo: 1 // reset to first page
          }));

    };

    const paginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setPaginationCountSize(prev => ({ ...prev, inputPageNo: page }));
    };

    const bgColorArray = ['#FFCDD2', '#F8BBD0', '#E1BEE7', '#D1C4E9', '#C5CAE9', '#BBDEFB', '#B3E5FC', '#B2EBF2', '#B2DFDB', '#C8E6C9', '#DCEDC8', '#F0F4C3', '#FFF9C4', '#FFECB3', '#FFE0B2', '#FFCCBC', '#D7CCC8', '#F5F5F5', '#CFD8DC', '#B0BEC5'];

    const textColorArray = ['#D32F2F', '#C2185B', '#7B1FA2', '#512DA8', '#303F9F', '#1976D2', '#0288D1', '#0097A7', '#00796B', '#388E3C', '#689F38', '#AFB42B', '#FBC02D', '#FFA000', '#F57C00', '#E64A19', '#5D4037', '#616161', '#455A64', '#37474F'];

    const stringToColorbg = (str: string): string => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return bgColorArray[Math.abs(hash) % bgColorArray.length];
    };

    const stringToColortext = (str: string): string => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return textColorArray[Math.abs(hash) % textColorArray.length];
    };

      function truncateString(str:any, num:number) {
        if (str && str.length > num) {
          return str.slice(0, num) + '...';
        } else {
          return str;
        }
      }


      function formatDate(dateString: any): any {
        const date = new Date(dateString);
        // Get day
        const day = date.getDate();
        // Get month (0-based index, so we add 1)
        const month = date.toLocaleString('default', { month: 'short' });
        // Get year
        const year = date.getFullYear();
        // Get hours and minutes
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // Format as '14 Dec 2024, 15:00'
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
      }
      


    const initialColumnFields = [
        { id: "1", objectkey: "mandate_name", label: "Mandate", checkStatus: true, reqObjkey: "mandateName" },
        { id: "2", objectkey: "candidate_name", label: "Candidate", checkStatus: true, reqObjkey: "candidateName" },
        { id: "3", objectkey: "job_title", label: "Job Title", checkStatus: true, reqObjkey: "jobTitle" },
        { id: "4", objectkey: "client_name", label: "Client", checkStatus: true, reqObjkey: "clientName" },
        { id: "5", objectkey: "current_company", label: "Current Org", checkStatus: true, reqObjkey: "currentOrganization" },
        { id: "6", objectkey: "experience", label: "Total Exp", checkStatus: true, reqObjkey: "totalExperience" },
        { id: "7", objectkey: "relevant_experience", label: "Relevant Exp", checkStatus: true, reqObjkey: "relevantExperience" },
        { id: "8", objectkey: "current_location", label: "Current Loc", checkStatus: true, reqObjkey: "currentLocation" },
        { id: "9", objectkey: "preferred_locations", label: "Preferred Loc", checkStatus: true, reqObjkey: "preferredLocation" },
        { id: "10", objectkey: "notice_period", label: "Notice Period", checkStatus: true, reqObjkey: "noticePeriod" },
        { id: "11", objectkey: "phone_number", label: "Contact No", checkStatus: true, reqObjkey: "phoneNumber" },
        { id: "12", objectkey: "e_mail", label: "Email", checkStatus: true, reqObjkey: "email" },
        { id: "13", objectkey: "current_ctc", label: "Current CTC", checkStatus: true, reqObjkey: "currentCTC" },
        { id: "14", objectkey: "exptected_ctc", label: "Expected CTC", checkStatus: true, reqObjkey: "expectedCTC" },
        { id: "15", objectkey: "source", label: "Source", checkStatus: true, reqObjkey: "source" },
        { id: "16", objectkey: "cv_sent_date", label: "CV Submitted Date", checkStatus: true, reqObjkey: "cvSentDate" },
        { id: "17", objectkey: "stage_name", label: "Stage", checkStatus: true, reqObjkey: "stage" },
        { id: "18", objectkey: "candidate_processing_stage", label: "Candidate Processing Stage", checkStatus: true, reqObjkey: "candidateProcessingStage" },
        { id: "19", objectkey: "status_name", label: "Status", checkStatus: true, reqObjkey: "status" },
        { id: "20", objectkey: "recruiter_name", label: "Recruiter Name", checkStatus: true, reqObjkey: "recruiterName" },
        { id: "21", objectkey: "created_at", label: "Profile Artemis Entry Date", checkStatus: true, reqObjkey: "profileEntryDate" },
        { id: "22", objectkey: "modified_on", label: "Profile Artemis Last Modified Date", checkStatus: true, reqObjkey: "profileLastModifiedDate" },
      ];
      



      const [columnFields, setColumnFields] = useState(initialColumnFields);
      const [tempColumnFields, setTempColumnFields] = useState(initialColumnFields);
        const [sortingTableHead, setSortingTableHead] = useState<any>({});
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [opentfiltercolumn,setopentfiltercolumn] = useState(false);
        const [displaycolumncontrol, setdisplaycolumncontrol] = useState(false);
        

        const sortTableHeadHandler = (column:any) => {
            let newDirection = '';
            if (columnsort === 'asc') {
                newDirection = 'desc';
            } else if (columnsort === 'desc') {
                newDirection = '';
            } else {
                newDirection = 'asc';
            }

            setSortDirection(newDirection);
            setcolumnsort(newDirection);
            setSortColumn(column.reqObjkey);
            setSortingTableHead((prevState: any) => ({
                ...prevState,
                [column.id]: newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
            }));
            
            
        };

        const handleCheckboxChange = (columnId:any) => {
            setTempColumnFields((prevFields) =>
              prevFields.map((field) =>
                  field.id === columnId ? { ...field, checkStatus: !field.checkStatus } : field
              )
          );
        };

        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          };

          const handleReset = () => {
            setTempColumnFields(prevFields =>
              prevFields.map(field => ({ ...field, checkStatus: true }))
          );
          };
        
          const handleApply = () => {
            setColumnFields(tempColumnFields);
            setdisplaycolumncontrol(true)
            opentfiltercolumnclick(); // Close the filter dialog
          };
        
          const filteredFields = tempColumnFields.filter((field) =>
            field.label.toLowerCase().includes(searchTerm.toLowerCase())
          );

          const opentfiltercolumnclick = () =>{
            setopentfiltercolumn(!opentfiltercolumn)
          } 

          useEffect(() => {
            hiringListApi();
            sortTableHeadHandler;
            handlePageSizeChange;
            paginationChange;
        }, [location,sortColumn, sortDirection, paginationCountSize.inputPageSize, paginationCountSize.inputPageNo,textFieldValues]);
        
        useEffect(() => {          
          sortTableHeadHandler;
          handlePageSizeChange;
          paginationChange;       
          if(initalCount == 0){
            setInitalCount(1)
          }else{   
            setTextFieldValues({})
          }
      }, [reportSubmissionAPIData,HirningDemandParamsDetails])
        const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card">
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }

      const HiringHeader = `<Box>
      <p class="breadcrumbFilter">${jobOrderAgeingDetails.label} - ${jobOrderAgeingDetails.reportType} (${HirningDemandParamsDetails?.state?.data?.count ? HirningDemandParamsDetails?.state?.data?.count : candidateData?.totalRecords})</p>
      <Typography display='flex' gap={'5px'} class='dashboardDetailHeaderTitle' style="margin-top:-15px;margin-bottom:10px;cursor:pointer">
          <span style="color:gray">${jobOrderAgeingDetails.reportType} Ageing Report > </span><span style="color:#0033a0">${jobOrderAgeingDetails.label} - ${jobOrderAgeingDetails.reportType}</span>
      </Typography>
    </Box>`

  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px'}}>
            <Box className="jobOrderAgeingSect">
              <Box sx={{padding: '10px' }}>
              <Box sx={{paddingX:'5px'}}><KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/jobOrderAgeing",{ state: {dashboardfilterObj:HirningDemandParamsDetailsCopy?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetailsCopy?.state?.filterList ,filterListLabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels,userFilterObj: HirningDemandParamsDetailsCopy?.state?.userFilterObj, clientDependedObj: HirningDemandParamsDetailsCopy?.state?.clientDependedObj } })}}/></Box>
              <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={HiringHeader} downloadName={`${jobOrderAgeingDetails.label} - ${jobOrderAgeingDetails.reportType}`} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={"subScreen"} screenType={"jobOrderAgeningDrill"}/>
                {/* <HiringDemandFilterApp exportdatareqest={exportdatareqest} setReportSubmissionAPIData={setReportSubmissionAPIData} HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setApplyBtnActive={setApplyBtnActive} candidates={candidateData}/> */}
                </Box>
                
                <Box sx={{padding: '10px' }}>
                <Box style={{paddingTop:"20px",display:'flex',flexDirection:'column'}}>
                {
                    opentfiltercolumn && (<div style={{display:'flex',justifyContent:'flex-end',paddingRight:'20px'}}>
                        <Paper elevation={3} sx={{ padding: '5px', maxWidth: 250 ,position:'absolute',zIndex:'3',marginTop:'38px'}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,paddingX:'10px',paddingY:'5px'}}>
                                <Typography variant="h6" sx={{fontSize:'16px'}}>Column Filter</Typography>
                                <IconButton aria-label="close" onClick={opentfiltercolumnclick}>
                                <CloseIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{borderTop:'1px solid #E5E8EB', borderBottom:'1px solid #E5E8EB'}}>
                            <Box sx={{ mb: 2, mt: 1 ,paddingX:'10px'}}>
                                <TextField size="small"
                                fullWidth
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                    ),
                                    sx: { borderRadius: '8px' }
                                }}
                                />
                            </Box>
                            <FormGroup>
                            <ScrollableBox>
                            <Box sx={{display:"flex",flexDirection:'column',paddingX:'10px'}}>
                                {filteredFields.map((column) => (
                                <FormControlLabel sx={{fontSize:'12px'}}
                                    key={column.id}
                                    control={
                                    <Checkbox
                                        checked={column.checkStatus}
                                        onChange={() => handleCheckboxChange(column.id)}
                                    />
                                    }
                                    label={column.label}
                                />
                                ))}
                                </Box>
                            </ScrollableBox>
                            </FormGroup>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 , columnGap:'10px'}}>
                                <Button onClick={handleReset} sx={{ bgcolor: '#E5E8EB', color: '#000', borderRadius:'6px' }}>
                                Reset
                                </Button>
                                <Button onClick={handleApply} sx={{ bgcolor: '#002882', color: '#fff', borderRadius:'6px' }}>
                                Apply
                                </Button>
                            </Box>
                            </Paper>
                        </div>)
                }
                
                </Box>
                <Box className="reportTableContSect hiringTableSect" style={{marginTop:'-20px'}}>
                <Box style={{display:'flex',justifyContent:'flex-end',position:"relative"}}>
                    <Box onClick={opentfiltercolumnclick} className="columnToggleBtn"><ToolfilterIcon/></Box>
                </Box>
                    <TableContainer className="submissionReportContainer roleListTableSect" component={Paper}>
                        <Table sx={{ minWidth:'auto' }} aria-label="simple table">
                            <TableHead  style={{
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 1,
                                    }}>

                            <TableRow>
                                {columnFields.filter(column => column.checkStatus).map((column) => (
                                    <TableCell key={column.id} style={{ whiteSpace: 'nowrap', cursor:'pointer'}} 
                                   >
                                    <div onClick={() => sortTableHeadHandler(column)}>
                                        {column.label}
                                        {
                                        sortingTableHead && sortingTableHead[column.id] ? 
                                            (sortingTableHead[column.id] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />
                                        }
                                    </div>
                                    </TableCell>
                                ))}
                            </TableRow>

                            <TableRow>
                                    {columnFields.length > 0 ? columnFields.filter(column => column.checkStatus).map((item, index) => {
                                        const key = `${index}`; // Unique key for each text field
                                        const dateField = ["cvSentDate","profileEntryDate","profileLastModifiedDate","rofileLastModifiedDate"]
                                        return (
                                            <TableCell key={key} className='dashboardListThead'>
                                                {!dateField.includes(item?.reqObjkey) ? <div className="customehiringdemand">
                                                    <TextField
                                                        type="text"
                                                        // size="small"
                                                        style={{opacity: item?.reqObjkey == "relevantExperience"  ? 0.5 : 1}}
                                                        disabled = {item?.reqObjkey == "relevantExperience"  ? true : false}
                                                        value={textFieldValues[item.reqObjkey] || ''}
                                                        onChange={handleTextFieldChange(item.reqObjkey)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <SearchIcon />
                                                            )
                                                        }}
                                                    />
                                                </div>: 
                                                <FormControl className={`reportTableSearchFieldMask`} variant="standard">
                                                <Input
                                                value={textFieldValues[item.reqObjkey] || ''}
                                                onChange={handleTextFieldChange(item.reqObjkey)}
                                                name="textmask"
                                                id="formatted-text-mask-input"
                                                inputComponent={TextMaskCustom as any}
                                                startAdornment={
                                                  <InputAdornment position="start">
                                                    <SearchIcon />
                                                  </InputAdornment>
                                                }
                                                />
                                              </FormControl>}
                                            </TableCell>
                                        );
                                    }) : null}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {candidates && candidates.length > 0 ? candidates.map((candidate,index) => {
                                    const avatar = candidate.candidate_name != null ? candidate.candidate_name.substring(0, 2).toUpperCase(): ""
                                    const avatarBgColor = candidate.candidate_name != null ? stringToColorbg(`${index + candidate.candidate_name}`): "";
                                    const colorhash = candidate.candidate_name != null ? stringToColortext(`${index + candidate.candidate_name}`): "";
                                    
                                    return (
                                    <TableRow key={index} style={{ minHeight: 'fit-content'}}>
                                        {columnFields.find(column => column.id === '1' && column.checkStatus) && (
                                        <TableCell>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                            <div style={{ minWidth: "fit-content" }}><DefaultClientLogo /></div>
                                            <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '5px' }}>
                                                <div style={{ fontWeight: "600" }} className='candidateName' title={candidate.mandate_name}>{candidate.mandate_name}</div><div>{candidate.mandate_code}</div>
                                            </div>
                                            </div>
                                        </TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '2' && column.checkStatus) && (
                                        <TableCell>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                            <div style={{ minWidth: "fit-content" }}>
                                                <div style={{ minWidth: "fit-content", backgroundColor: avatarBgColor, display:'flex',alignContent:'center', alignItems:'center',justifyContent:'center', height:"36px", width:'36px', fontWeight: 'bold', fontSize: '12px', color: `${colorhash}`, borderRadius: '50px' }}>
                                                {avatar}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '4px', paddingTop: '3px' }}>
                                                <div className='candidateName' title={candidate.candidate_name} style={{ fontWeight: "600", color: '#000', fontSize: '12px' }}>{candidate.candidate_name}</div><div>{candidate.candidate_id}</div>
                                            </div>
                                            </div>
                                        </TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '3' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.mandate_name}>{candidate.mandate_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '4' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.client_name}>{candidate.client_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '5' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_company}>{candidate.current_company || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '6' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.experience}>{candidate.experience || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '7' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.relevant_experience}>{candidate.relevant_experience || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '8' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_location}>{candidate.current_location || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '9' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.preferred_locations}>{candidate.preferred_locations ? truncateString(candidate.preferred_locations, 12) : '-'}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '10' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.notice_period}>{candidate.notice_period || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '11' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.phone_number}>{candidate.phone_number || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '12' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.e_mail}>{candidate.e_mail || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '13' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_ctc}>{candidate.current_ctc || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '14' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.exptected_ctc}>{candidate.exptected_ctc || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '15' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.source}>{candidate.source || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '16' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.cv_sent_date)}>{candidate.cv_sent_date ? formatDate(candidate.cv_sent_date) : '-' }</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '17' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.stage_name}>{candidate.stage_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '18' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.stage_name}>{candidate.stage_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '19' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.status_name}>{candidate.status_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '20' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.recruiter_name}>{candidate.recruiter_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '21' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.created_at)}>{ candidate.created_at ? formatDate(candidate.created_at) : '-'}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '22' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.modified_on)}>{candidate.modified_on ? formatDate(candidate.modified_on) : '-'}</Tooltip></TableCell>
                                        )}
                                    </TableRow>                                    
                                    );
                                }) : (
                                    <TableRow>
                                    <TableCell colSpan={columnFields.length} align="center">
                                        {renderEmptySearchBox()}
                                    </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
                    <Box className="hiringTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Age"
									value={paginationCountSize.inputPageSize}
                                    onChange={handlePageSizeChange}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={paginationCountSize.pageSize}
                            page={paginationCountSize.inputPageNo}
                            onChange={paginationChange} variant="outlined" shape="rounded"/>
					</Box>
          </Box>
            </Box>
        </div>
    </div>
  )
}

export default JobOrderAgeingWidgetDetails