// import http from './http-common'
import { getAccessToken, instance,forminstance, scrapperInstance, parserInstance, parserInstanceQuery, xphenoInstance } from './http-common'
import _ from 'lodash';
import { getLoggedInUserId } from '../utils/user-utils';
import { delay, objectToQueryString } from '../utils/common-utils';
export interface UploadMandate{
   clientId: number;
   MandateFile: string[];
   clientSpoc: string;
   jobTitle: string
}

export interface Mandatedetail {
    id: number
    clientName: string
    title: string
    clientSpoc: string
    requestStartDate: string | null
    requestEndDate: string | null
    createdAt: string | null
    mandateId: string | null
  }
export interface GetClientname{
    clientname:string;
    userid: string;
 }

 
class MandateServiceClass {
   // select dropdown datas
    getClientname(reqObj:any) {
        return instance.post<Array<GetClientname>>(`master/clients`,reqObj);
    }
    getClientnameCreate() {
        return instance.get<Array<GetClientname>>(`master/clients`);
    }
    uploadMandate(formData:any) {
        return forminstance.post('mandate/upload',formData);
    }
    cvUploadProfile(formData:any) {
        return forminstance.post('candidate/upload/profile',formData);
    }
    GetDocumentError() {
        return instance.get<any>(`master/configs?module=mandateUpload`);
    }
    async getMandateList({page, size, filters,pathname, sortColumn, sortDirection}: {page:number, size:number, filters: any,pathname?:any, sortColumn?:any, sortDirection?:any}) {
        const queryString = objectToQueryString({
            page: page+1,
            size,
            userId: getLoggedInUserId(),
            reqStartDate: _.get(filters, 'reqStartDate', ''),
            reqEndDate: _.get(filters,'reqEndDate', ''),
            clientName: _.get(filters,'clientName', '') ? (!isNaN(parseInt(_.get(filters,'clientName', ''))) ? _.get(filters,'clientName', '') : _.get(filters,'clientName', '').toLowerCase()):"",
            keyword: _.get(filters, 'commonFilterText', ''),
            IsApproved: _.get(filters, 'approver', ''),
            statusId:_.get(filters,'status') != undefined ? _.get(filters,'status') : "",
            screen:pathname != undefined ? pathname : "",
            sortColumn: sortColumn != undefined ? sortColumn.length > 0 ? sortColumn : "createdAt" : "createdAt",
            sortDirection: sortDirection != undefined ? sortDirection.length > 0 ? sortDirection : "desc" : "desc"
        })
        // await delay(2000);
        return instance.get<Array<Mandatedetail>>(`mandate/list?${queryString}`);
    }

    getMandateDetail(id:number) {
        return instance.get<any>(`mandate/${id}`);
    }

    getSearchMandates(keyword:string |null) {
        const queryString = objectToQueryString({
            page: 1,
            size:10,
            userId: getLoggedInUserId(),
            keyword:keyword,
            screen: "mandates"
        })
        return instance.get<any>(`mandate/list?${queryString}`);
    }
}
export const MandateService = new MandateServiceClass();

export const createMandateService = async (mandate: any) => {
    const response = await forminstance.post(`mandate/`, mandate);
    return response;

} 

export const updateMandateService = async (mandate: any) => {
    const response = await forminstance.put(`mandate/`, mandate);
    return response;

} 

export const listSearchQuery = async (id: any) => {
    const response = await forminstance.get(`mandate/searchQueries/${id}`);
    return response;

} 

export const createSearchQuery = async (obj: any) => {
    const response = await instance.post(`mandate/searchQuery/`, obj);
    return response;

} 

export const updateSearchQuery = async (obj: any) => {
    const response = await instance.put(`mandate/searchQuery/`, obj);
    return response;

} 

export const artemisProfiles =async (obj: any) => {
    const response = await parserInstanceQuery.post('resume_mandate_matcher',obj)
    return response;    
}
export const initiateRPA = async (id: any) => {
    const response = await instance.put(`mandate/searchQuery/rpa/${id}`);
    return response;

} 

export const locationScrapper = async () => {
    const response = await scrapperInstance.get(`location_scraper/?mode=0`);
    return response;

} 

export const getbranchlocation = async () => {
    const response = await instance.get('master/branches');
    return response;
}

export const getprimaryskill = async (req: any) => {
    const response = await instance.post('mandate/getMandatePrimarySkills',req);
    return response;
}

export const downloadMandateFile = async (req: any) => {
  const response = await forminstance.get(`mandate/download?${req}`)
  return response
} 

export const draftMandateService = async (mandate: any) => {
    const response = await forminstance.post(`mandate/draft`, mandate);
    return response;
}

export const editDraftMandateService = async (mandate: any) => {
    const response = await forminstance.put(`mandate/draft`, mandate);
    return response;
}
export const getAllListMandates = async () => {
    const response = await forminstance.get("mandate/listMandates");
    return response;
}

export const asssignMandate = async (req:any) => {
    try{
        const response = await instance.post(`mandate/assignees`, req);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const getMandateLocation = async (manId:any, query='') => {
    try{
        const response = await instance.get(`mandate/mandatelocation/${manId}${query}`);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const assignCandidateToMandateLocation = async (reqObj:any) => {
    const response = await instance.post(`mandate/assign/mandatelocation`, reqObj);
    return response;
} 


export const getMandateStagesbyOrder = async (manId:number, posId:number , candidateId:number, stageid:number,stageorder:number,isend:number) => {
    const response = await instance.get(`mandate/mandate-stages/${manId}/${posId}/${candidateId}/${stageid}/${stageorder}/${isend}`);
    return response;
} 

export const getMandateStages = async (manId:number, posId:number) => {
    const response = await instance.get(`mandate/mandate-stages/${manId}/${posId}`);
    return response;
} 

export const getMandateStageStatus = async (manId:number, posId:number, stageId:number) => {
    const response = await instance.get(`mandate/mandate-statuses/${manId}/${posId}/${stageId}`);
    return response;
} 

export const getMandateLocationAssignees = async (manId:number, posId:number, stageId:number) => {
    const response = await instance.get(`mandate/mandate-candidate_assignees/${manId}/${posId}/${stageId}`);
    return response;
} 

export const getStatusbyStage = async (stageId:number,statusid:number,statusorder:number,isend:number) => {
    const response = await instance.get(`mandate/statusByStage/${stageId}/${statusid}/${statusorder}/${isend}`);
    return response;
} 

export const getStatusAll = async (mandateId:number,locationId:number,candidateId:number,stageId:number) => {
    const response = await instance.get(`mandate/status-by-stage/${mandateId}/${locationId}/${candidateId}/${stageId}`);
    return response;
} 

export const updateHireStages = async (obj:any) => {
    const response = await forminstance.post(`mandate/update_hiring_stage/V1`, obj);
    return response;
} 

export const getCandidateHireStage = async (obj:any) => {
    const response = await instance.post(`mandate/get_candidate_hiring_stage/v1`, obj);
    return response;
} 

export const getCandidateTrackingRemarks = async (obj:any) => {
    const response = await instance.post(`mandate/candidate/tracking/remark`, obj);
    return response;
}

export const getMandateStatusDropdown = async (obj:any) => {
    const response = await instance.post(`mandate/status/search-filter`, obj);
    return response;
}

export const mandateStatusUpdate = async (obj:any) => {
    const response = await instance.put(`mandate/status/update`, obj);
    return response;
}

export const getMandateAuditLogs = async (obj:any) => {
    const response = await instance.post(`mandate/audit/search-filter`, obj);
    return response;
}

export const getDepartmentRoles = async (obj:any) => {
    const response = await instance.post(`mandate/department/roles/search-filter`, obj);
    return response;
}

export const dropDownData = async (obj:any) => {
    const response = await instance.post(`master/user/system/config`, obj);
    return response;
}

export const dropDownLocation = async (obj:any) => {
    const response = await instance.post(`mandate/primary-secondary/locations/search-filter`, obj);
    return response;
}

export const getIndustryData = async (obj:any) => {
    const response = await instance.post(`mandate/industry/category/search-filter`, obj);
    return response;
}

export const getJobOrderStatus = async (obj:any) => {
    const response = await instance.post(`mandate/location/job/orders`, obj);
    return response;
}
export const getJobOrderDetails =async (mId:any,locationId:any) => {
    const response = await instance.get(`mandate/getJobOrderDetails/${mId}/${locationId}`);
    return response?.data;
}
export const getReceviedEmail =async () => {
    const response = await instance.get(`user/getRecievedByDropDown`);
    return response?.data;
}
export const getJobOrderDetailStatusCount = async (obj:any) => {
    const response = await instance.post(`mandate/job/order/status/count`, obj);
    return response;
}
export const getJobOrderDetailList = async (obj:any) => {
    const response = await instance.post(`mandate/job/order/list`, obj);
    return response;
}
export const getJobOrderDetailStatusDropdown = async (obj:any) => {
    const response = await instance.post(`mandate/status/search-filter`, obj);
    return response;
}
export const getJobOrderDetailStatusType = async (obj:any) => {
    const response = await instance.post(`mandate/job/order/exception/types`, obj);
    return response;
}
export const getJobOrderDetailUpdateStatus = async (obj:any) => {
    const response = await forminstance.put(`mandate/jo/remark/update`, obj);
    return response;
}
export const getClientBasedSpoc =async (clientInfo:any) => {
    const response = await instance.get(`mandate/getClientBasedSpoc/${clientInfo?.clientId}/${clientInfo?.searchKey}`);
    return response?.data;
}
export const postJobPost = async (obj:any) => {
    const response = await xphenoInstance.post(`postjob`, obj);
    return response;
}
export const getIndustriesList =async () => {
    const response = await xphenoInstance.get(`getindustries`);
    return response?.data;
}
export const getJobCaregoriesList =async () => {
    const response = await xphenoInstance.get(`getjobcategories`);
    return response?.data;
}
export const getFunctionalAreaList =async () => {
    const response = await xphenoInstance.get(`getfunctionalareas`);
    return response?.data;
}
export const postJobCareerId = async (obj:any) => {
    const response = await instance.post(`mandate/job/portal/career/update`, obj);
    return response;
}
export const getJobPostData =async (jobPostId:any) => {
    const response = await xphenoInstance.get(`getjob?id=${jobPostId}`);
    return response?.data;
}
export const getCollaboratorList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing/collaborators/list`, obj);
    return response;
}
export const getCollabApproverList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing/approver/list`, obj);
    return response;
}
export const getCollabApproverDetailList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing/details`, obj);
    return response;
}
export const postCollabApproverList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing`, obj);
    return response;
}
export const postApproverUpdateList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing/update/status`, obj);
    return response;
}
export const postCollabRemoveList = async (obj:any) => {
    const response = await instance.post(`mandate/sharing/remove/request`, obj);
    return response;
}
export const getMandateCollaborator =async (manId:number, posId:number, stageId:number) => {
    const response = await instance.get(`mandate//mandate-collaborators-list/${manId}/${posId}/${stageId}`);
    return response;
}