import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
  DialogProps,
  Box,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  createTheme,
  ThemeProvider,
  Grid,
  Autocomplete,
  Checkbox,
  Avatar,
  TextField,
} from '@mui/material'
import { AppButton } from '../../AppComponents/AppButton'
import { AppTextField } from '../../AppComponents/AppTextField'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'
import './MandatesModal.scss'
import { TERTIARY13, PRIMARY2, TERTIARY3, COLOR_WHITE, PRIMARY3 } from '../../../utils/constants'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as HintsIcon } from '../../../assets/icons/info-circle.svg'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg'
import { object, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form'
import _ from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import { Renderplaceholder, AppControlSelect } from '../../AppComponents/AppSelect'
import { MandateService } from '../../../services/MandateService'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import GroupStrick from '../../../assets/images/default-avatar.png'
import { getApprovalLists } from '../../../services/ApprovalListService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { mandateUploadStatus } from '../../../features/mandateDetail/DrawerReducer'

const ModalStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiTypography-root.MuiDialogTitle-root': {
    fontSize: '1rem',
    color: '#000',
    fontWeight: 600,
    lineHeight: '20px',
  },
  // '&.MuiDialog-paper::-webkit-scrollbar': {
  //   display: 'none'
  // }
}))

// theme provider
export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
})

// Dialog titlt props
export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function ModalDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
// type of props taskmodel will recive
type ModalProps = {
  handleClose: () => void
  open: boolean
  title?: string
  children?: React.ReactNode
  onClose?: () => void
  onCreateSuccess: () => void
} & DialogProps

// type of form inputs and incoming props
export type MandateFormInputProps = {
  clientId: string
  jobTitle: string
  clientSpoc: string
  mandateFile: string[]
  uploadId: number
}
export type ErrorResponse = {
  configModule: string
  configValue: string
  configVariable: string
  id: number
}
const maxFileSize = 10000000
const AcceptedFile = [
  'application/pdf',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
export const MandatesModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  onCreateSuccess,
  ...props
}) => {
  const isRecruiter = useAppSelector((state) => state.mandatedrawer.isRecruiter)
  
  const [selectoption, setSelectOption] = useState<[]>([])
  const [errorResponse, setErrorReponse] = useState<Array<ErrorResponse>>([])
  const [errorExtensionState, setErrorExtensionState] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [openmessageModal, setOpenmessageModal] = useState(false)

  const [uploaderror, setUploaderror] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [uploadsuccess, setUploadsuccess] = useState(false)
  const [SuccessMessage, setSuccessMessage] = useState('')

  const [members, setMembers] = useState([])
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />
  const dispatch = useAppDispatch()
  const uploadMandateStatus = useAppSelector((state) => state.mandatedrawer.uploadMandate)

  // Initial values for form input field
  const formSchema = object({
    clientId: z.any().refine(value => (value !== null && value !== undefined && value !== ''),  `Client name is required ` ),
    jobTitle: z.string().min(1, { message: 'Job title is required' }),
    clientSpoc: z.string().min(1, { message: 'Client SPOC is required' }),
    // uploadfile: z.array(z.string()),
    mandateFile: z
      .any()
      .refine((val) => val.length > 0, 'File is required')
      .refine((files) => files?.[0]?.size <= maxFileSize, `Max file size is 10MB.`)
      .refine(
        (files) => AcceptedFile.includes(files?.[0]?.type),
        'PDF, DOCs and Word are accepted',
      ),
      
    approvers: isRecruiter ? z.array(z.string()).min(1, { message: 'Approvers field is required' }) :z.any()
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: FormValues = {
    clientId: undefined,
    jobTitle: '',
    clientSpoc: '',
    mandateFile: [],
    approvers:[]
  }

  const initialvalues: FormValues = {
    clientId: undefined,
    jobTitle: '',
    clientSpoc: '',
    mandateFile: [],
    approvers:[]
  }

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    shouldUnregister: true,
  })

  const [modal, setModalForm] = useState<FormValues>({
    clientId: '',
    jobTitle: '',
    clientSpoc: '',
    mandateFile: [],
    approvers: []
  })

  useEffect(() => {
    methods.reset(modal)
  }, [modal])

  async function ApproverList(){
    const response = await getApprovalLists()
    setMembers(response)
  }


  const handlemodalclose = () => {
    methods.clearErrors()
    methods.reset()
    handleClose()
    setErrorMessage('')
    setIsDisable(false)
  }

  useEffect(() => {
    MandateService.getClientnameCreate()
      .then((res: any) => {
        if(res?.status === 200){
          setSelectOption(res.data?.result?.list[0] || [])
        }
      })
      .catch((error: any) => {
        console.log(error.message)
      })
  }, [open])

  // get error message
  useEffect(() => {
    MandateService.GetDocumentError()
      .then((res: any) => {
        setErrorReponse(res.data)
        const extension = JSON.parse(res.data[1]?.configValue)?.mandate.map((item:any) => {return "."+item.key}).join(", ")
        setErrorExtensionState(extension)
      })
      .catch((error: any) => {
        console.log(error.message)
        setUploaderror(true)
      })
  }, [open])

  // handle error modal
  const handlerrorClose = () => {
    setUploaderror(false)
    setErrorMessage('')
  }
  // success modal
  const handlsuccessClose = () => {
    setUploadsuccess(false)
    setSuccessMessage('')
    handlemodalclose()
  }

  useEffect(() => {
    // reset form with user data
    setModalForm({
      clientId: JSON.stringify(values.clientId),
      jobTitle: values.jobTitle,
      clientSpoc: values.clientSpoc,
      mandateFile: values.mandateFile,
      approvers: values.approvers
    })
    if(isRecruiter){
      ApproverList()
    }
  }, [])

  const [values, setValues] = useState(initialvalues)
  const PreView = methods.watch('mandateFile', [])
  const { error }:any = methods.getFieldState('mandateFile', methods.formState)
  // form submit
  const onSubmitHandler: SubmitHandler<FormValues> = async (formValues: FormValues) => {
    setIsDisable(true)
    console.log('formvalues', formValues)
    console.log('file', formValues.mandateFile[0])
    const formData = new FormData()
    formData.append('clientId', JSON.stringify(formValues.clientId))
    formData.append('clientSpoc', formValues.clientSpoc)
    formData.append('jobTitle', formValues.jobTitle)
    formData.append('mandateFile', formValues.mandateFile[0])

    if(isRecruiter){
      formData.append('approvers', JSON.stringify(formValues?.approvers))
    }

    await MandateService.uploadMandate(formData)
      .then((res) => {
        console.log('res', res)
        setUploadsuccess(true)
        setSuccessMessage('File uploaded and passed to parsing queue')
        dispatch(mandateUploadStatus({uploadMandate: !uploadMandateStatus}))
      })
      .catch((error) => {
        console.log('error', error.message)
        methods.reset()
        setErrorMessage(error.message)
      })
    methods.reset()
    onCreateSuccess()
  }
  const clientNameMapping = _.keyBy(selectoption, 'id')
  // error message modal
  const handleclose = () => {
    setOpenmessageModal(false)
  }
  // get client names

  return (
    <>
      <ThemeProvider theme={theme}>
        <ModalStyle
          onClose={handlemodalclose}
          aria-labelledby='customized-dialog-title'
          open={open}
          fullWidth
          maxWidth='xs'
          className='mandate-modal-style'
        >
          <ModalDialogTitle id='customized-dialog-title' onClose={handlemodalclose}>
            {props.title}
          </ModalDialogTitle>
          <Typography variant='h6' component='h2' className='mandatedetails-header'>
            Mandate Details
          </Typography>
          <FormProvider {...methods}>
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <DialogContent sx={{ pb: 2 }}>
                <Box>
                  <FormControl fullWidth required>
                    <InputLabel id={`labelId`}>Client Name</InputLabel>
                    <AppControlSelect
                      required
                      labelId='select-label'
                      id='select-label'
                      // value={personName}
                      label='Client Name'
                      autoFocus
                      className='form-spaces'
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      startAdornment={<span>{''}</span>}
                      renderValue={(selected: any) => {
                        if (selected.length !== 0) {
                          return _.get(clientNameMapping, `${selected}.clientName`)
                        }
                        return <Renderplaceholder placeholder='--select--' />
                      }}
                      name='clientId'
                    >
                      {selectoption.length > 0 && selectoption.map((name: any) => (
                        <MenuItem title={name.clientName} key={name.id} value={name.id}>
                          {name.clientName}
                        </MenuItem>
                      ))}
                    </AppControlSelect>
                  </FormControl>
                  <AppTextField
                    focused
                    margin='dense'
                    label='Job Title'
                    type='text'
                    fullWidth
                    variant='outlined'
                    placeholder='Jobtitle'
                    borderRadius='10px'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    name='jobTitle'
                    size='small'
                    required
                  />
                  <AppTextField
                    name='clientSpoc'
                    focused
                    size='small'
                    margin='dense'
                    id='clientspoc'
                    label='Primary Client SPOC'
                    type='text'
                    fullWidth
                    variant='outlined'
                    placeholder='Primary Client SPOC'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    required
                  />
                  {isRecruiter ? (
                    <div style={{ marginBottom: '1.8rem' }}>
                      <Controller
                        name='approvers'
                        control={methods.control}
                        render={({ field }) => {
                          const handleChange = (e: any) => {
                            field.onChange(e?.map((ele: any) => ele?.id))
                          }
                          const dataValue = members.length ? members.filter((item: any) => field.value?.includes(item?.id)) : []
                          return (
                            <Autocomplete
                              multiple
                              id='checkboxes-tags'
                              options={members ?? []}
                              limitTags={2}
                              {...field}
                              sx={{ borderRadius: '10px' }}
                              disableCloseOnSelect
                              getOptionLabel={(option: any) => option?.firstName}
                              className={`upload-approver-filed ${!!methods.formState.errors["approvers"] && "autofield-error"}`}
                              value={dataValue ?? []}
                              onChange={(e, value: any) => {
                                handleChange(value)
                              }}
                              renderOption={(props, option: any, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                  />
                                  <Avatar
                                    alt='no-group'
                                    src={GroupStrick}
                                    sx={{ width: 30, height: 30, marginRight: '5px' }}
                                  />
                                  {option?.firstName}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Approver'
                                  placeholder='Approver'
                                  focused
                                  required
                                  error={!!methods.formState.errors["approvers"]}
                                />
                              )}
                            />
                            )
                        }}
                      />
                      {!!methods.formState.errors["approvers"] && (
                        <span className="upload-error-text" role="alert">
                            {methods.formState.errors["approvers"].message as string}
                        </span>
                      )}
                    </div>
                  ) : null}
                  <Box>
                    <input
                      type='file'
                      id='mandateFile'
                      {...methods.register('mandateFile')}
                      accept=".doc, .docx,.txt,.pdf"
                      name='mandateFile'
                    />
                    <label htmlFor='mandateFile' className='upload-custom-btn'>
                      <UploadIcon />
                      <span className='file-upload-text'>Upload Document</span>
                    </label>
                    <Box className='error-message'>{error?.message}</Box>
                    <Box className='helper-boxtext'>
                      {' '}
                      <HintsIcon />
                      <Box style={{marginLeft:"5px"}}>
                        Note {`.doc, .docx,.txt,.pdf`} Maximum size{' '}
                        {errorResponse[0]?.configValue}
                      </Box>
                    </Box>
                  </Box>
                  {PreView.length > 0 && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Tooltip title={PreView[0]?.name}>
                        <Grid item xs={10} className='file-preview-name'>
                          {PreView[0]?.name}
                        </Grid>
                      </Tooltip>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label='delete'
                          color='error'
                          size='small'
                          onClick={() => {
                            methods.unregister('mandateFile')
                          }}
                        >
                          <DeleteIcon fontSize='inherit' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </DialogContent>
              <DialogActions sx={{ mt: 5, mb: 2 }} className='action-btn-cls'>
                <AppButton
                  autoFocus
                  onClick={handlemodalclose}
                  type='button'
                  className='modal-cancel-btn'
                  width={'96px'}
                  bgcolor={TERTIARY3}
                  textColor={PRIMARY3}
                >
                  Cancel
                </AppButton>
                <AppButton
                  // onClick={handleClose}
                  autoFocus
                  type='submit'
                  bgcolor={PRIMARY2}
                  width={'124px'}
                  textColor={COLOR_WHITE}
                  className='modal-assign-btn'
                  disabled={isDisable}
                >
                  Upload
                </AppButton>
              </DialogActions>
            </Box>
          </FormProvider>
        </ModalStyle>
      </ThemeProvider>
      <Box>
        {/* <AppErrorHandlerModal
        open={uploaderror}
        content={errorMessage}
        handleClose={handlerrorClose}
        factor='Error'
        ButtonText='Close'
      >
        <></>
      </AppErrorHandlerModal> */}
        <AppErrorHandlerModal
          open={uploadsuccess}
          content={SuccessMessage}
          handleClose={handlsuccessClose}
          factor='Success'
          ButtonText='Close'
        >
          <></>
        </AppErrorHandlerModal>
      </Box>
    </>
  )
}
