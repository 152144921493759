import React, {useState} from "react";
import CloseIcon from "../../../assets/images/close.svg";
import ProfileImg from "../../../assets/images/profileImage.svg";
import UpArrowIcon from "../../../assets/images/upArrow.svg";
import LinkIcon from "../../../assets/icons/Frame.svg";
import { ReactComponent as HRIcon } from "../../../assets/icons/human-resources.svg";
import _ from 'lodash';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as NoResultIcon } from '../../../assets/icons/no-result-found.svg'

type Trecu = {
    setWorkRecuirterToggle: any;
}
const RecuriterInformation: React.FC<Trecu> = ({setWorkRecuirterToggle}) => {
    const [active, setActive] = useState<any|null>(null)
    const [redruiterInfo, setRedruiterInfo] = useState<boolean>(false)
    const renderSidebarMenus = () => {
        const sidebarMenus = [
            {
                icon: HRIcon,
                title: "Recruiter Information"
            },
        ]
        const sidebarMenuHtml = _.map(sidebarMenus, (value: any, index: number) => {
            const Icon: any = _.get(value, `icon`)
            return (
                <Box key={index}>
                    <Tooltip title={value.title}><Box className="workMangSideMenu" style={{cursor: "pointer"}} onClick={() => { if(index == active){setActive(null); setWorkRecuirterToggle(false)} else{setActive(index);setWorkRecuirterToggle(true)} }}>{Icon && <Icon className={index == active ? 'tabButton-color' : 'tabButton-inactive'} />}</Box></Tooltip>
                </Box>
            )
        })
        return <>{sidebarMenuHtml}</>
    }
    return (
        <>
            <div className='left-part'>
                <div className='header-row-cls'>
                    <div className='header-font'>User Information</div>
                    <img src={CloseIcon} />
                </div>
                <div className="divider-cls"></div>
                {/* {
                    redruiterInfo ? 
                    <> */}
                <div className='prof-details-container'>
                    <div className='prof-header-row'>
                        <div className='left-cls'>
                            <img src={ProfileImg} />
                        </div>
                        <div className='right-cls'>
                            <div className='primary-text'>Rodney Collins</div>
                            <div className='secondary-text'>Senior Recruiter L2</div>
                        </div>
                    </div>
                </div>
                <div className='activity-container'>
                    <div className='header-row'>
                        <div className="left-row-cls">
                            <div className="active-cls"></div>
                            <div className='header-font'>Active Allocations</div>
                        </div>
                        <img src={UpArrowIcon} />
                    </div>
                    <div className='Mandate-col'>
                        <div className='primary-text'>Mandates</div>
                        <div className='row-cls'>
                            <div className='highlighted-text'>10</div>
                            <img src={LinkIcon} />
                        </div>
                    </div>
                </div>
                <div className='hor-divider'></div>
                <div className='activity-container annual-cont-padding'>
                    <div className='header-row'>
                        <div className="left-row-cls">
                            <div className="active-cls"></div>
                            <div className='header-font'>Annual Target</div>
                        </div>
                        <img src={UpArrowIcon} />
                    </div>
                    <div className='flex-row'>
                        <div className='Mandate-col'>
                            <div className='primary-text w-i'>Candidates<br />
                                Onboarded</div>
                            <div className='highlighted-text mt-10'>100<span className='non-highlight-text'>/250</span></div>
                        </div>
                        <div className='Mandate-col'>
                            <div className='primary-text w-i'>Gross Profit <br /> (INR)</div>
                            <div className='highlighted-text mt-10'>120000<span className='non-highlight-text'> /30000</span></div>
                        </div>
                    </div>
                    <div className='flex-row mt-22'>
                        <div className='Mandate-col'>
                            <div className='primary-text w-i'>Profitability Rate</div>
                            <div className='secondary-text'>3.5%</div>
                        </div>
                    </div>
                </div>
                <div className='hor-divider'></div>
                <div className='activity-container annual-cont-padding'>
                    <div className='header-row'>
                        <div className="left-row-cls">
                            <div className="active-cls"></div>
                            <div className='header-font'>Job order closure by Complexity</div>
                        </div>
                        <img src={UpArrowIcon} />
                    </div>
                    <div className='flex-row mt-22'>
                        <div className='Mandate-col'>
                            <div className='primary-text w-i'>{'Ageing > 45days'}</div>
                            <div className='secondary-text mt-10'>35</div>
                        </div>
                        <div className='Mandate-col w-40'>
                            <div className='primary-text w-i'>{'Ageing > 45days'}</div>
                            <div className='secondary-text mt-10'>35</div>
                        </div>
                    </div>
                </div>
                {/* </> 
                :
                <div className='noRecordsFound'><NoResultIcon />
                No Mandate Selected</div>
                } */}
            </div>
            <div className="divider-cls"></div>
            <div className='right-part'>
                <div className='side-nav-bar'>{renderSidebarMenus()}</div>
            </div>
        </>
    )
}

export default RecuriterInformation;