import { Accordion, AccordionSummary, Avatar, Box, FormControl, IconButton, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon1.svg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactComponent as ManageCopilotIcon } from '../../../assets/icons/manageCopilot.svg';
import { ReactComponent as CopilotForApproval } from '../../../assets/icons/task.svg';
import { ReactComponent as CopilotAuditLog } from '../../../assets/icons/auditLog.svg';
import { ReactComponent as ActiveCopilot } from '../../../assets/icons/activeCopilot.svg';
import { ReactComponent as InactiveCopilot } from '../../../assets/icons/inactiveCopilot.svg';
import { ReactComponent as CancelApprover } from '../../../assets/icons/cancelApprover.svg';
import { ReactComponent as TickApprover } from '../../../assets/icons/tickApprover.svg';
import AddCopilotImg from '../../../assets/icons/addCopilotImg.svg';
import ProfileDefault from "../../../assets/images/profileDefault.png"
import GroupStrick from '../../../assets/images/default-avatar.png'
import { ReactComponent as ReasonIcon } from '../../../assets/icons/reject-reason.svg'
import "./ManageCopilot.scss"
import { convertToDisplayDateTime } from '../../../utils/date-utils';
import { AppButton } from '../../AppComponents/AppButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddCopilot from './AddCopilot';
import { changeCopilotStatusAddAPI, getCopilotAuditListAPI, getCopilotListAPI } from '../../../services/UserService';
import { useAppSelector } from '../../../store/hooks';
import moment from 'moment';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    // height: '280px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: "32px 32px 20px"
  }

const CopilotCard = ({type,addClick,data}:any) => {    
    const {userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    return (
        <>
            <Box onClick={() => {if(type == "addCopilot")addClick(type,data,"")}} className={`coPoilotCardsSect ${type == "approvePending" ? "disableCard" : type == "addCopilot" ? "addCopilotCard" : ""}`}>
                <Box className={`cardStatus ${type == "forApproveInactive" ? "cardImgDisable" : "cardImgDisable"}`}>
                    <img src={type == "addCopilot" ? AddCopilotImg : ProfileDefault} />
                    {type == "forApproveInactive" && <p className='inactiveRequest'>Inactive Request</p>}
                    {type == "forApproveNewRequest" && <p className='NewRequest'>New Request</p>}
                </Box>
                <Box className="cardDesc">
                    {type == "addCopilot" ? 
                    <>
                        <p className='addCopilotText'>Add <br></br> Copilot</p>
                    </>
                    :
                    <>
                        <Tooltip title={data.co_pilot_name}><h4>{data.co_pilot_name}</h4></Tooltip>
                        <p>{data.co_pilot_role_name}</p>
                    </>}
                </Box>
                <Box className="cardAction">
                    {type == "inactive" && <InactiveCopilot onClick={() => {if(userPermissions.user_management_module.co_pilot.activate_co_pilot.selected ? true : (userRole == "Admin") ? true : false)addClick(type,data,"")}}/>}
                    {type == "active" && <ActiveCopilot onClick={() => {if(userPermissions.user_management_module.co_pilot.deactivate_co_pilot.selected ? true : (userRole == "Admin") ? true : false)addClick(type,data,"")}}/>}
                    {(type == "forApproveInactive" || type == "forApproveNewRequest") && 
                        <>
                            <TickApprover onClick={() => {addClick(type,data,"tick")}}/>
                            <CancelApprover onClick={() => {addClick(type,data,"cancel")}}/>
                        </>
                    }
                </Box>
                {type == "approvePending" && <Box className="cardApprovalPending">
                    Approval Pending
                </Box>}

            </Box>
        </>
    )
}

const AuditLog = () => {
    const location = useLocation();
    const [userDetailData, setUserDetailData] = useState(location?.state)
    const [pageCount, setPageCount] = useState(5);
	const [pageSize, setPageSize] = useState(10)
	const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [sortingTableHead, setSortingTableHead] = useState<any>({});
    const [sortColumn, setSortColumn] = useState<string>('Module');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [paginationCountSize, setPaginationCountSize] = useState({
        pageSize: 10,
        pageCount: 1,
        keyWordSearch: ""
      })
    const sortTableHeadHandler = (column: any) => {
        let newDirection = '';
        if(!(column in sortingTableHead)){
            newDirection = 'asc';
        }else{
            if (sortDirection === 'asc') {
                newDirection = 'desc';
            } else if (sortDirection === 'desc') {
                newDirection = '';
            } else {
                newDirection = 'asc';
            }
        }
        

        setSortDirection(newDirection);
        setSortColumn(column);
        setSortingTableHead({
            [column]: !(column in sortingTableHead) ? 'Asc' : newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
        });
    };
    const [auditLogList,setAuditLogList] = useState<any>([])
    const getCopilotListAPi = async () => {
        try {
            const res: any = await getCopilotAuditListAPI({
                "pilotId":userDetailData.userId,
                "keyword": paginationCountSize.keyWordSearch,
                "pageSize":paginationCountSize.pageSize,
                "pageNo":paginationCountSize.pageCount,
                "sortColumn":sortColumn,
                "sortOrder":sortDirection
            });
            const copilotData = res.data.data.auditLogList.map((item:any) => {
                return {
                    "firstName": item.full_name,
                    "roleName": item.role_name,
                    "eventType": item.action,
                    "createdAt": moment(item.action_date).format("DD MMM YYYY, HH:mm"),
                    "module": item.module
                }
            })
            setPageCount(res.data.data.totalPages)
            setAuditLogList(copilotData)
        }catch (error: any) {
            console.log(error);
        }
    }
    const updatePagination = (paginationKey:any, changeValue:any) => {
        setPaginationCountSize(prevState => ({...prevState,[paginationKey]: changeValue}))
    }
    useEffect(() => {
        getCopilotListAPi()
    },[paginationCountSize,sortingTableHead])
    return (
        <>
        <Box className="mandate-detail-wrapper manageCopilotAuditTable">
        <Box className="userListFilterSect">
                      <FormControl fullWidth className="userListFilterSearch">
                        <SearchIcon />
                        <TextField
                        label='Search Keyword'
                        value={paginationCountSize.keyWordSearch}
                        onChange={(e:any) => {setPaginationCountSize(prevState => ({...prevState,keyWordSearch: e.target.value}))}}
                    />
                      </FormControl>
                    </Box>
                <Table className="audit-log-table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width:"20%"}}><p onClick={() => sortTableHeadHandler("Module")}>Module {sortingTableHead && sortingTableHead["Module"] ?(sortingTableHead["Module"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                        <TableCell style={{width:"20%"}}><p onClick={() => sortTableHeadHandler("Action")}>Action {sortingTableHead && sortingTableHead["Action"] ?(sortingTableHead["Action"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                        <TableCell style={{width:"25%"}}><p onClick={() => sortTableHeadHandler("Date")}>Date {sortingTableHead && sortingTableHead["Date"] ?(sortingTableHead["Date"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>                       
                        <TableCell style={{width:"20%"}} ><p onClick={() => sortTableHeadHandler("ActionBy")}>Action By {sortingTableHead && sortingTableHead["ActionBy"] ?(sortingTableHead["ActionBy"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {auditLogList?.map((item:any, index:number) => (
                    <TableRow key={index} >
                    <TableCell style={{width:"20%"}}>
                        <span className="primary-text text-ellips" >{item?.module || "-"}</span>
                    </TableCell>
                    <TableCell style={{width:"20%"}}>
                        <Tooltip title={item?.eventType} placement="bottom-start" >
                        <span className="primary-text text-ellips" >{item?.eventType || "-"}</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={{width:"25%"}}>
                        <div className="primary-text" >{convertToDisplayDateTime(item?.createdAt)}</div>
                    </TableCell>                   
                    <TableCell style={{width:"40%"}}>
                        <Box className="d-flex" >
                        <Box>
                            <Avatar
                            alt='no-group'
                            src={GroupStrick}
                            sx={{ width: 30, height: 30 }}
                            />
                        </Box>
                        <Box className='user-detail' >
                            <Tooltip title={item?.firstName} placement="bottom-start">
                            <span className='primary-text ellipsisText1'>{item?.firstName}</span>
                            </Tooltip>
                            <span className='secondary-text'>{item?.roleName || "-"}</span>
                        </Box>
                        </Box>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={pageSize}
									label="Age"
									onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);updatePagination("pageSize",event.target.value)}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={pageCount} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber);updatePagination("pageCount",pageNumber)}}/>
					</Box>
            </Box>
        </>
    )
}

const ManageCopilot = () => {
    const navigate =useNavigate()
    const location = useLocation();
    const [userDetailData, setUserDetailData] = useState(location?.state)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const [copilotTabValue, setCopilotTabValue] = React.useState(userDetailData.defaultTab.toString());
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCopilotTabValue(newValue);
      };      
    const [addCopilotPopupStatus, setAddCopilotPopupStatus] = useState(false)
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const [copilotList, setCopilotList] = useState<any>([])
    const [copilotApproverList, setCopilotApproverList] = useState<any>([])
    const [inactiveCopilotData, setInactiveCopilotData] = useState<any>("")
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const getCopilotListAPi = async () => {
        try {
            const res: any = await getCopilotListAPI({
                userId: userDetailData.userId,
                tabId: copilotTabValue,
                approvedStatus: copilotTabValue == "1" ? "CO_PILOT_APPROVED" : ""
            });
            if(copilotTabValue == "1"){
                setCopilotList(res.data.data)
            }else if(copilotTabValue == "2"){
                setCopilotApproverList(res.data.data)
            }
            console.log("setCopilotList",res.data.data)
            
        }catch (error: any) {
            console.log(error);
        }
    }
    const changeCopilotStatusAPI = async (changeObj:any,type:string) => {
        try {
            const res: any = await changeCopilotStatusAddAPI(changeObj);
            setUploadsuccess(true)
            setSuccessMessage(`New Copilot has been ${type} successfully`);         
        }catch (error: any) {
            console.log(error);
        }
    }
    useEffect(() => {
        getCopilotListAPi()
    },[copilotTabValue])

    const addCopilotHandler = (type:string, data:any) => {
        setAddCopilotPopupStatus(true)       
        if(type == "inactive"){
            setInactiveCopilotData(data)
        }else{
            setInactiveCopilotData("")
        }
    }
    const changeStatusCopilot = (type:string, data:any, btnType:string) => {
        changeCopilotStatusAPI({
            status: (type == "forApproveNewRequest" && btnType == "tick") ? 149 : (type == "forApproveInactive" && btnType == "tick") ? 151 : (type == "forApproveNewRequest" && btnType == "cancel") ? 150 : (type == "forApproveInactive" && btnType == "cancel") ? 149 : 0,
            id: data.id,
            actionType: (type == "forApproveNewRequest" && btnType == "tick") ? "co_pilot_approved" : (type == "forApproveInactive" && btnType == "tick") ? "co_pilot_deactive_approved" : (type == "forApproveNewRequest" && btnType == "cancel") ? "co_pilot_reject" : (type == "forApproveInactive" && btnType == "cancel") ? "co_pilot_deactive_reject" : type == "active" ? "deactive_copilot" : ""
        },(type == "forApproveNewRequest" && btnType == "tick") ? "approved" : (type == "forApproveInactive" && btnType == "tick") ? "removed" : (type == "forApproveNewRequest" && btnType == "cancel") ? "removed" : (type == "forApproveInactive" && btnType == "cancel") ? "restore" : type == "active" ? "Deactivate" : "")
    }
    const closeCopilotPopup = (status:string) => {
        setAddCopilotPopupStatus(false)
        if(status == "load") getCopilotListAPi()
    }
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        getCopilotListAPi()
      }
    return (
        <>
        <Box className='mandate-wrapper manageCopilotSect'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  window.history.back()}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>                    
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }} className="userViewTabBlk">
                    <TabContext value={copilotTabValue}>
                        <Box className="userViewTabSect" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="icon label tabs example">
                                <Tab className={((parseInt(copilotTabValue) - 1) == 1) ? "prev" : ""} icon={<ManageCopilotIcon />} label="Manage Copilot" value="1" />
                                {(userPermissions.user_management_module.co_pilot.approval_co_pilot.selected ? true : (userRole == "Admin") ? true : false) && <Tab className={((parseInt(copilotTabValue) - 1) == 2) ? "prev" : ""} icon={<CopilotForApproval />} label="For Approval" value="2" />}
                                {(userPermissions.user_management_module.co_pilot.history_co_pilot.selected ? true : (userRole == "Admin") ? true : false) && <Tab className={((parseInt(copilotTabValue) - 1) == 3) ? "prev" : ""} icon={<CopilotAuditLog />} label="Audit Logs" value="3" />}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box className="manageCopilotCardSect">
                                {copilotList.map((item:any) => {
                                    console.log("manageCopilotCardSect",moment(moment(item.access_till).format('YYYY-MM-DD')).isAfter(moment()))
                                    return (
                                        <>
                                        <CopilotCard type={`${(item.approval_status == 149 && moment(moment(item.access_till).format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD'))) ? "active" : item.approval_status == 151 ? "approvePending": "inactive"}`} data={item} addClick={(type:string,data:any) => {addCopilotHandler(type,data)}}/>
                                        </>
                                    )
                                })}
                                {(userPermissions.user_management_module.co_pilot.create_co_pilot.selected ? true : (userRole == "Admin") ? true : false) && <CopilotCard type="addCopilot" addClick={(type:string,data:string) => {addCopilotHandler(type,data)}}/>}
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box className="manageCopilotCardSect">
                                {copilotApproverList.map((item:any) => {
                                    console.log("manageCopilotCardSect",moment(moment(item.access_till).format('YYYY-MM-DD')).isAfter(moment()))
                                    return (
                                        <>
                                        <CopilotCard type="forApproveNewRequest" data={item} addClick={(type:string,data:any,selectedBtn:string) => {changeStatusCopilot(type,data,selectedBtn)}}/>
                                        </>
                                    )
                                })}
                                {/* <CopilotCard type="forApproveInactive" data={{}}/> */}
                                {/* <CopilotCard type="forApproveNewRequest"  data={{}}/> */}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box className="manageCopilotCardSect">
                                <AuditLog />
                            </Box>
                        </TabPanel>                        
                    </TabContext>
                </Box>                
            </Box>
            <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>
            {addCopilotPopupStatus && <AddCopilot userDetailId={userDetailData.userId} closeModal={(status:string) => {closeCopilotPopup(status)}} inactiveCopilotData={inactiveCopilotData} selectedUserId={userDetailData.userId}/>}
        </>
    );
};

export default ManageCopilot;