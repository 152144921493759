import React from 'react';
import { Box, Card, CardHeader, CardContent, Typography, Avatar } from '@mui/material';

type CustomTooltipProps = {
  id: string;
  value: number;
  color: string;
  label?: string;
  imageUrl?: string;
  numberOfCandidates?: number;
  tooltipcontentName?: string;
  data?:any;
  tooltipstatus?: {
    enable?: boolean;
    showstatuscolor?: boolean;
    statusname?: string;
    tooltipvaluewithpercentage?: number;
    evaulatepercentage?: number;
    hideSect?:any
  };
  activity?: {
    activitystatus?:string;
    numberOfactivity?:number;
  }
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  id,
  value,
  color,
  label,
  imageUrl,
  numberOfCandidates,
  tooltipcontentName,
  tooltipstatus,
  activity,
  data
}) => {
  console.log("donut", data)
  const showStatusColor = tooltipstatus?.showstatuscolor ?? false;
  const statusName = tooltipstatus?.statusname ?? '';
  const tooltipValueWithPercentage = tooltipstatus?.tooltipvaluewithpercentage ?? 0;
  const evaulatePercentage = tooltipstatus?.evaulatepercentage ?? 0;

  return (
    <Box
      sx={{
        position: 'relative',
        padding: '8px 0px',
        background: 'black',
        border: '1px solid #000',
        borderRadius: tooltipValueWithPercentage ? '5px' : '10px',
        color: color,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderTop: '8px solid black',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-1px)',
          width: 0,
          height: 0,
          borderLeft: '7px solid transparent',
          borderRight: '7px solid transparent',
          borderTop: '7px solid #000',
        },
      }}
    >
      <Card sx={{ maxWidth: 345, backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' } }}>
        {tooltipcontentName && (
          <Box sx={{ fontSize: '12px', paddingBottom: '5px', paddingLeft: "8px", paddingRight:"8px", color: '#c1b8b8', '&:hover': { color: '#fff' } }}>
            {tooltipcontentName}
          </Box>
        )}
        {!tooltipstatus?.hideSect.includes("title") && <Box style={{padding:"0px 10px",fontSize:"14px"}}>{label}</Box>}

        {tooltipstatus?.enable && (
          <>
            {statusName && (
              <Typography variant="body2" sx={{ fontSize: '8px', paddingLeft: "8px", paddingRight:"8px", color: '#c1b8b8', '&:hover': { color: '#fff' } }}>
                {statusName}
              </Typography>
            )}
            <Box style={{paddingLeft: "8px", paddingRight:"8px"}}>
              <Box className="legend-item" sx={{ display: 'flex', alignItems: 'center', gap: '10px', padding: tooltipValueWithPercentage ? 0 : 1 }}>
                {showStatusColor && (
                  <Box className="itm-clr" sx={{ backgroundColor: color, width: '10px', height: '10px' }}></Box>
                )}
                <Box className="itm-val" sx={{ fontSize: '12px' }}>{label}</Box>
              </Box>

              {tooltipValueWithPercentage && (
                <Box style={{paddingLeft: "8px", paddingRight:"8px"}}>
                  <Typography variant="body1" sx={{ fontSize: '12px', color: '#fff' }}>{value} {!tooltipstatus?.hideSect.includes("percentage") && `(${evaulatePercentage}%)`}</Typography>
                </Box>
              )}
            </Box>
            
          </>
        )}

        {imageUrl && (
          <CardHeader
            style={{margin:"0px 10px"}}
            avatar={<Avatar src={imageUrl} aria-label="recipe" />}
            title={
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  maxWidth: '120px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {label}
              </Typography>
            }
            subheader={<Typography sx={{ color: '#ff6e00', fontSize: '10px' }}>{id}</Typography>}
            sx={{ padding: '8px 8px 6px 6px', borderRadius: '5px', backgroundColor: '#484646', '&:hover': { backgroundColor: '#333' } }}
          />
        )}
        {numberOfCandidates && (
          <CardContent sx={{padding: '0px !important', '&:hover': { backgroundColor: '#333' } }}>
            <Box sx={{ padding: '10px 10px 0px 10px !important' }}>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8' }}>Number of Candidates</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>{numberOfCandidates || value}</Typography>
            </Box>
            <Box className="inSerUnserToolSect">
              <p>Inservice <span style={{color:"#69e59f"}}>{data?.inserviceCandidates}</span></p>
              <p>Unservice <span style={{color:"#e96a6a"}}>{data?.unserviceCandidates}</span></p>
            </Box>
            <Box sx={{ padding: '0px 10px 0px 10px !important' }}>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8' }}>Percentage</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>{((value / 100) * 100).toFixed(2)}%</Typography>
            </Box>
          </CardContent>
        )}


      {activity && (
          <CardContent sx={{ padding: '10px 10px 0 10px !important', '&:hover': { backgroundColor: '#333' } }}>
            <Box sx={{ paddingBottom: '10px !important' }}>
              <Typography variant="body2" sx={{ fontSize: '8px', color: '#c1b8b8' }}>Client</Typography>
              <Typography variant="body1" sx={{ fontSize: '12px', color: '#fff' }}>{activity.activitystatus || value}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '8px', color: '#c1b8b8' }}>Candidate Activity</Typography>
              <Typography variant="body1" sx={{ fontSize: '12px', color: '#fff' }}>{activity.numberOfactivity}</Typography>
            </Box>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default CustomTooltip;