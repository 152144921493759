import React, { useEffect, useState } from 'react';
// import { ResponsiveFunnel } from '@nivo/funnel'
import { ResponsiveFunnel, FunnelDatum, FunnelPart } from '@nivo/funnel';
import { Box, Typography } from '@mui/material';
import "./FunnelWidget.css"
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Item from 'antd/es/list/Item';
import { getstageWiseConversation } from '../../../../services/DashboardService';
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks';
import { ReactComponent as RightSmallArrow } from '../../../../assets/icons/rightSmallArrow.svg'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';

const FunnelWidget:any = ({reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels,userRoleObjectSelected,userDependendObj}:any) => {

  const navigate = useNavigate();
  const [funnelStages, setFunnelStages] = useState(["Submittals","Client CV Shortlist","CCM 1","CCM 2","Selects","Offered","Offer Acceptance","Joiners"])
  
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)
  const [effecienychange, seteffecienychange] = useState("conversion");
  const [stageWiseConversationData, setstageWiseConversationData] = useState([]);
    const getstageWiseConversationDataApi = async (reportType:string) => {

      const reportSubmissionAPIDataJson:any = {}
         if(reportSubmissionAPIData !=  null){
             if(Object.keys(reportSubmissionAPIData).length > 0){
                 Object.keys(reportSubmissionAPIData).map((item:any) => {
                     if(reportSubmissionAPIData[item].length > 0){
                     reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                     }
                 })
                }  
	         }
        const requestPayload = {
          ...reportSubmissionAPIDataJson,
          reportType: reportType
        }

        requestPayload["userID"] = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
        delete requestPayload["userId"]
        try{
          const response = await getstageWiseConversation(requestPayload);        
          console.log("My StageWise Funnel Data : ", response?.data)
          response?.data.forEach((item:any) => {
            if (item.label === "Client Shortlist") {
                item.label = "Client CV Shortlist";
            }
        });
          setstageWiseConversationData(response?.data)
        }
          catch(error:any){
            console.log(error)        
          }
      }

      useEffect(() => {
        getstageWiseConversationDataApi(effecienychange)
      },[effecienychange,reportSubmissionAPIData])

    const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card" style={{top:"0%"}}>
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }

      const handleSwitchChange = (type:string) => {
        console.log("seteffecienychange",type)
        seteffecienychange(type);
      };
    
      const currentColorScheme = effecienychange == "effeciency" ? ["#5C73A4","#465F95","#354F88","#29437E","#1F3870","#132B60","#091F50","#051842"] : ["#847897","#847897","#7C6F8F","#6D607C","#60546F","#473A55","#3D314B","#342842"];
    // const currentColors = colorSchemes.find(scheme => scheme.id === currentColorScheme)?.colors || colorSchemes[0].colors;

  // Custom tooltip component
  const CustomTooltip = ({ part }: { part: any }) => {
    const previStage = effecienychange == "conversion" ? funnelStages.indexOf(part.data.label) >= 1 ? funnelStages[funnelStages.indexOf(part.data.label) - 1] : "" : part.data.label != "Submittals" ? "Submittals" : ""
    return (
      <Box
      sx={{
        position: 'relative',
        padding: '8px',
        background: 'black',
        border: '1px solid #000',
        borderRadius: '5px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderTop: '8px solid black',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-1px)',
          width: 0,
          height: 0,
          borderLeft: '7px solid transparent',
          borderRight: '7px solid transparent',
          borderTop: '7px solid #000',
        },
      }}
    >
        <div style={{ color: 'white' }}>
        <Typography sx={{ color: '#FFFFFF', fontSize: '12px',display:"flex",alignItems:"center",gap:"5px", backgroundColor:"#484646",padding:"4px 8px",borderRadius:"5px" }}>{previStage} {previStage.length > 0  && <RightSmallArrow />} {part.data.label}</Typography>
        </div>
        <div style={{ color: 'white', marginTop: '5px' }}>
        <Box>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8',textTransform:"capitalize" }}>{effecienychange}</Typography>
              <Typography variant="body1" sx={{ fontSize: '15px', color: '#fff' }}>{part.data.value} ({Math.round(part.data.percentage * 10) / 10}%)</Typography>
            </Box>
        </div>
    </Box>
    )
  };


const funnelhandleClick = (part: any) => {
  const { id } = part.data
  const userRoleObjectSelectedData = userRoleObjectSelected;
  if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
  navigate(`/FunnelDetailsView/${id}`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels, funnelType: effecienychange, funnelColumnSelected: part.data.label.replaceAll(" ","").toLowerCase(),userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj,funnelCount: part.formattedValue} });
};

if (!stageWiseConversationData.length) {
  return <Typography>Loading...</Typography>;
}

const checkFunnelZeroCase = (data:any) => {
  let count = 0;
  data.map((item:any) => {count = count + item.value})  
  return count == 0 ? false : true
}
      

    return (
      <div className='hiringDemandBgBlk' style={{paddingBottom:"20px"}}>
        {/* <Box className="hiringDemandTitle">
          <h3>Stagewise conversions & Efficiency</h3>
        </Box> */}
      <Box className="hiringDemandTitle">
                <h3 style={{fontSize:"16px"}}>Recruiting Efficiency Funnel</h3>                
                <Box sx={{display:'flex',gap:"20px"}}>
                {checkFunnelZeroCase(stageWiseConversationData) && <Box className="dashboardFunnelSect">
                  <Box onClick={() => handleSwitchChange("conversion")} className={`${effecienychange == "conversion" ? "active":""} dashboardFunnelLeft`}>Conversion</Box>
                  <Box onClick={() => handleSwitchChange("effeciency")} className={`${effecienychange == "effeciency" ? "active":""} dashboardFunnelRight`}>Efficiency</Box>
                </Box>}
                {/* <Box className="hiringDemandTitleLeft">
                    <p><SyncAltIcon /> Compare</p>
                </Box> */}
                </Box>
            </Box>
            <Box style={{position:"relative",minHeight:"400px"}}>
            {checkFunnelZeroCase(stageWiseConversationData) ? <div className={`funnelWidgetSect`} style={{width:'100%',height:"350px",marginBottom:"20px",position:"relative"}}>
              <Box className="funnelLabelTop">
              {stageWiseConversationData.map((item:any, index) => (
                <p key={item?.id}>
                  <span style={{ backgroundColor: currentColorScheme[index] }}></span>
                  {item?.label}
                </p>
              ))}
              </Box>
              <ResponsiveFunnel
                  data={stageWiseConversationData}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  direction="horizontal"
                  colors={currentColorScheme}
                  borderWidth={20}
                  labelColor="#ffffff"
                  // borderColor={effecienychange == "effeciency" ? "#E4ECFF" : "#EEE0FF"}
                  beforeSeparatorLength={40}
                  beforeSeparatorOffset={20}
                  afterSeparatorLength={0}
                  afterSeparatorOffset={20}
                  currentPartSizeExtension={2}
                  currentBorderWidth={25}
                  motionConfig="slow"
                  tooltip={CustomTooltip}
                  onClick={funnelhandleClick}
                  onMouseEnter={(_datum, event:any) => {
                    event.currentTarget.style.cursor = "pointer";
                  }}
                  layers={[
                    'separators',
                    'parts',
                    ({ parts }:any) =>
                        parts.map((part:any) => (
                            <text
                                key={part.data.id}
                                x={part.x0 + (part.x1 - part.x0) / 2}
                                y={part.y0 + (part.y1 - part.y0) / 2}
                                textAnchor="middle"
                                dominantBaseline="central"
                                className='funnelChartLabel'
                            >
                                <tspan x={part.x0 + (part.x1 - part.x0) / 2} dy="-0.5em">
                                  {`${part.data.value}`}
                                </tspan>
                                <tspan style={{fontWeight:"200"}} x={part.x0 + (part.x1 - part.x0) / 2} dy="1.2em">
                                  {`(${Math.round(part.data.percentage * 10) / 10}%)`}
                                </tspan>
                            </text>
                        )),
                ]}
              />
              
          </div>: renderEmptySearchBox()}
          </Box>

          {/* <Box sx={{
            height: 'fit-content',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight:'60px',
            marginTop:'-30px'
          }}><Typography sx={{color:'#002882',padding:'5px',backgroundColor:'#E5E9F3',borderRadius:'5px',marginBottom:'20px'}}>Post Offer Drop</Typography>
          </Box> */}
         
      </div>
    );
};

export default FunnelWidget;