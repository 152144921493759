import { createSlice } from '@reduxjs/toolkit'

interface IMandateProfile {
 location:any,
 mandate:any,
 candidateId:any,
 tabValue:any,
 mandateOptionsData:any
 locationOptionsData:any
 mandateTableColumn:any
 mandateTableType:any
}

const countInitialState: IMandateProfile = {
    location:"",
    mandate:"",
    candidateId:0,
    tabValue:0,
    mandateOptionsData:[],
    locationOptionsData:[],
    mandateTableColumn: {},
    mandateTableType: ""
}

const MandateProfilePersistSlice = createSlice({
  name: 'profilePersist',
  initialState: countInitialState,
  reducers: {
    setProfilePersist: (state, action) => {
          return {
              ...state,
              location: action?.payload?.location,
              mandate: action?.payload?.mandate,
              candidateId: action?.payload?.canId,
              tabValue: action?.payload?.tabValue,
              mandateOptionsData:action?.payload?.mandateOptions,
              locationOptionsData:action?.payload?.locationOptions,
          }
    },
    resetProfilePersist: (state) => {
      console.log(state,'called')
      return {
       ...countInitialState
      }
    },
    mandateTableColumnPersist: (state,action) => {
      state.mandateTableColumn = action.payload.columnHeader,
      state.mandateTableType = action.payload.viewtype
    },
  }
})

export const { setProfilePersist, resetProfilePersist,mandateTableColumnPersist } = MandateProfilePersistSlice.actions
export default MandateProfilePersistSlice.reducer