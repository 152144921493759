import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
// import pages
import CandidateListing from '../pages/CandidateListing'
import CheckList from '../pages/CheckList'
import Configuration from '../pages/Configuration'
import Dashboard from '../pages/Dashboard'
import MyTasks from '../pages/MyTasks'
import Notes from '../pages/Notes'
import Reports from '../pages/Reports'
import Scheduler from '../pages/Scheduler'
import Script from '../pages/Script'
import UserManagement from '../pages/UserManagement'
import Mandates from '../pages/Mandates'
import { Notifications , CallLogs} from '../components/Actions'
import AuditHistory from '../pages/AuditHistory'
import Calendar from '../pages/Calendar'
import AddMandate from '../components/Mandates/AddMandate/AddMandate'
import EditMandate from '../components/Mandates/AddMandate/EditMandate'
import CandidateDetails from '../components/CandidateDetails'
import { useEffect } from 'react'
import { logout } from '../features/auth/AuthReducer'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import jwtDecode from 'jwt-decode'
import AuthVerify from '../common/AuthVerify'
import ChatGPT from '../pages/ChatGPT'
import MandateProfile from '../components/Mandates/MandateProfile/MandateProfile'
import { setSysTemplate, resetSysConfig } from '../features/systemConfig/SystemConfig'
import { getSystemConfig } from '../services/UserService'
import WorkManagement from '../pages/WorkManagement'
import { mandateUnAuthorized } from '../features/mandateDetail/DrawerReducer'
import UserProfile from '../components/UserProfile/UserProfile'
import OverAllReport from '../pages/OverAllReport'
import JobOrderDetailView from '../components/Mandates/MandateDetail/DrawerData/JobOrderDetailView'
import UserRole from '../components/CreateUser/UserList'
import RoleAccess from '../components/RoleAccess/RoleAccess'
import CreateUser from '../components/CreateUser/CreateUser'
import CreateRoleAccess from '../components/RoleAccess/CreateRoleAccess'
import ViewUserDetails from "../components/CreateUser/ViewUserDetails"
import RoleDetailView from "../components/RoleAccess/RoleDetailView"
import PostJobs from '../components/Mandates/PostJobs/PostJobs'
import CreateCandidate from '../pages/CreateCandidate/CreateCandidate'
import ManageCopilot from '../components/CreateUser/ManageCopilot/ManageCopilot'
import HiringDemandComponentdetails from '../components/Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail'
import FunnelDetailsView from '../components/Dashboard/OverviewDashboard/FunnelWidget/FunnelWidgetDetails'
import AllNotification from "../components/RightNavigation/Notification/AllNotification"
import OfferMgtEffective from '../components/Dashboard/OverviewDashboard/OfferManagementWidget/OfferManagementWidgetDetails'
import JobOrderAgeing from '../components/Dashboard/JobOrderAgeing/JobOrderAgeing'
import JobOrderAgeingDetail from '../components/Dashboard/JobOrderAgeing/JobOrderAgeingWidgetDetails'
import ApplicationUsageTrendDashboard from '../components/Dashboard/ApplicationUsage/ApplicationUsage'
import ApplicationUsageTrendDetails from '../components/Dashboard/ApplicationUsage/ApplicationUsageWidgetDetails'
import JobsWithCandidate from '../components/Dashboard/OverviewDashboard/JobswithCandidateWidget/JobsWithCandidateWidgetDetails'
import WelcomeScreen from '../pages/WelcomeScreen'

export const PrivateRoutes = () => {
  const location = useLocation()
  const dispatch:any = useAppDispatch()
  const navigate = useNavigate()
  const pathname = useLocation()?.pathname?.split('/')[1]
  const search = useLocation()?.search
  const userId: any = new URLSearchParams(search).get('userid')
  const userDetailId = useAppSelector((state) => state.UserDetail.userId)

  const token = useAppSelector((state) => state?.auth.token)
  const handleLogout = async () => {
    const accesstoken: { [key: string]: any; id: string } = jwtDecode(token['access_token'])
    const id = accesstoken?.sub
    dispatch(logout(id)).then(() => {
      navigate('/login')
    })
  }

  const getAppConfig = async () => {
    const reqObj = {
      businessUnit: true,
      appConfiguration: true,
      dropdownRequest: []
    }

    try{
      const res = await getSystemConfig(reqObj)
      if(res.status === 200) {
        const obj = {
          businessUnitTemplate: res?.data?.result?.businessUnit?.buMlpTemplate,
          chatGptShow:res?.data?.result?.configuration?.length && 
          res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 29),
          systemConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 33),
          googleIntegrationConfig: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 43),
          welcomeConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 49),
          uploadFileConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 3),
          smartFlowIncomingData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 54),
          confettiEffectTimerData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 56),
          isWelcomePopupEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 58),
          welcomePopupDisplayTimer: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 59),
          welcomePopupContent: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 60),
          welcomePopupClose: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 61),
          welcomeScreenAnimationEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 62),
          JoStatusList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 65),
          toursConfigList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 70),
          buElementAccessRoles: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 72)
        }
        dispatch(setSysTemplate(obj))
      }
    }catch(error){
      console.log(error, "config api error")
    }
  }

  useEffect(() => {
    if (location?.pathname !== '/login') {
      const isExpiry = AuthVerify(token)
      if (isExpiry) handleLogout()
    }
  }, [location])

  useEffect(()=>{
    getAppConfig()
    return () => {
      dispatch(resetSysConfig())
    }
  },[])
  if(pathname.includes("forgot-password-otp")){
    if(userId != userDetailId) dispatch(mandateUnAuthorized({unAuthorizedError: "You are not authorized to use this link"}))
  }
  console.log(userId)

  return (
    <Routes>
      <Route path='/*' element={<WelcomeScreen />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/my-tasks' element={<MyTasks />} />
      <Route path='/candidate-listing' element={<CandidateListing />} />
      <Route path='/work-management' element={<WorkManagement />} />
      <Route path='/scheduler' element={<Scheduler />} />
      <Route path='/candidatePipeline' element={<Reports />} />
      <Route path='/user-management' element={<UserManagement />} />
      <Route path='/configuration' element={<Configuration />} />
      <Route path='/checklist' element={<CheckList />} />
      <Route path='/script' element={<Script />} />
      <Route path='/notes' element={<Notes />} />
      <Route path='/candidate/:id' element={<CandidateDetails />} />
      <Route path='/UserProfile' element={<UserProfile />} />

      {/* moved for public to private routes */}
      <Route path='/mandates' element={<Mandates />} />
      <Route path='/add-mandate' element={<AddMandate />} />
      <Route path='/edit-mandate/:id' element={<EditMandate />} />
      <Route path='/clone-mandate/:id' element={<EditMandate isClone={true} />} />
      <Route path='/post-jobs/:id' element={<PostJobs/>} />
      <Route path='/calendar' element={<Calendar />} />
      <Route path='/notifications' element={<Notifications />} />
      <Route path='/callLog' element={<CallLogs />} />
      <Route path='/audit-history' element={<AuditHistory />} />
      <Route path='/chatgpt' element={<ChatGPT/>} />
      <Route path='/mandates/profiles' element={<MandateProfile/>} />
      <Route path='/analyticsSpace' element={<OverAllReport />} />
      <Route path='/jobOderDetailView' element={<JobOrderDetailView />} />  
      <Route path='/users' element={<UserRole />} />  
      <Route path='/roleAccess' element={<RoleAccess />} />  
      <Route path='/createuser' element={<CreateUser />} />    
      <Route path='/viewuserdetails' element={<ViewUserDetails />} />    
      <Route path='/viewRoledetails' element={<RoleDetailView />} />        
      <Route path='/createrole' element={<CreateRoleAccess />} />    
      <Route path='/manageCopilot' element={<ManageCopilot />} /> 
      <Route path='/createCandidate' element={<CreateCandidate />} />
      <Route path='/hiringDemand/:id' element={<HiringDemandComponentdetails />} />  
      <Route path='/FunnelDetailsView/:id' element={<FunnelDetailsView />} /> 
      <Route path='/viewAllNotification' element={<AllNotification />} /> 
      <Route path='/OfferMgtEffective' element={<OfferMgtEffective />} /> 
      <Route path='/jobOrderAgeing' element={<JobOrderAgeing />} />
      <Route path='/jobOrderAgeingDetail' element={<JobOrderAgeingDetail />} />
      <Route path='/applicationUsage' element={<ApplicationUsageTrendDashboard />} />
      <Route path='/applicationUsageDetail' element={<ApplicationUsageTrendDetails />} />
      <Route path='/applicationUsageRoleWiseDetail' element={<ApplicationUsageTrendDetails />} />
      <Route path='/jobsWithCandidate' element={<JobsWithCandidate />} /> 
    </Routes>
  )
}
