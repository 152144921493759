import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
  DialogProps,
  Box,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Popper,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { AppButton } from '../../AppComponents/AppButton'
import { AppTextField } from '../../AppComponents/AppTextField'
import './TaskModal.scss'
import { TERTIARY13, PRIMARY2, TERTIARY3, COLOR_WHITE, PRIMARY3 } from '../../../utils/constants'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { object, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form'
import { getLoggedInUserId } from '../../../utils/user-utils';
import {
  CreateTaskInfo,
  TaskManagementService,
  UpdateTaskInfo,
} from '../../../services/TaskService'
import _ from 'lodash'
import { AppSelect } from '../../AppComponents/AppSelect'
import { toast } from 'react-toastify'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Margin } from '@mui/icons-material'
import { MandateService } from '../../../services/MandateService'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { objectToQueryString } from '../../../utils/common-utils'
import { useAppSelector } from '../../../store/hooks'
import { getJobTitleapi } from '../../../services/DashboardService'

const ModalStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiTypography-root.MuiDialogTitle-root': {
    fontSize: '1rem',
    color: '#000',
    fontWeight: 600,
    lineHeight: '20px',
  },
}))
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
// dummy select data
const data: any = [
  { value: '1', label: 'Test' },
  { value: '2', label: 'Sample' },
]

// Dialog titlt props
export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function ModalDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

// type of props taskmodel will recive
type ModalProps = {
  handleClose: () => void
  open: boolean
  title?: string
  children?: React.ReactNode
  onClose?: () => void
  editdata?: TaskFormInputProps
  assignedToUsers: []
  onCreateSuccess: () => void
  onUpdateSuccess?: (taskUpdateInfo: UpdateTaskInfo) => void
  mandateOptionsList: []
} & DialogProps

// type of form inputs and incoming props
export type TaskFormInputProps = {
  taskId: number
  taskname: string
  description: string
  assignto: string[]
  duedate:string,
  isHorizontal?:any
  mandates:any,
  topost: boolean
}

export const TaskModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  onCreateSuccess,
  onUpdateSuccess,
  editdata,
  assignedToUsers,
  mandateOptionsList,
  ...props
}) => {
  // Initial values for form input field

  const formSchema:any = object({
    taskname: z.string().min(1, 'Taskname is required'),

    description: z.string().optional(),
    assignto: z.array(z.string()).refine(
      (data) => {
        const assignToItems: string[] = _.get(values, 'assignto', []) || []
        return assignToItems.length > 0
      },
      {
        message: 'Atleast one user should be selected',
      },
    ),
    mandate: z.any().optional(),
    duedate: z.any().refine((data)=> data ?true : false,{message: 'Field required'}),
    topost: z.boolean().optional(),
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: FormValues = {
    taskname: '',
    description: '',
    assignto: [],
    duedate:null,
    isHorizontal:false,
    mandate:[],
    topost: false,
  }

  const initialvalues: FormValues = {
    taskname: '',
    description: '',
    assignto: [],
    duedate:null,
    isHorizontal:false,
    mandate:[],
    topost: false,
  }

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const [horizontalAssignTo, setHorizontalAssignTo]= useState<any[]>([])
  const [assignToOptions, setAssignedToOptions]= useState<any[]>(assignedToUsers)
  const [currentValues, setCurrentValues] = useState<any>({
    horizontal: [],
    vertical: [],
  })
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  const userDetailBuId = useAppSelector((state) => state.UserDetail.businessUnitId)
  const [modal, setModalForm] = useState<FormValues>({
    taskname: '',
    description: '',
    assignto: [],
    duedate:null,
    isHorizontal:false,
    mandate:[],
    topost: false,
  })
  const [taskMandateStoreList, setTaskMandateStoreList] = useState([])
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />
  useEffect(() => {
    // reset form with user data
    setModalForm({
      taskname: values.taskname,
      description: values.description,
      assignto: values.assignto,
      duedate: values.duedate,
      isHorizontal:values.isHorizontal,
      mandate: values.mandate,
      topost: values.topost,
    })
    TaskManagementService.getHorizontalAssignedToUsers()
      .then((res: any) => {
        const list = _.get(res, 'data', [])
        setHorizontalAssignTo(list.filter((ele:any,index:number,self:any[])=>index===self.findIndex(x=>x.id === ele.id)))
      })
      .catch((error: any) => {
        console.log(error.message)
      })
    }, [])

  useEffect(() => {
    methods.reset(modal)
  }, [modal])

  const handleRadioChange = (value:any) => {
    if (value) {
      setAssignedToOptions(horizontalAssignTo)
    }else{
      setAssignedToOptions(assignedToUsers)
    }
  }
  useEffect(() => {
    setAssignedToOptions(methods.getValues("isHorizontal") ? horizontalAssignTo: assignedToUsers)
  }, [methods.getValues("isHorizontal"),horizontalAssignTo,assignedToUsers])
  
  const onSubmitHandler: SubmitHandler<FormValues> = async (formValues: FormValues) => {
    setButtonDisable(true)
    handleClose()
    
    if (editmode) {
      if (!editdata) return
      const taskUpdateInfo: UpdateTaskInfo = {
        taskId: editdata.taskId,
        description: formValues.description,
        name: formValues.taskname,
        assignedTo: values.assignto,
        mandate: formValues.mandate,
        isHorizontal: methods.getValues("isHorizontal"),
        duedate:formValues.duedate,
        topost: formValues.topost,
      }
      await TaskManagementService.updateTask(taskUpdateInfo)
        .then(() => {
          toast.success('Task updated successfully!')
          
          setValues({
            taskname: '',
            description: '',
            assignto: [],
            topost:false,
            duedate:null            
          })
          if (onUpdateSuccess) {
            onUpdateSuccess(taskUpdateInfo)
          }
        })
        .catch((error: any) => {
          console.log(error.message)
        })
        methods.clearErrors()
        setCurrentValues({
          horizontal: [],
          vertical:[]
      })
    } else {
      const taskCreateInfo: CreateTaskInfo = {
        assignedTo: values.assignto,
        description: formValues.description,
        name: formValues.taskname,
        mandate: formValues.mandate,
        duedate:formValues.duedate,
        isHorizontal:methods.getValues("isHorizontal"),
        topost: formValues.V,
      }
      await TaskManagementService.createTask(taskCreateInfo)
        .then(() => {
          toast.success('New task created successfully!')
          methods.reset(modal)
          setValues({
            taskname: '',
            description: '',
            assignto: [],
            topost:false,
            duedate:null
          })
          handleClose()
          onCreateSuccess()
        })
        .catch((error: any) => {
          console.log(error.message)
        })
        methods.clearErrors()
        setCurrentValues({
          horizontal: [],
          vertical:[]
      })
    }
  setButtonDisable(false)
    console.log(errors,'b')
    methods.clearErrors()
    console.log(errors,'a')
  }

  
  const assignToMapping = _.keyBy(assignedToUsers, 'id')

  const handlemodalclose = () => {
    setCurrentValues({
      horizontal: [],
      vertical:[]
    })
    methods.clearErrors()
    handleClose()
  }
  const [values, setValues] = useState(initialvalues)
  const [editmode, setEditmode] = useState(false)

  const {
    trigger,
    formState: { errors },
  } = methods

  useEffect(() => {
    if(JSON.stringify(values) === JSON.stringify({
      taskname: '',
      description: '',
      assignto: [],
      topost:false,
      duedate:null,
    })){
      methods.clearErrors()
    }
    if(values?.assignto.length) methods.clearErrors("assignto")
  }, [values.assignto])

  useEffect(() => {
    if (editdata != null) {
      setModalForm({
        taskname: editdata.taskname,
        description: editdata.description,
        assignto: editdata.assignto,
        duedate:editdata?.duedate,
        mandate:editdata?.mandates?.map((ele:any)=> ele.mandate_id),
        isHorizontal: editdata?.isHorizontal === 0 ? false :true
        
      })
      setValues({
        ...values,
        assignto: editdata.assignto,
      })
      jobtitlecallapi("")
      setEditmode(true)
    }
  }, [editdata])

  const handleSelect = (e: any, v: any) => {
    setValues({ ...values, assignto: v?.map((ele:any)=>ele?.id as string) })
  }
  const PopperMy = React.useCallback((props: any)=> {
    return <Popper {...props} id={"task-autocomp-popper"} placement='bottom' />
  },[])
  const [mandateOptions, setMandateOptions] = useState<any[]>([])

  // useEffect(() => getData(), [])

  useEffect(() => {
    setMandateOptions(mandateOptionsList)
  },[mandateOptionsList])


  const jobtitlecallapi = async (searchTerm: string) =>{
          const queryString = objectToQueryString({
            page: 0,
            size: 100,
            userId: userStateId,
            IsApproved: 1,
            screen:"mandates",
            keyword:searchTerm,
          })
          const reqObj = {
              jobTitleFilterList: [],
              buId: [],
              selectedMandates: searchTerm.length > 0 ? [] : editdata != null ? editdata?.mandates?.map((ele:any)=> ele.mandate_id.toString()) : []
          }
          try {
            const res = await getJobTitleapi(queryString,reqObj);
  
            const JobtitleOptions = _.map(res?.data?.result.list, (data: any,index:number) => {
              return {
                label: _.get(data, 'title')  + ' _ ' + _.get(data, 'mandateCode'),
                value: _.get(data, 'id')
              }
            })
            const uniqueData = JobtitleOptions.filter((item:any, index:any, self:any) =>
                index === self.findIndex((t:any) => t.label === item.label)
            );
            if(taskMandateStoreList.length > 0){
              setMandateOptions([...uniqueData,...taskMandateStoreList])
            }else{
              setMandateOptions(uniqueData)
            }
            
          } catch (error) {
            console.log(error, "config api error");
          }
  
      }

  const onInputChange = _.debounce((event: any, value: string)=>{
    if (_.trim(value) !== '') {
      jobtitlecallapi(value)
    }
  }, 500)
  
  const [buttonDisable, setButtonDisable]=useState(false)
  return (
    <>
      <ModalStyle
        onClose={handlemodalclose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
        maxWidth='xs'
        className='task-assign-modal'
      >
        <ModalDialogTitle id='customized-dialog-title' onClose={handlemodalclose}>
          {props.title}
        </ModalDialogTitle>
        <FormProvider {...methods}>
          <Box
            display='flex'
            flexDirection='column'
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={methods.handleSubmit(onSubmitHandler)}
            className='form-wrapper'
          >
            <DialogContent>
              <Box>
                <AppTextField
                  // autoFocus
                  focused
                  // id='task-name'
                  label='Task Name'
                  type='text'
                  fullWidth
                  variant='outlined'
                  placeholder='Task Name'
                  borderRadius='10px'
                  className='task-modalform'
                  borderColor={TERTIARY13}
                  name='taskname'
                  required
                />

                <AppTextField
                  name='description'
                  focused
                  id='description'
                  label='Description'
                  type='text'
                  fullWidth
                  variant='outlined'
                  multiline
                  rows={5}
                  placeholder='Description'
                  className='task-modalform'
                  borderColor={TERTIARY13}
                />

                <Controller
                  name='isHorizontal'
                  control={methods.control}
                  render={({ field: { onChange, value } }: any) => (
                    // <div style={{display:'flex', alignItems:'center'}}>
                    //   <Checkbox
                    //     size='medium'
                    //     checked={value}
                    //     onClick={(e:any)=>{onChange(e.target.checked)
                    //       e.target.checked? setAssignedToOptions(horizontalAssignTo): setAssignedToOptions(assignedToUsers)
                    //     }}

                    //     // style={{ color: '#002882' }}
                    //     className='search-result-item-checkbox'
                    //   />
                    //   <span style={{ color: '#002882' }}>Is Horizontal</span>
                    // </div>
                    <FormControl>
                      <FormLabel
                        id='demo-controlled-radio-buttons-group'
                        sx={{ color: 'black !important' }}
                      >
                        Hierarchy
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        value={value}
                        sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
                        onChange={(e: any) => {
                          onChange(String(e.target.value) === 'true' ? true : false)
                          handleRadioChange(String(e.target.value) === 'true' ? true : false)
                          const currentChoice:any = (e.target.value) === 'true' ? "horizontal":"vertical";
                          const previousChoice:any = currentChoice === "horizontal" ? "vertical" : "horizontal";
                          setCurrentValues({...currentValues,
                            [previousChoice]: values.assignto
                          })
                          setValues({...values ,assignto: currentValues?.[currentChoice]})
                        }}
                      >
                        <FormControlLabel value={false} control={<Radio />} label='Vertical' />
                        <FormControlLabel value={true} control={<Radio />} label='Horizontal' />
                      </RadioGroup>
                    </FormControl>
                  )}
                />

                <FormControl sx={{ width: '100%' }}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id='multiple-limit-tags'
                    disableCloseOnSelect
                    disableListWrap
                    className={`inner-textfield ${errors['assignto'] && 'autofield-error'}`}
                    PopperComponent={PopperMy}
                    options={assignToOptions}
                    getOptionLabel={(option: any) => option?.firstName}
                    value={assignToOptions?.filter((ele: any) =>
                      values?.assignto?.includes(ele?.id),
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <span>{option.firstName}</span> {<span style={{color:'GrayText',fontSize:'12px', paddingLeft:'5px'}}>{getLoggedInUserId()=== option.id ? '(Assign to me)':''}</span>  }
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} required label='Assign To' />}
                    onChange={handleSelect}
                  />
                  {errors?.assignto && (
                    <span
                      // style={{ color: '#d32f2f', fontSize: '0.75rem', margin: '10px 0 0 0 !important' }}
                      className='error-span'
                    >
                      {'Atleast one user should be selected'}
                    </span>
                  )}
                </FormControl>
                <FormControl sx={{ width: '100%', paddingTop: '10px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name='duedate'
                      control={methods.control}
                      rules={{required:true}}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Due Date'
                          className={`datePicker ${errors['duedate'] && 'autofield-error'}`}
                          inputFormat='DD/MM/YYYY'
                          value={value}
                          onChange={(e: any) => {
                            onChange(e)
                          }}
                          // onError={}
                          components={{
                            OpenPickerIcon: CalenderIcon,
                          }}
                          // required={true}
                          renderInput={(params: any) => (
                            <TextField
                              required
                              name='duedate'
                              style={{ width: '100%' }}
                              {...params}
                            />
                          )}
                          // {...restField}
                        />
                      )}
                    />
                     {errors?.duedate && (
                    <span
                      // style={{ color: '#d32f2f', fontSize: '0.75rem', margin: '10px 0 0 0 !important' }}
                      className='error-span-date'
                    >
                      {'Due date is required'}
                    </span>
                  )}
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                  <Controller
                    name='mandate'
                    control={methods.control}
                    render={({ field: { value, onChange } }) => {
                      const [inputValue, setInputValue] = React.useState('');
                      const handleSelectMandate = (e: any, val: any) => {
                        onChange(val.map((x: any) => x.value))
                        setTaskMandateStoreList(val)
                        setInputValue("")
                        // setValues({ ...values, mandate: val?.map((ele:any)=>ele?.id as string) })
                      }
                      const mandateValue = mandateOptions?.filter((ele: any) =>
                        value?.includes(ele.value),
                      )
                      return (
                        <FormControl sx={{ width: '100%' }}>
                          <Autocomplete
                            multiple
                            limitTags={2}
                            id='multiple-limit-tags'
                            disableCloseOnSelect
                            disableListWrap
                            className={`inner-textfield`}
                            PopperComponent={PopperMy}
                            options={mandateOptions}
                            onInputChange={(e, val, reason) => {
                              if (reason === "input") {
                                setInputValue(val)
                                onInputChange(e, val); 
                              }
                            }}
                            getOptionLabel={(option: any) => option?.label}
                            value={mandateValue}
                            inputValue={inputValue}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                <Box
                                  sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                                >
                                  <Typography
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {option.label?.split('_')[0]}
                                  </Typography>
                                  <Typography className='dropdownSubtext'>
                                    {option.label?.split('_')[1]}
                                  </Typography>
                                </Box>
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params}  label='Mandates' />
                            )}
                            onChange={handleSelectMandate}
                          />
                        </FormControl>
                      )
                    }}
                  />
                </FormControl>
                {/* <Controller
                  name='topost'
                  control={methods.control}
                  render={({ field: { value, onChange } }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        size='medium'
                        checked={value}
                        onClick={onChange}
                        // style={{color:'#002882'}}
                        className='search-result-item-checkbox'
                      />
                      <span style={{ color: '#002882' }}>Post in Google Calender</span>
                    </div>
                  )}
                /> */}
              </Box>
            </DialogContent>
            <DialogActions>
              <AppButton
                autoFocus
                onClick={handlemodalclose}
                type='button'
                className='modal-cancel-btn'
                width={'96px'}
                bgcolor={TERTIARY3}
                textColor={PRIMARY3}
              >
                Cancel
              </AppButton>

              <AppButton
                // onClick={handleClose}
                autoFocus
                type='submit'
                disabled={buttonDisable}
                bgcolor={PRIMARY2}
                width={'124px'}
                textColor={COLOR_WHITE}
                className='modal-assign-btn'
              >
                {!editmode ? 'Assign' : 'Save Changes'}
              </AppButton>
            </DialogActions>
          </Box>
        </FormProvider>
      </ModalStyle>
    </>
  )
}
