/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Icon,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItem,
  Tooltip,
  Box,
  ListItemText,
  Badge
} from '@mui/material'
import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Route } from '../../../../types/Route'
import '../Routes.css'
import {useAppSelector } from "../../../../store/hooks";

interface RouteItemProps {
  route: Route
  nested?: boolean
  hasChildren?: boolean
  handleMenuClick?: (route: Route) => void
  open: boolean | undefined
  toggleQuickView?: () => void
  toggleCallLogView?: () => void
}

export const RouteItem = ({
  route,
  hasChildren = false,
  handleMenuClick = () => {},
  open,
  toggleQuickView,
  toggleCallLogView
}: RouteItemProps) => {
  const location = useLocation()
  const notificationCount = useAppSelector((state:any)=>state?.notificationState?.count)
  const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!route.isEnabled || hasChildren) e.preventDefault()
    if(route?.title === "Notifications"){
      e.preventDefault()
      if(toggleQuickView) toggleQuickView()
    }
    if(route?.title == "Call Log"){
      e.preventDefault()
      if(toggleCallLogView) toggleCallLogView()
    }
  }

  const isSelected =
    location.pathname === route.path ||
    (hasChildren && route.subRoutes?.some((e) => location.pathname === e.path))
  const item = (
    <ListItem disablePadding sx={{ display: 'block' }} className='right-router-item'>
      <ListItemButton
        css={css`
          width: ${!open && '50px'};
        `}
        onClick={() => handleMenuClick(route)}
        className={`rightMenuSection ${route.active ? "active" : "inactive"} ${
          isSelected && !hasChildren ? 'right-nav-selected-route' : 'default-router-item'
        }`}
      >
        {!hasChildren && (
          <ListItemIcon className='router-icon-button' style={route?.title==="Notifications"?{position:"relative"}:{}}>
            <IconButton>
              
              {route.icon && (
                <Icon
                  component={route.icon}
                  css={css`
                    fill: ${isSelected ? '#000000' : '#727376'};
                    min-width: ${hasChildren && '0px'};
                  `}
                  className={`${route.title == "Add Page" ? "addPageRouterIcon" : ""} router-icon`}
                />
              )}
            </IconButton>
            {
              route?.title==="Notifications" &&(
                <div className='notification-count-cls'>{notificationCount??0}</div>
              )
            }
          </ListItemIcon>
        )}
        {open && (
          <ListItemText
            className={` ${hasChildren ? 'route-title route-title-parent' : 'route-title'}`}
          >
            {route.title}{' '}
          </ListItemText>
        )}

        <Box
          className={` ${isSelected && !hasChildren ? 'right-nav-selected-route-highlighter' : ''}`}
        ></Box>
      </ListItemButton>
    </ListItem>
  )
  
  return (
    <NavLink
      to={`${route.path}`}
      key={route.key}
      onClick={handleNavigate}
      className={`${route.disable ? "disableRightLink" : route.path?.replace("/","")}`}
      css={css`
        text-decoration: none;
        color: inherit;
      `}
    >
      {route.description ? (
        <Tooltip
          title={`${route.description}${!route.isEnabled ? ' (Not Allowed)' : ''}`}
          placement='left'
        >
          <div>{item}</div>
        </Tooltip>
      ) : (
        item
      )}
    </NavLink>
  )
}
