import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/lightcloseIcon.svg'
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import '../HiringDemandCard/HiringDemandCardDetail.scss'
import { IMaskInput } from 'react-imask';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactComponent as ClientIcon } from '../../../../assets/icons/widgets/Client.svg'
import { ReactComponent as MandateIcon } from '../../../../assets/icons/widgets/Mandate.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/icons/widgets/candidate.svg'
import { getFunnelConversionListApi, gethiringdemandlistapi } from '../../../../services/DashboardService';
import DashboardFilterIndex from '../../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import HiringDemandTableSection from '../HiringDemandCard/HiringDemandTableSection';
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }


const ScrollableBox = styled(Box)({
  height: '300px',  // Set the desired height
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0px',  // Hide scrollbar for WebKit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  scrollbarWidth: 'none',  // Hide scrollbar for Firefox
  msOverflowStyle: 'none',  // Hide scrollbar for IE 10+
});

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
	function TextMaskCustom(props, ref) {
	  const { onChange, ...other } = props;
	  return (
		<IMaskInput
		  {...other}
		  mask="0000-00-00"
		  definitions={{
			'#': /[1-9]/,
		  }}
		  inputRef={ref}
		  onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
		  overwrite
		/>
	  );
	},
  );
  


const FunnelWidgetDetails = () => {

    
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>(location)
    const [funnelType, setFunnelType] = useState(HirningDemandParamsDetails?.state?.funnelColumnSelected ? HirningDemandParamsDetails?.state?.funnelColumnSelected : "")
    const [funnelReportType, setFunnelReportType] = useState(HirningDemandParamsDetails?.state?.funnelType)
    const [exportdatareqest,setexportdatareqest] = useState<any>({})
    const [candidates, setCandidates] = useState<any>({});
    const [initalCount, setInitalCount] = useState(0)
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [userDependendObj,setUserDependendObj] = useState({})
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: any }>({});
    const [hiringDemandTabDetail,setHiringDemandTabDetail] = useState<any>({
        sortColumn: "",
        sortDirection: "",
        paginationCountSize: {
          inputPageSize: 10,
          pageSize: 1,
          inputPageNo: 1,
          totalRecords: 0,
        },
        textFieldValues: ""
      })
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
    function toCamelCaseAndAppend(str:string, appendWord:string) {
      const camelCaseStr = str
          .toLowerCase()
          .split('_')
          .map((word, index) =>
              index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join('');
      
      return camelCaseStr + appendWord.charAt(0).toUpperCase() + appendWord.slice(1);
  }
    const hiringListApi = async () => {
      const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
      const reqObj:any = {
        "reportType":funnelReportType, 
        "coe": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.coe : HirningDemandParamsDetails?.state?.filterList?.coe,
        "createdAt": "",
        "createdBy": "",
        "jobTitle":  Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobTitle : HirningDemandParamsDetails?.state?.filterList?.jobTitle,
        "profileLastActiveDate": "",
        "userId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.userId : HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId] : [userId],
        "pageSize": hiringDemandTabDetail.paginationCountSize.inputPageSize,
        "pageNo": hiringDemandTabDetail.paginationCountSize.inputPageNo,
        "clientId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.client : HirningDemandParamsDetails?.state?.filterList?.client || undefined,
        "joLocation": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.joLocation : HirningDemandParamsDetails?.state?.filterList?.joLocation || [],
        "businessUnit": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.businessUnit : HirningDemandParamsDetails?.state?.filterList?.businessUnit || undefined,
        "mandateType": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateType : HirningDemandParamsDetails?.state?.filterList?.mandateType || [],
        "jobMode": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobMode : HirningDemandParamsDetails?.state?.filterList?.jobMode || [],
        "mandateSPOC": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateSPOC : HirningDemandParamsDetails?.state?.filterList?.mandateSPOC || [],
        "roles":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.roles : HirningDemandParamsDetails?.state?.filterList?.roles || [],
        "primarySkills":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.primarySkills : HirningDemandParamsDetails?.state?.filterList?.primarySkills || [],
        "resultStage": funnelType,
        "customEndDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customEndDate : HirningDemandParamsDetails?.state?.filterList?.customEndDate || "",
        "customStartDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customStartDate : HirningDemandParamsDetails?.state?.filterList?.customStartDate || "",
      };
      Object.keys(hiringDemandTabDetail.textFieldValues).map((item:any) => {
        if(hiringDemandTabDetail.textFieldValues[item].length > 0)reqObj[toCamelCaseAndAppend(item,"search")] = hiringDemandTabDetail.textFieldValues[item]
      })
      if(hiringDemandTabDetail.sortColumn.length > 0){reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn}else{reqObj["sortColumn"] = "candidate_name"}
      if(hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection}else{reqObj["sortDirection"] = ""}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}
        try {
            const res: any = await getFunnelConversionListApi(reqObj);
            setCandidates({"data":res?.data,"requestData":res?.data.data});
            setexportdatareqest(reqObj)

        } catch (error: any) {
            console.log(error);
        }
    };

        useEffect(() => {
            hiringListApi();
          }, [hiringDemandTabDetail]);

        useEffect(() => {
          if(initalCount == 0){
            setInitalCount(1)
          }else{
            setHiringDemandTabDetail({
              sortColumn: "",
              sortDirection: "",
              paginationCountSize: {
                inputPageSize: hiringDemandTabDetail.paginationCountSize.inputPageSize,
                inputPageNo: 1,
                pageSize: 10,
                totalRecords: 0,
              },
              textFieldValues: ""
            })
          }
      }, [reportSubmissionAPIData,HirningDemandParamsDetails])
        const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card">
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }

      const HiringHeader = `<Box>
      <p class="breadcrumbFilter">Recruitment ${funnelType[0].toUpperCase() + funnelType.slice(1)} Rate (${HirningDemandParamsDetailsCopy?.state?.funnelCount ? HirningDemandParamsDetailsCopy?.state?.funnelCount : candidates?.data.data.totalRecords})</p>
      <Typography display='flex' gap={'5px'} class='dashboardDetailHeaderTitle' style="margin-top:-15px;margin-bottom:10px;cursor:pointer">
          <span style="color:gray">Overview Dashboard > </span><span style="color:#0033a0">Recruitment ${funnelType[0].toUpperCase() + funnelType.slice(1)} Rate</span>
      </Typography>
    </Box>`

  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px'}}>
            <Box className="funnelDetailWidgetSect">
              <Box sx={{padding: '10px' }}>
              <Box sx={{paddingX:'5px'}}><KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/dashboard",{ state: {dashboardfilterObj:HirningDemandParamsDetailsCopy?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetailsCopy?.state?.filterList ,filterListLabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels, userFilterObj: HirningDemandParamsDetailsCopy?.state?.userFilterObj, clientDependedObj: HirningDemandParamsDetailsCopy?.state?.clientDependedObj } })}}/></Box>
                <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={HiringHeader} downloadName={`Recuritment Submittals Rate`} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={"subScreen"} screenType={"funnelDrill"}/>
                </Box>
                
                {Object.keys(candidates).length > 0 ? <HiringDemandTableSection candidates={candidates} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={""}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
            </Box>
        </div>
    </div>
  )
}

export default FunnelWidgetDetails