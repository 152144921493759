import React, { useEffect, useState } from 'react';
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputLabel, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, Select, MenuItem  } from '@mui/material'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY12, TERTIARY3 } from '../../utils/constants';
import { AppButton } from '../AppComponents/AppButton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg'
import { ReactComponent as AddNewIcon } from '../../assets/icons/add-new.svg'
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as RotateIcon } from '../../assets/icons/reset.svg'
import { getBussinessUnit, getUserReportingTo } from '../../services/UserService';
import _ from 'lodash';
import { useAppSelector } from '../../store/hooks';

interface IFormInput {
  searchKey: string;
  bussinessUnit: any;
  reportingManager: any;
  status: any;
}

const UserFilter = ({totalList,setUserListFilter}:any) => {
  const initValues = {
    searchKey: "",
    bussinessUnit: null,
    reportingManager: null,
    status: null
  }
  const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });

    const {
      control,
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      reset,
      clearErrors 
    } = methods
    const [employeeBussinessUnit, setEmployeeBussinessUnit] = useState([])
    const [filterChecked, setfilterChecked] = useState<boolean>(false)
    const [reportingToDisable,setReportingToDisable] = useState(true)
    const [reportingToList, setReportingToList] = useState([])
    const userDetailBuId = useAppSelector((state) => state.UserDetail.businessUnitId)
    const navigate =useNavigate()
    const handleFilter = () => {
        setfilterChecked((prev) => !prev)
      }
    const userStstus = [
      {
        statusName: "Active",
        value : 1
      },
      // {
      //   statusName: "Inactive",
      //   value : 2
      // },
      {
        statusName: "Re Joined",
        value : 137
      },
      {
        statusName: "De Activated",
        value : 136
      }
  ] 
    const getBussinessUnitDetailsApi = async () => {
      try{
        const res:any = await getBussinessUnit({"buIds": userDetailBuId != null ? [userDetailBuId?.toString()] : []})
        setEmployeeBussinessUnit(res.data)
      }
      catch(error:any){
          console.log(error)        
        }
    }
    const getReportingToApi = async (buId:string) => {
      try{
        const res:any = await getUserReportingTo(buId)
        const options = _.map(res.data, (data: any) => {
          return {
            label: _.get(data, 'firstName'),
            value: _.get(data, 'id')
          }})
        setReportingToDisable(false)
        setReportingToList(options)
      }
      catch(error:any){
          console.log(error)        
        }
  }
    useEffect(() => {
      getBussinessUnitDetailsApi()
    },[])
    const mandateSubmitHandler = async (data:any, event:any) => {
        setUserListFilter({
          "keyword": data?.searchKey,
          "businessUnit": data?.bussinessUnit,
          "reportingTo": data?.reportingManager?.value,
          "status": data?.status
        })
    }
    const filterDataReset = () => {
      reset(initValues);
      setReportingToDisable(true)
      setUserListFilter({
        "keyword": "",
        "businessUnit": "",
        "reportingTo": "",
        "status": ""
      })
    }
    
    return (
        <div className='userFilterSect'>
            <Paper className='box-style' elevation={1}>
            <Box display='flex' justifyContent='space-between' className='filter-parent-box'>
              <Box className='tasklist' >
                <Typography display='flex' gap={1} className='tasklist-title'>
                    Artemis Users Lists <Typography color={PRIMARY2}>({totalList})</Typography>
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between' style={{padding:"5px"}}>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                <Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  {/* <AppButton minwidth={40} margin={-5}>
                    <SortIcon />
                  </AppButton> */}
                  <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    width={'80px'}
                    className='class-new-button'
                    onClick={() => navigate('/createuser')}
                    tooltip='Add New User'
                >
                    <AddNewIcon style={{ marginRight: '10px' }} /> New
                </AppButton>
                </Box>
              </Box>
            </Box>

    
            {filterChecked && (
              <Box className="userFilterSectAction">
                <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
                  <Grid item xs={12} md={12} sm={3} lg={12}>
                    <Typography className='filter-title'> Filter</Typography>
                  </Grid>
                  <Box component={'form'} onSubmit={handleSubmit(mandateSubmitHandler)}>
                    <Box className="userListFilterSect">
                      <FormControl fullWidth className="userListFilterSearch">
                        <SearchIcon />
                        <Controller
                            name={"searchKey"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                              <TextField
                              label='Search Keyword'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                            )}
                            />
                      </FormControl>
                      <FormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Business Unit</InputLabel>
                        <Controller
                            name={"bussinessUnit"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            defaultValue=""
                            label="Business Unit"
                            onChange={(e:any) => {
                                onChange(e.target.value)
                                getReportingToApi(e.target.value)
                            }}
                            >
                            {employeeBussinessUnit.map((item:any,index:number) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.businessUnitName}</MenuItem>
                                ))}
                            </Select>
                            )}
                            />
                      </FormControl>
                      <FormControl fullWidth >
                        <Controller
                            name={"reportingManager"}
                            control={control}
                            render={({ field: { onChange, value} }) => (
                              <Autocomplete                                disabled={reportingToDisable}
                                options={reportingToList}
                                defaultValue={getValues("reportingManager")}
                                value={value?.label}
                                onChange={(event, value) => {
                                  onChange(value)
                                }}
                                renderInput={(params) => <TextField required={true} {...params} label={`Reporting Manager`} />}
                              />                          
                              )}
                            />
                      </FormControl>
                      <FormControl fullWidth >
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Controller
                            name={"status"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Status"
                            onChange={(e:any) => {
                                onChange(e.target.value)
                            }}
                            >
                            {userStstus.map((item:any,index:number) => (
                                <MenuItem key={index} value={item?.value}>{item?.statusName}</MenuItem>
                                ))}
                            </Select>
                            )}
                            />
                      </FormControl>
                    </Box>
                    <Grid className="filter-btn-container" item xs={12} sm={6}>
                      <Stack justifyContent="end" gap={1} direction='row'>
                          <Box className='filter-search-btn'>
                            <AppButton
                              label='Search'
                              bordercolor='black'
                              bgcolor='black'
                              size='large'
                              type='submit'
                              className='search-bth'
                              sx={{ color: '#fff' }}
                            />
                          </Box>
                          <Button
                            sx={{
                              color: '#002882',
                              textTransform: 'none',
                            }}
                            variant='text'
                            // onClick={resetAllFilters}
                          >
                            <RotateIcon />
                            <Typography className='reset-label' onClick={() => {filterDataReset()}}>Reset All</Typography>
                          </Button>
                        </Stack>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </div>
    );
};

export default UserFilter;