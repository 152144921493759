import { createSlice } from '@reduxjs/toolkit'
import * as _ from "lodash";

export interface FilterState {
    filters: any
    filterPreloadData: any;
    reportFiler?:any;
    industryOptionData?:any
}

const initialFilterState: FilterState = {
    filters: {},
    filterPreloadData: {},
    reportFiler: {},
    industryOptionData: []
}

const filterSlice = createSlice({
    name: 'filters',
    initialState: initialFilterState,
    reducers: {
        setFilterValues: (state, action) => {
            const newFilterUpdate = {
                ...state.filters,
                ...action.payload,
            };
            if(JSON.stringify(newFilterUpdate) !== JSON.stringify(state.filters)) {
                state.filters = newFilterUpdate;
            }
        },
        setFilterPreloadData: (state, action) => {
            const newFilterPreloadDataUpdate = {
                ...state.filterPreloadData,
                ...action.payload,
            };
            if(JSON.stringify(newFilterPreloadDataUpdate) !== JSON.stringify(state.filterPreloadData)) {
                state.filterPreloadData = newFilterPreloadDataUpdate;
            }
        },
        setFixedFilterValues: (state, action) => {
            const newData = _.get(action, 'payload', {});
            state.filters = newData;
        },
        resetFilters: (state) => {
            state.filters = {};
        },
        resetApiFilters: (state, action) => {
            return {
                ...state,
                filters: action?.payload,
              }
        },
        resetFilterPreloadData: (state, action) => {
            const newData = _.get(action, 'payload', {});
            state.filterPreloadData = newData;
        },
        reportFilterLoadData: (state, action) => {
            state.reportFiler = action.payload.reportFiler
        },
        industryDropDownData: (state, action) => {
            state.industryOptionData = action.payload.industryOptionData
        },
    },
})

export const { 
    setFilterValues, 
    setFixedFilterValues,
    resetFilters,
    setFilterPreloadData,
    resetFilterPreloadData,
    reportFilterLoadData,
    resetApiFilters,
    industryDropDownData
 } = filterSlice.actions
export default filterSlice.reducer