import { Box, Tooltip } from '@mui/material';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import "./HiringDemandCard.scss"
import { ReactComponent as HiringClients } from '../../../../assets/icons/hiringClients.svg'
import { ReactComponent as HiringMandate } from '../../../../assets/icons/hiringMandate.svg'
import { ReactComponent as HiringJobOrders } from '../../../../assets/icons/hiringJobOrders.svg'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import LaunchIcon from '@mui/icons-material/Launch';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getHiringDemand, getHiringDemandList } from '../../../../services/DashboardService';
import { useNavigate } from 'react-router-dom'
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useAppSelector } from '../../../../store/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface TooltipProps {
    title: string;
    children: React.ReactElement;
}

const CustomTooltip: React.FC<TooltipProps> = ({ title, children }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {children}
            {isHovered && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: '3px',
                        whiteSpace: 'nowrap',
                        zIndex: 1000,
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '-5px',
                            left: '50%',
                            transform: 'translateX(-50%) rotate(45deg)',
                            width: '10px',
                            height: '10px',
                            backgroundColor: '#333',
                            zIndex: -1,
                        }}
                    />
                    {title}
                </div>
            )}
        </div>
    );
};


const HiringDemandCard : React.FC<any>= ({reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels,userRoleObjectSelected,userDependendObj}:any) => {
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const dataToPass = reportSubmissionAPIData;
    const [hiringPercentageStatus,setHiringPercentageStatus] = useState(false)
    const [selectedCard, setSelectedCard] = useState({
        columnName:"",
        selectedIndex: ""
    });
    const navigate = useNavigate()

    const [HiringDemandData,setHiringDemandData] = useState([])
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

    const getHiringDemandDataApi = async () => {
        const reportSubmissionAPIDataJson: any = {
            dateRange: ""
        };        
        if(reportSubmissionAPIData !=  null){
            if(Object.keys(reportSubmissionAPIData).length > 0){
                Object.keys(reportSubmissionAPIData).map((item:any) => {
                    if(reportSubmissionAPIData[item].length > 0){
                        reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                    }
                })
               }  
        }
        const userIdData = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
        // const userIdData = userId
        delete reportSubmissionAPIDataJson["userId"]
        try{
          const listresponse = await getHiringDemandList({
            ...reportSubmissionAPIDataJson, 
            userID: userIdData,
        })
          setHiringDemandData(listresponse?.data)
          getDateKeySelected()
        }
          catch(error:any){
            console.log(error)        
          }
      }

      useEffect(() => {
        getHiringDemandDataApi()
      },[reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels])

      const hiringDemandJson:any = HiringDemandData;

      const handleStatusClick = (status: string) => {
        setSelectedStatus(status);
    };

    const getDateKeySelected = () => {
        let toggleStatus = false;
        if(reportSubmissionAPIData != undefined){
            if("customEndDate" in reportSubmissionAPIData ) toggleStatus = true
        }else{
            toggleStatus = false
        }
        setHiringPercentageStatus(toggleStatus)
    }

const handleClick = (selectedList:any, id:any,resultypekey:any,resultstagekey: string,type:string) => {
    const userRoleObjectSelectedData = userRoleObjectSelected;
    if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
    navigate(`/hiringDemand/${id}`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels , data: selectedList, datahandle:{resultType:resultypekey,resultStage:resultstagekey },userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj,cardType: type, tabSwitch: type == "leftCard" ? resultypekey == "Client" ? "1" : "2" : "1"} });
  };
  
    return (
        <div className='hiringDemandBgBlk'>
            <Box className="hiringDemandTitle">
                <h3 style={{fontSize:'16px'}}>Hiring Demand Dashboard</h3>
            </Box>
            <Box className="hiringDemand">
                <ul className='hiringDemandHeader'>
                    <li>
                        <Box>
                            <Box className="parentTree firstCard" onClick={() => handleStatusClick('')}>
                                Search Results
                            </Box>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="Active">
                            <Box className="parentTree" onClick={() => handleStatusClick('active')}>
                                <span style={{ backgroundColor: "#0E94F4" }}></span>
                                Active
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="Indicates activity from the point of submission to client">
                            <Box className="parentTree" onClick={() => handleStatusClick('inservice')}>
                                <span style={{ backgroundColor: "#002882" }}></span>
                                In service
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="Pending allocation to to recruiter / Pre client submission activity">
                            <Box className="parentTree" onClick={() => handleStatusClick('unservice')}>
                                <span style={{ backgroundColor: "#727376" }}></span>
                                Un serviced
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="On hold by Xpheno">
                            <Box className="parentTree" onClick={() => handleStatusClick('onHoldXpheno')}>
                                <span style={{ backgroundColor: "#FF6E00" }}></span>
                                On Hold By xpheno
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="On hold by client">
                            <Box className="parentTree" onClick={() => handleStatusClick('onHoldClient')}>
                                <span style={{ backgroundColor: "#FFCC00" }}></span>
                                On Hold By Client
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="Lost opportunities">
                            <Box className="parentTree" onClick={() => handleStatusClick('lostOpportunity')}>
                                <span style={{ backgroundColor: "#F34334" }}></span>
                                Lost Opportunity
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                    <li>
                        <Box>
                        <CustomTooltip title="Onboards">
                            <Box className="parentTree" onClick={() => handleStatusClick('onBoard')}>
                                <span style={{ backgroundColor: "#2BAD63" }}></span>
                                Onboards
                            </Box>
                            </CustomTooltip>
                        </Box>
                    </li>
                </ul>
            </Box>
            <Box className="hiringDemand">
                <ul className='hiringDemandBody'>
                {Object.keys(hiringDemandJson).map((hiringItem:any,parentIndex:number) => {
                    return (
                        <li key={parentIndex}>
                            <ul>
                                <li className="firstCardLi">
                                    <Box>
                                        <Box className={`parentTree firstCard ${hiringItem}`}>
                                            <Box className="firstCardSect" style={{cursor: (hiringDemandJson[hiringItem].Name == "Client" || hiringDemandJson[hiringItem].Name == "Mandate") ? "pointer": "default"}} onClick={() => {if((hiringDemandJson[hiringItem].Name == "Client" || hiringDemandJson[hiringItem].Name == "Mandate"))handleClick({"count": hiringDemandJson[hiringItem][hiringDemandJson[hiringItem].Name == "Client" ? "clientCount" : hiringDemandJson[hiringItem].Name == "Mandate" ? "mandateCount" : "jobOrderCount"],"percentage": 0,"status": ""},hiringDemandJson[hiringItem][hiringDemandJson[hiringItem].Name == "Client" ? "clientCount" : hiringDemandJson[hiringItem].Name == "Mandate" ? "mandateCount" : "jobOrderCount"],hiringDemandJson[hiringItem].Name,"","leftCard")}}>
                                                {hiringDemandJson[hiringItem].Name == "Client" ? <HiringClients/> : hiringDemandJson[hiringItem].Name == "Mandate" ? <HiringMandate />: <HiringJobOrders />}
                                                <Box className="firstCardSectContent">
                                                  <Box className="leftCardArrowSect"><h4 style={{fontWeight:"bold"}}>{hiringDemandJson[hiringItem][hiringDemandJson[hiringItem].Name == "Client" ? "clientCount" : hiringDemandJson[hiringItem].Name == "Mandate" ? "mandateCount" : "jobOrderCount"]}</h4>{(hiringDemandJson[hiringItem].Name == "Client" || hiringDemandJson[hiringItem].Name == "Mandate") && <ArrowForwardIosIcon />}</Box>
                                                  <p>{hiringDemandJson[hiringItem].Name}</p>
                                                </Box>
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                </li>
                                {Object.keys(hiringDemandJson[hiringItem].list).map((hiringListItem:any, index:number) => {
                                    const listItem = hiringDemandJson[hiringItem].list[hiringListItem];
                                    const hasChildInfo = listItem.childInfo && Object.keys(listItem.childInfo).length > 0;
                                    const validChildItems = hasChildInfo && Object.values(listItem.childInfo).some((childItem: any) => childItem.count);
                                    return (
                                        <>
                                            <li key={index} className={`hiringBody${index + 1} ${(hiringItem == "JobOrders" && (index == 1 || index == 2)) ? "disableCard" : ""}`} onClick={() => {setSelectedCard({
                                                 columnName: hiringDemandJson[hiringItem].Name,
                                                 selectedIndex: (selectedCard.selectedIndex == index.toString() && selectedCard.columnName == hiringDemandJson[hiringItem].Name) ? "" : index.toString()
                                            })}} style={{marginBottom:'10px'}}>
                                                <Box className={(selectedCard.columnName == hiringDemandJson[hiringItem].Name && parseInt(selectedCard.selectedIndex) == index) ? "parentCardSelected" : ""}>
                                                    <Box className="parentTree hidehover" >
                                                        <h5>{hiringDemandJson[hiringItem].list[hiringListItem].count}</h5>
                                                        {hiringPercentageStatus && <p className={hiringDemandJson[hiringItem].list[hiringListItem].status == "inc" ? "incrementClass": hiringDemandJson[hiringItem].list[hiringListItem].status == "equal" ? "equalentClass" : "decrementClass"}>{hiringDemandJson[hiringItem].list[hiringListItem].percentage} % {hiringDemandJson[hiringItem].list[hiringListItem].status == "inc" ? <NorthIcon /> : hiringDemandJson[hiringItem].list[hiringListItem].status == "equal" ? <span style={{fontSize:'20px'}}>=</span> : <SouthIcon />}</p>}
                                                        <LaunchIcon className='launchIcon' onClick={() => handleClick(hiringDemandJson[hiringItem].list[hiringListItem], hiringDemandJson[hiringItem].list[hiringListItem].count, hiringDemandJson[hiringItem].Name,hiringListItem,"rightCard")}/>
                                                        {("childInfo" in hiringDemandJson[hiringItem].list[hiringListItem] && !(selectedCard.columnName == hiringDemandJson[hiringItem].Name && parseInt(selectedCard.selectedIndex) == index)) && <ExpandCircleDownIcon style={{position:"absolute",bottom:"-11px",top:"auto",left:"38px",backgroundColor:"#fff",color:'#002882',borderRadius:"30px"}}/>}
                                                    </Box>
                                                    {/* {validChildItems && ( */}
                                                        <Box>
                                                        {("childInfo" in hiringDemandJson[hiringItem].list[hiringListItem] && (selectedCard.columnName == hiringDemandJson[hiringItem].Name && parseInt(selectedCard.selectedIndex) == index)) && <Box className="childTree">
                                                            <Box>
                                                            <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
                                                            <ExpandCircleDownIcon style={{transform:"rotate(180deg)",marginTop:"-12px",background:"#fff",color:"#002882",borderRadius:"30px",marginLeft:"-3px"}}/>
                                                            </Box>
                                                            <Box className="childTreeBlk">
                                                            <ul>    
                                                                {Object.keys(listItem?.childInfo).map((childInfoList: any, childInfoIndex: any) => {
                                                                            const childItem = listItem.childInfo[childInfoList];
                                                                            return (
                                                                                <li key={childInfoIndex} className={`${(hiringItem == "JobOrders" && index == 5 && childItem?.name == "Archived") ? "disbaleArchived" :""}`}>
                                                                                    <Box>
                                                                                        <Box className="parentTree">
                                                                                            <p>{childItem?.name}</p>
                                                                                            <span>({childItem?.count}) </span>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </li>
                                                                            )
                                                                        })}
                                                            </ul>
                                                            </Box>
                                                            </Box>
                                                        </Box>}
                                                        </Box>
                                                    {/* )} */}
                                                    
                                                </Box>
                                            </li>
                                        </>
                                    )
                                })} 
                            </ul>                            
                        </li>
                    )
                })}
                </ul>
            </Box>
        </div>
    );
};

export default HiringDemandCard;