import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ApplicationUsageCustomTooltips from '../../../Common/CustomTooltipComponent/ApplicationUsageCustomTooltips';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./ApplicationUsageTrendWidget.scss"
import DateRangePickerWithCond from '../../../Common/CustomeDaterangepricker/DateRangePickerWithCond';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
const monthlyQ:any = {
  "January" : "Q4",
  "February": "Q4",
  "March": "Q4",
  "April": "Q1",
  "May":"Q1",
  "June": "Q1",
  "July": "Q2",
  "August": "Q2",
  "September": "Q2",
  "October": "Q3",
  "November": "Q3",
  "December": "Q3"
}
const monthlyQHlistCheck:any = {
  "Q1": ["April","May","June"],
  "Q2": ["July","August","September"],
  "Q3": ["October","November","December"],
  "Q4": ["January","February","March"]
}
const monthlyQHlist:any = {
  "Q1": ["April","May","June"],
  "Q2": ["July","August","September"],
  "Q3": ["October","November","December"],
  "Q4": ["January","February","March"],
  "H1 (Apr - Sep)": ["April","September"],
  "H2 (Oct - Mar)": ["October","March"],
  "YTD": ["April","March"]
}
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const ApplicationUsageTrendRoleWise = ({appTrendsData,appTrendsRoleList,appTrendsRoleWiseDataCount,appTrendsModeChange,reportSubmissionAPIData,redirectChange,setAppTrendsFilterObj,appTrendsFilterObj,appTrendsRedirectObj,setAppTrendsRoleSelectedData,setSwitchToggleBtnState,switchToggleBtnState}:any) => {
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
    const currentYearConst = useState(moment().format('YYYY'));
    const currentMonthConst:any = useState(moment().format('MMMM'));
    const months = ["April","May","June","July","August","September","October","November","December","January","February","March"];  
    const monthlyOptions = ["Q1","Q2","Q3","Q4","H1 (Apr - Sep)","H2 (Oct - Mar)","YTD"]  
    const [chartOptions,setChartOptions] = useState([
        { label: 'Daily', type: 'daily',active:true },
        { label: 'Weekly', type: 'weekly',active:false },
        { label: 'Monthly', type: 'monthly',active:false }
      ]);
    const appTrendsRedirectObjState = appTrendsRedirectObj["appTrendsRole"]
    const [selectedDate, setSelectedDate] = useState<any>((appTrendsRedirectObjState != undefined && Object.keys(appTrendsRedirectObjState).length > 0) ? appTrendsRedirectObjState.fieldSelecteDate : [moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')])
    const [selectedDateType, setSelectedDateType] = useState("daily")
    const [selectedweekmonth, setSelectedweekmonth] = useState<any | string | null>(appTrendsFilterObj.value);
    const [selectedMonthly, setSelectedMonthly] = useState<any | string | null>(appTrendsFilterObj != undefined ? appTrendsFilterObj.value : null);
    const [monthlyOptionDates,setMonthlyOptionDates] = useState<any>({})
    const [toogleSwitch, setToogleSwitch] = useState(switchToggleBtnState == "loggedIn" ? false : true)
    const barColorList = ['#988BE6','#F3AB5C','#EA73C7','#69D3E6','#FC6481','#C083E8','#E9D36E','#81E8AD','#E79B85','#67B4EC']
    useEffect(() => {
      setAppTrendsRoleSelectedData(selectedDate)
    },[selectedDate])
    useEffect(() => {
      const currentYear = reportSubmissionAPIData?.dateYear != undefined ? reportSubmissionAPIData?.dateYear : moment().format('YYYY');
      setCurrentYear(currentYear)
      const currentMonth = moment().format('MMMM')
      const Q4List = ["January","February","March"]
      const generateMonthlyList:any = {}
      generateMonthlyList["Q1"] = [moment(`${monthlyQHlist["Q1"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["Q1"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["Q2"] = [moment(`${monthlyQHlist["Q2"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["Q2"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["Q3"] = [moment(`${monthlyQHlist["Q3"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["Q3"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["Q4"] = [moment(`${monthlyQHlist["Q4"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) + 1 : parseInt(currentYear)}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["Q4"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) + 1 : parseInt(currentYear)}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["H1 (Apr - Sep)"] = [moment(`${monthlyQHlist["H1 (Apr - Sep)"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["H1 (Apr - Sep)"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["H2 (Oct - Mar)"] = [moment(`${monthlyQHlist["H2 (Oct - Mar)"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["H2 (Oct - Mar)"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) + 1 : parseInt(currentYear)}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      generateMonthlyList["YTD"] = [moment(`${monthlyQHlist["YTD"][0]} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) : parseInt(currentYear) - 1}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'),moment(`${monthlyQHlist["YTD"].at(-1)} ${!Q4List.includes(currentMonth) ? parseInt(currentYear) + 1 : parseInt(currentYear)}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')]
      setMonthlyOptionDates(generateMonthlyList)      
      const retriveType = appTrendsFilterObj != undefined ? appTrendsFilterObj.type : "daily"
      setSelectedDateType(retriveType)
      setChartOptions(prevOptions =>
        prevOptions.map(option =>
          option.type === retriveType
            ? { ...option, active: true }
            : { ...option, active: false }
        ))
        if(appTrendsFilterObj.type == "daily") setSelectedDate(appTrendsFilterObj.value)        
    },[reportSubmissionAPIData.dateYear,appTrendsFilterObj])

    const appTrendsBtnHandler = (type:string) => {
      setSelectedDateType(type)
      let selectedCurrentMonth = ""
      setChartOptions(prevOptions =>
        prevOptions.map(option =>
          option.type === type
            ? { ...option, active: true }
            : { ...option, active: false }
        ))
        let dateSelection:any = []
        if(type == "daily"){
          dateSelection = currentYear == moment().format('YYYY') ? [moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')] : [moment(`${"April"} ${currentYear}`, "MMMM YYYY").format('YYYY-MM-DD'), moment(`${"April"} ${currentYear}`, "MMMM YYYY").add(6, 'days').format('YYYY-MM-DD')]
        }else if(type == "weekly"){
          const { monthStart, monthEnd }:any = getMonthRange(moment().format('MMMM'), currentYear);
          dateSelection = [monthStart,monthEnd]
          setSelectedweekmonth(moment().format('MMMM'))
        }else{ 
          let selectedMonth = ""
          Object.keys(monthlyQHlistCheck).map((item) => {
            if(monthlyQHlistCheck[item].includes(moment().format('MMMM')))selectedMonth = item
          })
          const Q4List = ["January","February","March"]
          selectedCurrentMonth = selectedMonth
          const selectedMonthRange = monthlyQHlist[selectedMonth]          
          const endDate = moment(`${selectedMonthRange[selectedMonthRange.length - 1]} ${Q4List.includes(currentMonthConst) ? parseInt(currentYear) +1  : currentYear}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD');
          const startDate = moment(`${selectedMonthRange[0]} ${Q4List.includes(currentMonthConst) ? parseInt(currentYear) +1  : currentYear}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD');                   
          dateSelection = [startDate,endDate]
          setSelectedMonthly(selectedMonth)
        }
      appTrendsModeChange(type,dateSelection,toogleSwitch ? "notLoggedIn": "loggedIn",selectedCurrentMonth);
      setSelectedDate(dateSelection)
      setAppTrendsFilterObj({...appTrendsFilterObj,type:type,value: type == "daily" ? dateSelection : type == "weekly" ? moment().format('MMMM') : selectedCurrentMonth})
    }

    const appTrendsDateHandler = (date:any,selectedVal:any) => {
      let DateStartEnd:any = [];
      if(selectedDateType == "daily"){
        DateStartEnd = date
      }
      else{
        DateStartEnd = [date[0],date[1]]        
      }
      setAppTrendsFilterObj({...appTrendsFilterObj,type:"daily",value: DateStartEnd})
      setSelectedDate(DateStartEnd)
      appTrendsModeChange(selectedDateType,DateStartEnd,toogleSwitch ? "notLoggedIn": "loggedIn", selectedDateType == "monthly" ? selectedVal : "");      
    }
    const loggedToggleSwitch = (switchEnable:any) => {
      setToogleSwitch(switchEnable)
      setSwitchToggleBtnState(switchEnable ? "notLoggedIn": "loggedIn")
      setAppTrendsFilterObj({...appTrendsFilterObj,loggedType:switchEnable ? "notLoggedIn": "loggedIn"})
      appTrendsModeChange(selectedDateType,selectedDate,switchEnable ? "notLoggedIn": "loggedIn",selectedDateType == "monthly" ? selectedMonthly : "");      
    }
    const renderEmptySearchBox = () => {;
      return (
          <Box className="empty-data-card" style={{top: "200px"}}>
              <Box>
                  <NoResultFound />
              </Box>
              <Box className="empty-search-message">
                  No data found
              </Box>
          </Box>
      )
  }
  
  function getMonthRange(monthName:string, year:any) {
    const monthStart = moment(`${monthName} ${year}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD');
    const monthEnd = moment(`${monthName} ${year}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD');
    
    return { monthStart, monthEnd };
}

  const dateSelectionHandler = (date:any) => {
    const currentMonth = moment().format('MMMM')
    const Q4List = ["January","February","March"]
    if(selectedDateType == "weekly"){      
      const { monthStart, monthEnd }:any = getMonthRange(date, (Q4List.includes(date) && !Q4List.includes(currentMonth)) ? parseInt(currentYear) +1  : (Q4List.includes(date) && Q4List.includes(currentMonth)) ? currentYear : parseInt(currentYear) - 1);
      appTrendsDateHandler([monthStart,monthEnd],"")
      setAppTrendsFilterObj({...appTrendsFilterObj,type:selectedDateType,value:date})
    }else{
      appTrendsDateHandler(monthlyOptionDates[date],date)
      setAppTrendsFilterObj({...appTrendsFilterObj,type:selectedDateType,value:date})
    }
    
  }
    
  const [chartType, setChartType] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const [dateRangeType,setDateRangeType] = useState("")
  const calculateMaxValue = (data:any) => {    
    if(data.length > 0){
      const maxMonth = data.reduce((max:any, month:any) => 
      parseInt(month.totalUsers) > parseInt(max.totalUsers) ? month : max
    );
      return parseInt(maxMonth.totalUsers) + ((parseInt(maxMonth.totalUsers) / 100) * 15);
  }else{
    return 0
  }
  };


  const legentsoptions = appTrendsRoleList.map((item:any,index:number) => {
    return{
      legendkey:item,color:barColorList[index]
    }
  })


  const CustomTooltip = ({ id, value, indexValue, data }: any) => {
    return(
      <ApplicationUsageCustomTooltips data={data} id={id} type={"appTrendsRole"} colorCode={barColorList[appTrendsRoleList.indexOf(id)]}/>
    )
  }

  const appTrendRedirectHandler = (data:any) => {
    redirectChange(data,selectedDate)
  }
  return (
    <Box sx={{ width: '100%', height: 500, padding:'5px'}}>
        <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'space-between',alignItems:'baseline',marginTop:'-20px'}}>
            <Box><Typography variant="h4" align="center" sx={{fontSize:'16px',fontWeight:600}}>App Usage Trend - Role-wise</Typography></Box>
            <Box className="appTrendsRoleOptSect">
              <Box sx={{ display: 'flex', flexWrap:'wrap', justifyContent: 'space-between', padding:'0px 10px',alignItems:'center',}}>                
                  <Box className="switchToggleSect">
                      <p>{toogleSwitch ? "Not Logged In Users" : "Logged In Users"}</p><AntSwitch onClick={(e:any) =>{loggedToggleSwitch(e.target.checked)}} defaultChecked={toogleSwitch} inputProps={{ 'aria-label': 'ant design' }} />
                  </Box>
                  <Box sx={{paddingTop:'15px'}}>
                      <ButtonGroup sx={{ display: 'flex', justifyContent: 'center', mb: 2 }} className='appTrendsBtnMode'>
                          {chartOptions.map((option:any) => (
                          <Button
                              key={option?.type}
                              size="small"
                              onClick={() => appTrendsBtnHandler(option?.type)}
                              sx={{
                              fontSize:'14px'                            
                              }}
                              className={option?.active ? "active": ""}
                          >
                              {option.label}
                          </Button>
                          ))}
                      </ButtonGroup>
                  </Box>
                  {
                      selectedDateType === 'daily' && 
                      <Box sx={{paddingX:'10px'}}>
                        <Box className="DateRangeField appTrendSect">
                          <DateRangePickerWithCond onDateChange={appTrendsDateHandler} value={appTrendsFilterObj.type == "daily" ? appTrendsFilterObj.value : ''} currentYear={currentYear} type="appTrendsRole"/>
                      </Box>
                      </Box>}
                      {selectedDateType === 'weekly' && <Box sx={{padding:'10px'}}>
                      <FormControl className='appTrendsSelectField'>
                      <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectedweekmonth}
                          // label="Age"
                          placeholder='None'
                          defaultValue={'None'}
                          onChange={(event:any) => {dateSelectionHandler(event.target.value);setSelectedweekmonth(event.target.value);}}
                      >
                          {months.map((month, index) => {
                              return(<MenuItem key={index} value={month} sx={{fontSize:'12px'}}>{month}</MenuItem>)
                          })}
                      </Select>
                      </FormControl>
                      </Box>
                      }
                      {selectedDateType === 'monthly' && <Box sx={{padding:'10px'}}>
                      <FormControl className='appTrendsSelectField'>
                      <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={selectedMonthly}
                          placeholder='None'
                          defaultValue={'None'}
                          onChange={(event:any) => {dateSelectionHandler(event.target.value); setSelectedMonthly(event.target.value);}}
                      >
                          {monthlyOptions.map((month, index) => {
                              return(<MenuItem key={index} value={month} sx={{fontSize:'12px'}}>{month}</MenuItem>)
                          })}
                      </Select>
                      </FormControl>
                      </Box>
                      }
                  
                  {/* <Box sx={{paddingTop:'5px'}}><MoreVertIcon style={{ fontSize: '18px', fontWeight: 'bold' }} /></Box> */}
            </Box>
            <Box sx={{ display: 'flex', flexWrap:'wrap', justifyContent: 'end', padding:'0px 10px',alignItems:'center'}}>
                    {legentsoptions?.map((item:any,index:number)=>{
                        return(
                            <Box key={index} sx={{ display: 'flex', gap:'5px', flexWrap:'wrap', justifyContent: 'end', padding:'10px',alignItems:'center'}}>
                                <Box sx={{height:'10px',width:'10px',background:item?.color,borderRadius:"2px"}}></Box>
                                <Box sx={{fontSize:'14px',color:"#727376"}}>{item?.legendkey}</Box>
                            </Box>
                        )
                    })}
                </Box>
         </Box>
      </Box>
      

      
      {appTrendsData.length && appTrendsRoleWiseDataCount != 0 ? <Box className="appTrendsBarChart roleWiseHeight"><ResponsiveBar
        data={appTrendsData}
        keys={appTrendsRoleList}
        indexBy={"label"}
        margin={{ top: 0, right: 30, bottom: 60, left: 60 }}
        padding={0.70}
        groupMode="stacked"
        colors={barColorList}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // format: (d: any) => formatDatemonth(d, chartType),
          legend: chartType === 'daily' ? 'Activity Period' : chartType === 'weekly' ? 'Weeks' : 'Months',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: '# of Users',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        tooltip={(bar) => <CustomTooltip {...bar} />}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={'#fff'}
        animate={true}
        enableTotals={true}
        maxValue={calculateMaxValue(appTrendsData)} 
        onMouseEnter={(_datum, event:any) => {
          event.currentTarget.style.cursor = "pointer";
        }}
        onClick={(data) => {appTrendRedirectHandler(data)}}
      /></Box>: <>
      <Box style={{position:"relative"}}>
        {renderEmptySearchBox()}
      </Box>
        
      </>}
    </Box>
  );
};

export default ApplicationUsageTrendRoleWise;
