import React, { useEffect, useRef, useState } from 'react';
import DashboardFilterIndex from '../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import "../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail.scss"
import { IMaskInput } from 'react-imask';
import { ResponsiveBar } from '@nivo/bar'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { PRIMARY1 } from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooks';
import { getApptrendsData, getApptrendsRoleWiseData, jobsAgeingReportList } from '../../../services/DashboardService';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationUsageTrendWidget from './ApplicationUsageTrendWidget/ApplicationUsageTrendWidget';
import moment from 'moment';
import ApplicationUsageTrendRoleWise from './ApplicationUsageTrendWidget/ApplicationUsageTrendRoleWise';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }


const ApplicationUsage = () => {
    const location = useLocation();
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state !=  null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "filterListLabels" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.filterListLabels : {} : {}) : {})
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [appTrendsRedirectObj,setAppTrendsRedirectObj] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "appTrendsDetails" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.appTrendsDetails : {} : {}) : {})
    const [appTrendsRoleWiseRedirectObj,setAppTrendsRoleWiseRedirectObj] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "appTrendsDetails" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.appTrendsDetails : {} : {}) : {})
    const userId = useAppSelector((state: any) => state.UserDetail.userId);
    const navigate = useNavigate();
    const [appTrendsData, setAppTrendsData] = useState<any>([])
    const [appTrendsRoleWiseData, setAppTrendsRoleWiseData] = useState<any>([])
    const [appTrendsRoleWiseDataCount, setAppTrendsRoleWiseDataCount] = useState<any>(0)
    const [appTrendsRoleList, setAppTrendsRoleList] = useState<any>([])
    const [appTrendsSelectedData, setAppTrendsSelectedData] = useState<any>([moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')])
    const [appTrendsRoleSelectedData, setAppTrendsRoleSelectedData] = useState<any>([moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')])
    const months = Array.from({ length: 12 }, (_, i) => moment().month(i).format('MMMM'));
    const [currentBtnType, setCurrentBtnType] = useState("")
    const [currentRoleBtnType, setCurrentRoleBtnType] = useState("")
    const [roundCount,setRoundCount] = useState(0)
    const [appTrendsFilterObj, setAppTrendsFilterObj] = useState({type:"",value: ""})
    const [appTrendsRoleWiseFilterObj, setAppTrendsRoleWiseFilterObj] = useState({type:"",value: "",loggedType:""})
    const [switchToggleBtnState,setSwitchToggleBtnState] = useState("loggedIn")
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [userDependendObj,setUserDependendObj] = useState({}) 
    const roleSectionRef = useRef<any>(null);
    useEffect(() => {
        console.log("appTrendsFilterObj",appTrendsFilterObj)
    },[appTrendsFilterObj])
    const appTrendsRedirectHandler = (data:any,selectedDate:any) => {
        const appTrendObj = {
            appTrends: {
            "loginType": data.id,  
            "customStartDate": moment(data.data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            "customEndDate":moment(data.data.startEnd, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            "type": currentBtnType,
            "selectedValue": appTrendsFilterObj,
            "fieldSelecteDate": appTrendsSelectedData
            },
            appTrendsRole:{
                "type": currentRoleBtnType,
                "selectedValue": appTrendsRoleWiseFilterObj,
                "fieldSelecteDate": appTrendsRoleSelectedData,
                "loginType" : switchToggleBtnState
            }
        }  
        navigate(`/applicationUsageDetail`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels, appTrendsDetails: appTrendObj,type:"appTrends",userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj, count: data.value} });
    }
    const appTrendsRoleRedirectHandler = (data:any,selectedDate:any) => {
        const roleName= data.id+"Id";
        const appTrendObj = {
            appTrends: {
            "type": currentBtnType,
            "selectedValue": appTrendsFilterObj,
            "fieldSelecteDate": appTrendsSelectedData
            },
            appTrendsRole:{
                "loginType": switchToggleBtnState,  
                "customStartDate": currentRoleBtnType == "daily" ? moment(data.data.startDate, 'MMM-DD-YYYY').format('YYYY-MM-DD') : moment(data.data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                "customEndDate": currentRoleBtnType == "daily" ? moment(data.data.startEnd, 'MMM-DD-YYYY').format('YYYY-MM-DD'): moment(data.data.startEnd, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                "type": currentRoleBtnType,
                "selectedValue": appTrendsRoleWiseFilterObj,
                "fieldSelecteDate": selectedDate,
                "roleId" : data.data[roleName],
            }
        }
        navigate(`/applicationUsageRoleWiseDetail`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels, appTrendsDetails: appTrendObj,type:"appTrendsRole",userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj,count: data.value} });
    }
    const appTrendsListAPi = async (trendsType:string,date:any,selectedVal:string) => {
        setCurrentBtnType(trendsType)
        try {
            const res: any = await getApptrendsData({
                "userID": reportSubmissionAPIData["userId"] != undefined ? reportSubmissionAPIData["userId"] : [userId], 
                "activityPeriod": trendsType, 
                "activityPeriodRange": trendsType == "monthly" ? selectedVal : trendsType, 
                "customStartDate": date[0],
                "customEndDate": date[1],
                "coe":reportSubmissionAPIData?.coe,
                "businessUnit":reportSubmissionAPIData?.businessUnit
            });
            const listData = res.data.map((item:any,index:number) => {
                let appTendsData = {}
                if(trendsType == "daily"){
                    appTendsData = {
                        id:index + 1, 
                        label: moment(item.login_date, 'YYYY-MM-DD').format('MMM-DD'), 
                        loggedIn: item.users_logged_in, 
                        notLoggedIn: item.users_not_logged_in, 
                        totalUsers: item.total_users,
                        type: "daily",
                        startDate: item.login_date,
                        startEnd: item.login_date
                    }
                }else if(trendsType == "weekly") {
                    appTendsData = { 
                        id:index + 1, 
                        label: `week ${index + 1}`, 
                        loggedIn: item.users_logged_in, 
                        notLoggedIn: item.users_not_logged_in, 
                        totalUsers: item.total_users,
                        type: "weekly",
                        startDate: item.week_start,
                        startEnd: item.week_end
                    }
                }else{
                    appTendsData = { 
                        id:index + 1, 
                        label: moment(item.month_end, 'YYYY-MM-DD').format('MMMM'), 
                        loggedIn: item.users_logged_in, 
                        notLoggedIn: item.users_not_logged_in, 
                        totalUsers: item.total_users,
                        type: "monthly",
                        startDate: item.month_start,
                        startEnd: item.month_end
                    }
                }
                return appTendsData
            })
            setAppTrendsData(listData)
        } catch (error: any) {
            console.log(error);
        }
    }
    const appTrendsRoleListAPi = async (trendsType:string,date:any,loggedType:any,selectedVal:string) => {
        setCurrentRoleBtnType(trendsType)
        try {
            const res: any = await getApptrendsRoleWiseData({
                "userID": reportSubmissionAPIData["userId"] != undefined ? reportSubmissionAPIData["userId"] : [userId], 
                "activityPeriod": trendsType, 
                "activityPeriodRange": trendsType == "monthly" ? selectedVal : trendsType, 
                "customStartDate": date[0],
                "customEndDate": date[1],
                "coe":reportSubmissionAPIData?.coe,
                "businessUnit":reportSubmissionAPIData?.businessUnit,
                "loginType":loggedType
            });
            const groupByLoginDate = (arr: any[], searchKey:any) => {
                return arr.reduce((acc: Record<string, any[]>, curr: any) => {
                  const date = curr[searchKey];
                  if (!acc[date]) {
                    acc[date] = [];
                  }
                  acc[date].push(curr);
                  return acc;
                }, {});
              };
            const sortedData:any = groupByLoginDate(res.data,trendsType == "daily" ? "login_date": trendsType == "weekly" ? "week_start": "month_start");
            let totalUserCount = 0;
            const listData = Object.keys(sortedData).map((item:any,index:number) => {
                let appTendsData:any = {}
                console.log("trendsType",trendsType)
                if(trendsType == "daily"){
                    appTendsData = {
                        id:index + 1, 
                        label: moment(item, 'YYYY-MM-DD').format('MMM-DD'),
                        type: "daily",
                        startDate: moment(item, 'YYYY-MM-DD').format('MMM-DD-YYYY'),
                        startEnd: moment(item, 'YYYY-MM-DD').format('MMM-DD-YYYY'),
                        roleTypeFilter: loggedType
                    }
                    let totalUsers = 0;
                    const roleList:any = []
                    sortedData[item].map((itemList:any,index:number) => {
                        const roleName = itemList.role_name.replace(" ","");
                        if(!roleList.includes(roleName)) roleList.push(roleName)
                        appTendsData[roleName] = itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"];
                        appTendsData[roleName+"Id"] = itemList.role_id;
                        totalUsers = totalUsers + parseInt(itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"])
                    })
                    setAppTrendsRoleList(roleList)
                    appTendsData["totalUsers"] = totalUsers;
                    totalUserCount = totalUserCount + totalUsers
                }
                else if(trendsType == "weekly") {
                    console.log("weekly")
                    appTendsData = {
                        id:index + 1, 
                        label: "Week"+(index+1),
                        type: "weekly",
                        startDate: sortedData[item][0].week_start,
                        startEnd: sortedData[item][0].week_end,
                        roleTypeFilter: loggedType
                    }
                    let totalUsers = 0;
                    const roleList:any = []
                    sortedData[item].map((itemList:any,index:number) => {
                        const roleName = itemList.role_name.replace(" ","");
                        if(!roleList.includes(roleName)) roleList.push(roleName)
                        appTendsData[roleName] = itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"];
                        appTendsData[roleName+"Id"] = itemList.role_id;
                        totalUsers = totalUsers + parseInt(itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"])
                    })
                    setAppTrendsRoleList(roleList)
                    appTendsData["totalUsers"] = totalUsers;
                    totalUserCount = totalUserCount + totalUsers
                }
                else{
                    appTendsData = {
                        id:index + 1, 
                        label: moment(item, 'YYYY-MM-DD').format('MMMM'),
                        type: "monthly",
                        startDate: sortedData[item][0].month_start,
                        startEnd: sortedData[item][0].month_end,
                        roleTypeFilter: loggedType
                    }
                    let totalUsers = 0;
                    const roleList:any = []
                    sortedData[item].map((itemList:any,index:number) => {
                        const roleName = itemList.role_name.replace(" ","");
                        if(!roleList.includes(roleName)) roleList.push(roleName)
                        appTendsData[roleName] = itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"];
                        appTendsData[roleName+"Id"] = itemList.role_id;
                        totalUsers = totalUsers + parseInt(itemList[loggedType == "loggedIn" ? "users_logged_in" : "users_not_logged_in"])
                    })
                    setAppTrendsRoleList(roleList)
                    appTendsData["totalUsers"] = totalUsers;
                    totalUserCount = totalUserCount + totalUsers
                }
                return appTendsData
            })
            setAppTrendsRoleWiseData(listData)
            setAppTrendsRoleWiseDataCount(totalUserCount)
        } catch (error: any) {
            console.log(error);
        }
    }
    const roleHandleScroll = () => {
        roleSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    useEffect(() => {
        let startEndDate:any = [];
        if(reportSubmissionAPIData?.dateYear != undefined){
            startEndDate = reportSubmissionAPIData?.dateYear == moment().format('YYYY') ? [moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')] : [moment(`${"April"} ${reportSubmissionAPIData?.dateYear}`, "MMMM YYYY").format('YYYY-MM-DD'), moment(`${"April"} ${reportSubmissionAPIData?.dateYear}`, "MMMM YYYY").add(6, 'days').format('YYYY-MM-DD')]
        }else{
            startEndDate = [moment().subtract(6, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]
        }
        console.log("startEndDate",startEndDate)
        if(appTrendsRedirectObj != undefined && Object.keys(appTrendsRedirectObj).length > 0 && roundCount == 0){
            console.log("appTrendsRedirectObj",appTrendsRedirectObj)
            const appTrendsRedirectObjData = appTrendsRedirectObj.appTrends;
            const appTrendsRoleRedirectObjData = appTrendsRedirectObj.appTrendsRole;
            if(!("loginType" in appTrendsRedirectObjData))setTimeout(() => {roleHandleScroll()},500)
            appTrendsListAPi(appTrendsRedirectObjData.type,[appTrendsRedirectObjData.fieldSelecteDate[0],appTrendsRedirectObjData.fieldSelecteDate[1]],appTrendsRoleRedirectObjData.selectedValue.value)
            setAppTrendsFilterObj({type:appTrendsRedirectObjData.selectedValue.type,value: appTrendsRedirectObjData.selectedValue.value})
            setAppTrendsRoleWiseFilterObj({type:appTrendsRoleRedirectObjData.selectedValue.type,value: appTrendsRoleRedirectObjData.selectedValue.value,loggedType:appTrendsRoleRedirectObjData.loginType})
            appTrendsRoleListAPi(appTrendsRoleRedirectObjData.type,[appTrendsRoleRedirectObjData.fieldSelecteDate[0],appTrendsRoleRedirectObjData.fieldSelecteDate[1]],appTrendsRoleRedirectObjData.loginType,appTrendsRoleRedirectObjData.selectedValue.value)
            setSwitchToggleBtnState(appTrendsRoleRedirectObjData.loginType)
        }else{
            appTrendsListAPi("daily",startEndDate,"")
            setAppTrendsFilterObj({type:"daily",value: startEndDate})
            setAppTrendsRoleWiseFilterObj({type:"daily",value: startEndDate,loggedType:"loggedIn"})
            appTrendsRoleListAPi("daily",startEndDate,"loggedIn","")            
        }   
        setRoundCount(1)
        
    },[reportSubmissionAPIData])

    const [headerTitle,setHeaderTitle] = useState(`<Box className='tasklist mar0'>
        <Typography display='flex' gap={1} className='tasklist-title'>
        <Box style="font-weight: 600; font-size: 16px">Application Usage</Box>
        </Typography>
    </Box>`)
    return (
        <>
            <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","Mandate SPOC","Job Title","JO Location","Primary Skill","Mandate Type","industryData","Job Mode","Role","Start Date","Client"]} headerTitle={headerTitle} datePickerType={"date"} dateYearEnable={true} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} filterType={"mainScreen"}/>
            <Box className="reportTableContSect" style={{paddingTop:'10px',paddingBottom:"60px"}}>
                {appTrendsFilterObj.type != "" &&<ApplicationUsageTrendWidget appTrendsData={appTrendsData} appTrendsModeChange={(e:any,date:any,selectedVal:string) => {appTrendsListAPi(e,date,selectedVal)}} reportSubmissionAPIData={reportSubmissionAPIData} redirectChange={(data:any,selectedDate:any) => {appTrendsRedirectHandler(data,selectedDate)}} setAppTrendsFilterObj={setAppTrendsFilterObj} appTrendsFilterObj={appTrendsFilterObj} appTrendsRedirectObj={appTrendsRedirectObj} setAppTrendsSelectedData={setAppTrendsSelectedData}/>}
            </Box>
            <Box ref={roleSectionRef} className="reportTableContSect" style={{paddingTop:'10px',paddingBottom:"60px"}}>
                {appTrendsRoleWiseFilterObj.type != "" &&<ApplicationUsageTrendRoleWise appTrendsData={appTrendsRoleWiseData} appTrendsRoleList={appTrendsRoleList} appTrendsRoleWiseDataCount={appTrendsRoleWiseDataCount} appTrendsModeChange={(e:any,date:any,loggedType:string,selectedVal:string) => {appTrendsRoleListAPi(e,date,loggedType,selectedVal)}} reportSubmissionAPIData={reportSubmissionAPIData} redirectChange={(data:any,selectedDate:any) => {appTrendsRoleRedirectHandler(data,selectedDate)}} setAppTrendsFilterObj={setAppTrendsRoleWiseFilterObj} appTrendsFilterObj={appTrendsRoleWiseFilterObj} appTrendsRedirectObj={appTrendsRoleWiseRedirectObj} setAppTrendsRoleSelectedData={setAppTrendsRoleSelectedData} setSwitchToggleBtnState={setSwitchToggleBtnState} switchToggleBtnState={switchToggleBtnState}/>}
            </Box>
        </>
    );
};

export default ApplicationUsage;