import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, InputLabel, Select, MenuItem, ListItemText  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { AppButton } from '../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../utils/constants'
import { ReportSearch } from '../Search/Search'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as SortIcon } from '../../../assets/icons/sort-arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { ReactComponent as UserRoleImgPlus } from '../../../assets/icons/plus.svg'
import { ReactComponent as UserRoleCancel } from '../../../assets/icons/userRoleClose.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs';
import { AppAutoComplete } from '../../AppComponents/AppAutoComplete'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { ReactComponent as RotateIcon } from '../../../../src/assets/icons/reset.svg'
import Popover from '@mui/material/Popover';
import { MandateService, getDepartmentRoles, getMandateLocation } from '../../../services/MandateService'
import _ from 'lodash'
import { ReportkManagementService } from '../../../services/ReportListService'
import DepartmentDropDown from '../../Mandates/AddMandate/DepartmentDropDown'
import { useAppDispatch,useAppSelector } from "../../../store/hooks";
import { convertToDisplayDate } from '../../../utils/date-utils'
import moment from 'moment'
import IndustryDropdown from '../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import { TaskManagementService } from '../../../services/TaskService'
import { reportFilterLoadData } from '../../../features/filters/FilterReducer'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
  },
};



const FilterComponent = ({filteredColumns,reportData,reportClientAPIList,setReportSubmissionAPIData,reportSubmissionAPIData, referAPIObjKeys, mLocationList, setMLocationList, reportFilterPreload, setResetFilter,reportBussinessUnitAPIList,userRoleListObject,setUserRoleListObject,userRoleChange,setSelectedFilterObjectValue,userTrigger}:any) => {
  const [filterChecked, setfilterChecked] = useState<boolean>(false)
  const [columnFilter, setColumnFilter] = useState<boolean>(false)
  const [columnFilterSearch, setColumnFilterSearch] = useState<string>('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [keyWordSearch, setKeyWordSearch] = useState("")
  let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
  const dispatch = useAppDispatch()
  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
  }
  const userDetailBuId = useAppSelector((state) => state.UserDetail.businessUnitId)
  const { userId } = useAppSelector((state) => state?.UserDetail)
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false)  
  const [selectColumnsState, setSelectColumnsState] = useState<any>({
    "Job Title": true,
    "Client": false,
    "Current Organization": false,
    "Total exp": true,
    "Relevant Exp": false,
    "Current Location": true,
    "Preferred Location": false,
    "Notice Period": true,
    "Contact Number": false,
    "e-Mail": false,
    "Current CTC": true,
    "Expected CTC": false,
    "Source": false,
    "CV Send Date": true,
    "Stage": true,
    "Status": true,
    "Recruiter Name": true,
    "Profile entry date in the Artemis": false,
    "Profile last modified date": false,
    "Profile last active date": false
})
  const [userIdSelected, setUserIdSelected] = useState<any>(userId)  
  const [columnFields, setColumnFields] = useState<any>([
    {label: 'Job Title', id: 1, checkStatus: true},
    {label: 'Client', id: 2, checkStatus: false},
    {label: 'Current Organization', id: 3, checkStatus: false},
    {label: 'Total exp', id: 4, checkStatus: true},
    {label: 'Relevant Exp', id: 5, checkStatus: false},
    {label: 'Current Location', id: 6, checkStatus: true},
    {label: 'Preferred Location', id: 7, checkStatus: false},
    {label: 'Notice Period', id: 8, checkStatus: true},    
    {label: 'Contact Number', id: 9, checkStatus: false},
    {label: 'e-Mail', id: 10, checkStatus: false},
    {label: 'Current CTC', id: 11, checkStatus: true},
    {label: 'Expected CTC', id: 12, checkStatus: false},
    {label: 'Source', id: 13, checkStatus: false},
    {label: 'CV Send Date', id: 15, checkStatus: true},    
    {label: 'Stage', id: 16, checkStatus: true},
    {label: 'Status', id: 17, checkStatus: true},
    {label: 'Recruiter Name', id: 18, checkStatus: true},
    {label: 'Profile entry date in the Artemis', id: 19, checkStatus: false},
    {label: 'Profile last modified date', id: 20, checkStatus: false},
    {label: 'Profile last active date',id: 21, checkStatus: false}
  ])

  const openCoumnFilter = (event:any) => {
    setAnchorEl(event.currentTarget)
    setColumnFilter(true)
    let selectAllFilter = true;
    columnFields.map((columns:any) => {
      !columns.checkStatus ? selectAllFilter = false : "";
    })
    setSelectAllColumnsState(selectAllFilter)
  }

  const [preloadDataObj , setPreloadDataObj] = useState<any>()
  const [submissionStartDate, setSubmissionStartDate] = React.useState<Dayjs | null>(null);
  const [submissionEndDate, setSubmissionEndDate] = React.useState<Dayjs | null>(null);
  useEffect(() => {
    const reportFilterPreloadData = JSON.parse(JSON.stringify(reportFilterPreload))
    delete reportFilterPreloadData['reportList']; 
    delete reportFilterPreloadData['defaultPageCount']; 
    delete reportFilterPreloadData['pageCount']; 
    delete reportFilterPreloadData['pageSize']; 
    setTimeout(() => {
      if(Object.keys(reportFilterPreloadData).length > 0){
        const preloadDataObj = {
          client: reportFilterPreloadData?.client,
          mandate: reportFilterPreloadData?.mandate,
          mandateLocation: reportFilterPreloadData?.mandateLocation,
          submissionDate: reportFilterPreloadData?.submissionDate ? dayjs(moment(new Date(reportFilterPreloadData?.submissionDate)).format('YYYY/MM/DD')): null,
          submissionEndDate: reportFilterPreloadData?.submissionEndDate ? dayjs(moment(new Date(reportFilterPreloadData?.submissionEndDate)).format('YYYY/MM/DD')) : null,
        }
        setPreloadDataObj(preloadDataObj)  
        setValue('bussinessUnit', reportFilterPreload?.selectedFilterObjectValue.bussinessUnit)
        setValue('client', reportFilterPreload?.selectedFilterObjectValue?.client)
        setValue('Mandate', reportFilterPreload?.selectedFilterObjectValue?.Mandate)
        setValue('mandateLocation', reportFilterPreload?.selectedFilterObjectValue?.mandateLocation)
        setValue('submissionDate', reportFilterPreload?.selectedFilterObjectValue?.submissionDate ?? null)
        setValue('submissionEndDate',reportFilterPreload?.selectedFilterObjectValue?.submissionEndDate ?? null)
        if(reportFilterPreload?.selectedFilterObjectValue?.Mandate)getMandateLocationPreloadList(reportFilterPreload?.selectedFilterObjectValue?.Mandate)
        setUserRoleListObject(reportFilterPreloadData.userRoleFilter)
      }
    },500)
  },[reportFilterPreload])

  const { handleSubmit, control, reset, watch, getValues,setValue } = useForm({})

  const hitSave = (data:any,type:string) => {
    filteredColumns(data)
    setSelectColumnsState(data)
  }

  const updateCheckedColumns = (isChecked:boolean) => {
    columnFields.forEach((columns:any) => {
      if(columns.label.toLowerCase().includes(columnFilterSearch)) setValue(columns.label, isChecked)
    })
  }
  const getReportExport = async () => {
    const reportSubmissionAPIDataObj = JSON.parse(JSON.stringify(reportSubmissionAPIData))
    const selColObj = JSON.parse(JSON.stringify(selectColumnsState))
    let columnSelect = _.filter(Object.keys(selColObj), (data: any) => {
      return selColObj[data] && referAPIObjKeys[data]
    })
    columnSelect = _.map(columnSelect , (columItem:any) => {
      return referAPIObjKeys[columItem]
    })
    reportSubmissionAPIDataObj["clientName"] = reportSubmissionAPIDataObj["client"]
    reportSubmissionAPIDataObj["client"] = null
    reportSubmissionAPIDataObj["recruiterName"] = reportSubmissionAPIDataObj["recruiter"]
    reportSubmissionAPIDataObj["mandateId"] = reportSubmissionAPIDataObj["Mandate"]
    reportSubmissionAPIDataObj["recruiter"] = ""
    reportSubmissionAPIDataObj["locationId"] = reportSubmissionAPIDataObj["mandateLocation"]
    reportSubmissionAPIDataObj["mandateLocation"] = ""
    const requestBody = {...reportSubmissionAPIDataObj,...{selectedColumn:[...["candidateName"],...columnSelect]}}
    try{
     const res = await ReportkManagementService.exportSubmitionReport(requestBody)
      if(res?.status === 200){
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CandidateDetails${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }      
    }catch(error){
      console.log(error)
    }
  }
  

  const [mandateLocationPreloadLists, setMandateLocationPreloadLists] = useState([])

  const getMandateLocationPreloadList = async (manId:any) => {
    setMandateLocationPreloadLists([])
    // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : "" 
    const apiQuery = `?mandateBehaviour=${manId?.mandateBehaviour}`
    const response = await getMandateLocation(manId?.value, apiQuery)
    if(response?.status === 200){
      const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
        return {
          label: _.get(data, 'location'),
          value: _.get(data, 'id'),
        }
      })
      setMandateLocationPreloadLists(locationOptions)
      setValue("mandateLocation", reportFilterPreload?.selectedFilterObjectValue?.mandateLocation)
    }
  }

  const FilterForms = () => {
    const [mandateList,setMandateList] = useState([])
    const [mandateLocationLists, setMandateLocationLists] = useState(mLocationList ?? [])
    const [searchtextonMandate, setSearchtextonMandate] = useState("")
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const [snackStatus, setSnackStatus] = useState(false)
    const [snackStatusMessage, setSnackStatusMessage] = useState("")
    const [snackStatusType, setSnackStatusType] = useState<any>("")
    const autoC = useRef<any>(null);
    const autoMandate = useRef<any>(null);
    const [clientfieldrequestdata, setClientFieldRequestData] = useState({
      filterkey: [] as any[],
      clientid: [] as any[],
      search: "" as string,
      jobtitlereq: [] as any[],
      dateRangeType: "",
      buId: [],
      userCurrentIndex: {} as any
    });  
    const [currentUserSelected, setCurrentUserSelected] = React.useState<any>([]);

    const selectUserHandler = (userId: any,roleIndex: number) => {      
      const userIdData = userId.target.value
      let updatedData = JSON.parse(JSON.stringify(userRoleListObject));
      const referData = JSON.parse(JSON.stringify(userRoleListObject));
      updatedData[roleIndex].user.selectedValue = userIdData;
      if(userRoleListObject.length != roleIndex + 1 || (referData[roleIndex].user.selectedValue.length > 0 && userRoleListObject.length != roleIndex + 1)) {
        updatedData = updatedData.slice(0, roleIndex + 1)
        setUserRoleListObject(updatedData)
        setTimeout(() => {
          if(userIdData.length > 0)addRoleHierarchy(roleIndex,userIdData)
        },100)
      }else{
        setCurrentUserSelected(userIdData);
        setUserRoleListObject(updatedData)
      }   
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : "",
        userCurrentIndex: {type:"user",index:roleIndex}
      }));
    };

    const userRoleSelectHandler = (userRole: string, roleIndex: number) => {
      let updatedData:any = JSON.parse(JSON.stringify(userRoleListObject));
      updatedData[roleIndex].role.selectedValue = userRole;
      updatedData[roleIndex].user.option = userRoleListObject[roleIndex].role.groupedKeys[userRole];
      if(userRoleListObject.length != roleIndex + 1 || userRoleListObject[roleIndex].role.selectedValue.length > 0) {
        updatedData[roleIndex].user.selectedValue = [];
        updatedData = updatedData.slice(0, roleIndex + 1)
      }
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : "",
        userCurrentIndex: {type:"role",index:roleIndex}
      }));
      setUserRoleListObject(updatedData)
    }
  
  
    const addRoleHierarchy = (currentIndex: number,userObject:any) => {      
      if(currentUserSelected.length > 0 && userObject.length == 0){
        setCurrentUserSelected([])
      }
      const buIdValue:any = getValues("bussinessUnit")
      userRoleChange(((currentUserSelected.length > 0 && userObject.length == 0) ? currentUserSelected : userObject.length > 0 ? userObject : userRoleListObject[currentIndex].user.selectedValue),"addCase",buIdValue != null ? [buIdValue?.value] : [])
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : "",
      }));
    }
  
    const removeRoleHierarchy = (currentIndex: number) => {
      const updatedData = [...userRoleListObject];
      const sliceObj = updatedData.slice(0, currentIndex)
      setUserRoleListObject(sliceObj)
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : "",
        userCurrentIndex: {type:"role",index:currentIndex - 1}
      }));
    }

    const getData = (searchTerm: string) => {
      setSearchtextonMandate(searchTerm)
      MandateService.getSearchMandates(searchTerm).then((res: any) => {      
        const dataItems = _.get(res, 'data.result.list', [])
        const filterData = dataItems ? dataItems?.filter((item: any) => item?.status !== 'draft') : []
        const options = _.map(filterData, (data: any) => {
          return {
            label: _.get(data, 'title') + ' - ' + _.get(data, 'mandateId'),
            value: _.get(data, 'id'),
            mandateBehaviour: _.get(data, 'mandateBehaviour')
          }
        })
        setMandateList(options)
      })
    }

    const onInputChange = _.debounce((event: any, value: string)=>{
      if (_.trim(value) !== '') {
        getData(value)
      } else {
        setSearchtextonMandate('')
        setMandateList([])
      }
    }, 500)

    const getMandateLocationList = async (manId:any) => {
      setMandateLocationLists([])
      const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : `?mandateBehaviour=${manId?.mandateBehaviour}`
      const response = await getMandateLocation(manId?.value, apiQuery)      
      if(response?.status === 200){
        const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
          return {
            label: _.get(data, 'location'),
            value: _.get(data, 'id'),
          }
        })
        setMandateLocationLists(locationOptions)
        setMLocationList(locationOptions)
      }
    }

    const filterSubmitHandler = (Data:any) => {
      console.log("filterSubmitHandler",Data)
      let mandateLocation = false;
      if(getValues("Mandate")){
        if(preloadDataObj?.mandateLocation && !getValues("mandateLocation")){
          mandateLocation = true
        }
        else if(!getValues("mandateLocation")) {
          setSnackStatusMessage("Mandate Location Need to select");
          setSnackStatus(true);
          setSnackStatusType("error");
          return
        }
      }
      if(getValues("submissionDate")){
        if(!getValues("submissionEndDate")){
          setSnackStatusMessage("Submission end date Need to select");
          setSnackStatus(true);
          setSnackStatusType("error");
          return
        }
      }
      const filterSubmitObj = JSON.parse(JSON.stringify(reportSubmissionAPIData));
      Object.keys(Data).map((item:any) => {
        filterSubmitObj[item] = (item == "client" ) ? (Data?.client?.label == "All" ? "" : Data[item]?.label) : Data[item]?.value;
      })
      if(mandateLocation) filterSubmitObj["mandateLocation"] = preloadDataObj?.mandateLocation?.value
      // filterSubmitObj["userId"] = userRoleListObject.at(-1).user.selectedValue.length > 0 ? userRoleListObject.at(-1).user.selectedValue.toString() : userIdSelected
      filterSubmitObj["submissionDate"] = Data?.submissionDate != null ? moment(new Date(Data?.submissionDate)).format('YYYY-MM-DD') : "";
      filterSubmitObj["submissionEndDate"] = Data?.submissionEndDate != null ? moment(new Date(Data?.submissionEndDate)).format('YYYY-MM-DD') : "";
      filterSubmitObj["buid"] = Data?.bussinessUnit != null ? Data?.bussinessUnit.value != "All" ?  Data?.bussinessUnit.value : "": ""
      filterSubmitObj["userId"] = userRoleListObject.length > 0 ? userRoleListObject.at(-1).user.selectedValue.length > 0 ? userRoleListObject.at(-1).user.selectedValue.toString() : userRoleListObject.length > 1 ?  userRoleListObject[(userRoleListObject.length - 1) - 1].user.selectedValue.toString() :  userId : userId
      setReportSubmissionAPIData(filterSubmitObj)
      const reduxFilterData = {
        buid: Data?.bussinessUnit,
        client: Data?.client,
        mandate: Data?.Mandate,
        mandateLocation: Data?.mandateLocation ? Data?.mandateLocation : reportFilterPreload?.mandateLocation,
        submissionDate: Data?.submissionDate,
        submissionEndDate: Data?.submissionEndDate,
        userRoleObject: userRoleListObject
      }
      userTrigger()
      dispatch(reportFilterLoadData({reportFiler: reduxFilterData}))
    }

    const resetFilterData = () => {
      reset()
      setUserIdSelected(userId)
      clearLocationField();
      clearLocationMandateField()
      const reportSubmissionJsonObj = {...reportSubmissionAPIData}
      reportSubmissionJsonObj["buid"] = "";
      reportSubmissionJsonObj["bussinessUnit"] = "";
      reportSubmissionJsonObj["client"] = "";
      reportSubmissionJsonObj["Mandate"] = "";
      reportSubmissionJsonObj["mandateLocation"] = "";
      reportSubmissionJsonObj["submissionDate"] = "";
      reportSubmissionJsonObj["submissionEndDate"] = "";
      reportSubmissionJsonObj["userId"] = userId
      setReportSubmissionAPIData(reportSubmissionJsonObj)
      const filterSubmitObj = JSON.parse(JSON.stringify(reportSubmissionAPIData));
      Object.keys(filterSubmitObj).map((item:string) => {
        filterSubmitObj[item] = (item == "industry" || item == "department") ? [] : item == "pageNo" ? 1 : item == "pageSize" ? 10 : "";
      })
      filterSubmitObj["userId"] = userStateId
      filterSubmitObj["userIds"] = userStateId
      userRoleChange([userStateId],"reset", userDetailBuId != null ? [userId] : [])
      setUserRoleListObject([])
      const reduxFilterData = {
        client: {},
        mandate: {},
        mandateLocation: {},
        submissionDate: null,
        submissionEndDate: null,
        roleBaseObj : {}
      }
      dispatch(reportFilterLoadData({reportFiler: reduxFilterData}))
      setSubmissionStartDate(null)
      setSubmissionEndDate(null)
      setResetFilter(true)
    }
    const clearLocationField = () => {
      const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
      setMLocationList([])
    } 
    const clearLocationMandateField = () => {
      const ele = autoMandate.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 
    
    
    return (
      <>
        <Divider />
        <Snackbar open={snackStatus} autoHideDuration={6000} onClose={() => {setSnackStatus(false)}}>
            <Alert onClose={() => {setSnackStatus(false)}} severity={snackStatusType} sx={{ width: '100%' }}>
              {snackStatusMessage}
            </Alert>
          </Snackbar>
        <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
          <Grid item xs={12} md={12} sm={3} lg={12}>
            <Typography className='filter-title'> Filter</Typography>
          </Grid>
          <Box component={'form'} onSubmit={handleSubmit((data) => filterSubmitHandler(data))}>
            <div className='filterGrid reportFilters'>
            <Controller
                control={control}
                name='bussinessUnit'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={reportBussinessUnitAPIList}
                    defaultValue={reportBussinessUnitAPIList[0]}
                    sx={{ width: 300 }}
                    value={watch('bussinessUnit')}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("bussinessUnit", value)   
                      setSelectedFilterObjectValue((prev:any) => ({
                        ...prev,
                        "bussinessUnit": value,
                      }));
                      userRoleChange([userStateId],"",value!= null ? [value.value] : [])
                      if(value?.label == "All"){
                        clearLocationField();
                        clearLocationMandateField();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Business Unit" label={`Business Unit`} />}
                  />
                )}
              />
              <Controller
                control={control}
                name='client'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={reportClientAPIList}
                    defaultValue={reportClientAPIList[0]}
                    sx={{ width: 300 }}
                    value={watch('client')}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("client", value)  
                      setSelectedFilterObjectValue((prev:any) => ({
                        ...prev,
                        "client": value,
                      })); 
                      if(value?.label == "All"){
                        clearLocationField();
                        clearLocationMandateField();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Client" label={`Client`} />}
                  />
                )}
              />              
              <Controller
                control={control}
                name='Mandate'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    ref={autoMandate}
                    disablePortal
                    options={mandateList}
                    onInputChange={(e, val) => onInputChange(e, val)}
                    defaultValue={preloadDataObj?.mandate}
                    sx={{ width: 300 }}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("Mandate",value) 
                      setSelectedFilterObjectValue((prev:any) => ({
                        ...prev,
                        "Mandate": value,
                      })); 
                      value?.value ? getMandateLocationList(value) : clearLocationField() 
                      // setSearchMandateListFilter(searchMandateList.filter((item: any) => item?.title == value.label.split("-")[0].trim()).value)               
                    }}
                    noOptionsText={searchtextonMandate ? 'No options' : 'Type something...'}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id} >
                          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {option.label?.split('-')[0]}
                            </Typography>
                            <Typography className='dropdownSubtext'>
                              {option.label?.split('-')[1]}-{option.label?.split('-')[2]}
                            </Typography>
                          </Box>
                        </li>
                      )
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Mandate" label={"Mandate"} />}
                  />
                )}
              />
              <Controller
                control={control}
                name='mandateLocation'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    ref={autoC}
                    disablePortal
                    onInputChange={(e, val) => onInputChange(e, val)}
                    defaultValue={preloadDataObj?.mandateLocation ? preloadDataObj?.mandateLocation : getValues('mandateLocation')}
                    options={mandateLocationLists?.length > 0 ? mandateLocationLists : mandateLocationPreloadLists}
                    sx={{ width: 300 }}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("mandateLocation",value)
                      setSelectedFilterObjectValue((prev:any) => ({
                        ...prev,
                        "mandateLocation": value,
                      })); 
                    }}
                    renderOption={(props, option) => {
                      return ( <li {...props} key={option.id} >
                        {option.label}
                      </li>)
                      }}
                    renderInput={(params) => <TextField {...params} placeholder="Mandate Location" label={"Mandate Location"} />}
                  />
                )}
              />
              <Controller
                name='submissionDate'
                defaultValue={submissionStartDate}
                // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                control={control}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                    onChange={(date:any) => {
                      onChange(date); // Update the Controller's value
                      setSelectedFilterObjectValue((prev:any) => ({
                        ...prev,
                        "submissionDate": moment(new Date(date)).format('YYYY/MM/DD'),
                      })); 
                    }}
                      inputFormat='YYYY/MM/DD'                      
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Submission Start Date" label={"Submission Start Date"} />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name='submissionEndDate'
                defaultValue={submissionEndDate}
                // defaultValue={preloadDataObj?.submissionEndDate? preloadDataObj?.submissionEndDate : null}
                control={control}
                disabled={(watch('submissionDate') || submissionEndDate) == null}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(date:any) => {
                        onChange(date)
                        setSelectedFilterObjectValue((prev:any) => ({
                          ...prev,
                          "submissionEndDate": moment(new Date(date)).format('YYYY/MM/DD'),
                        })); 
                      }}
                      inputFormat='YYYY/MM/DD'
                      minDate={dayjs(moment(new Date((watch('submissionDate') || submissionEndDate))).format('YYYY/MM/DD'))}
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Submission End Date" label={"Submission End Date"} />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
            {userRoleListObject.length > 0 && <Grid className="user-role-based-container" item xs={12} sm={6}>
              <h4>Role - Based Filters</h4>
              <Box className="user-role-based-hierarchy">
                {/* {JSON.stringify(userRoleListObject)} */}
                {userRoleListObject.map((userRoleItem: any, index: number) => {
                  return (
                    <Box key={index} className={`${(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) ? "cancelState" : (userRoleListObject.length == index + 1 && userRoleItem.role.option.length == 1) ? "lastNoState" : "addState"} user-role-based-hierarchy-row dashboard-user-filter`}>
                      <Box className="user-role-basedFields">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Role</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userRoleItem.role.selectedValue}
                            autoFocus={Object.keys(clientfieldrequestdata.userCurrentIndex).length > 0 ? ((clientfieldrequestdata.userCurrentIndex.index == index && clientfieldrequestdata.userCurrentIndex.type == "role") ? true : false) : false}
                            label="Role"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={(e: any) => { userRoleSelectHandler(e.target.value, index) }}
                          >
                            {userRoleItem.role.option.map((options: any, optionIndex: number) => {
                              return (
                                <MenuItem key={optionIndex} value={options}>{options}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <FormControl className={(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) ? "cancelBtnAdded" : ""} sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={userRoleItem.user.selectedValue.length > 0 ? userRoleItem.user.selectedValue : currentUserSelected.length > 0 ? currentUserSelected : []}
                            disabled={userRoleItem.user.option.length == 0 ? true : false}
                            onChange={(e: any) => { selectUserHandler(e,index) }}
                            autoFocus={Object.keys(clientfieldrequestdata.userCurrentIndex).length > 0 ? ((clientfieldrequestdata.userCurrentIndex.index == index && clientfieldrequestdata.userCurrentIndex.type == "user") ? true : false) : false}
                            input={<OutlinedInput label="Select" />}
                            IconComponent={KeyboardArrowDownIcon}
                            renderValue={(selected: any) =>
                              selected
                                .map((selectedId: string) => {
                                  const user = userRoleItem.user.option.find(
                                    (userItem: any) => userItem.keycloak_id === selectedId
                                  );
                                  return user ? user.name : selectedId;
                                })
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {userRoleItem.user.option.map((userItem: any, index: number) => {
                              return (
                                <MenuItem key={index} value={userItem.keycloak_id}>
                                  <Checkbox checked={userRoleItem.user.selectedValue.includes(userItem.keycloak_id)} />
                                  <ListItemText primary={userItem.name} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        {(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) && <Button className="userRoleCancelBtn" onClick={() => { removeRoleHierarchy(index + 1) }}><UserRoleCancel /></Button>}
                      </Box>
                       {(userRoleListObject.length == index + 1 && userRoleItem.role.option.length > 1) && <FormControl><Box className="userRoleFilterAction">
                        <Button className={`userRoleAddBtn ${userRoleItem.user.selectedValue.length == 0 ? "disablePlus" : ""}`} onClick={() => { addRoleHierarchy(index,[]) }}><UserRoleImgPlus /></Button>
                      </Box></FormControl>}
                    </Box>
                  )
                })}

              </Box>
            </Grid>}
            <Grid className="filter-btn-container" item xs={12} sm={6}>
              <Stack justifyContent="end" gap={1} direction='row'>
                  <Box className='filter-search-btn'>
                    <AppButton
                      label='Search'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      type='submit'
                      className='search-bth'
                      sx={{ color: '#fff' }}
                    />
                  </Box>
                  <Button
                    sx={{
                      color: '#002882',
                      textTransform: 'none',
                    }}
                    variant='text'
                    // onClick={resetAllFilters}
                  >
                    <RotateIcon />
                    <Typography className='reset-label' onClick={() => {resetFilterData()}}>Reset All</Typography>
                  </Button>
                </Stack>
            </Grid>
          </Box>
        </Box>
      </>
    )
  }

    return (
        <>
          <Paper className='box-style' elevation={1}>
            <Box display='flex' justifyContent='space-between' className='filter-parent-box'>
              <Box className='tasklist' >
                <Typography display='flex' gap={1} className='tasklist-title'>
                  Total Candidate Pipeline <Typography color={PRIMARY2}>({reportData.totalRecords})</Typography>
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                <Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    className='reportFilterBtn'
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  {/* <AppButton minwidth={40} margin={-5}>
                    <SortIcon />
                  </AppButton> */}
                  <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    minwidth={40}
                    className='class-new-button'
                    onClick={(event) => openCoumnFilter(event)}
                  >
                    <MenuIcon />
                  </AppButton>
                  <AppButton
                    disabled ={reportData.totalRecords == 0 ? true : false}
                    variant='contained'
                    bgcolor={PRIMARY3}
                    margin={5}
                    minwidth={40}
                    onClick={getReportExport}
                  >
                    <DownloadIcon />{' '}
                  </AppButton>
                </Box>
              </Box>
            </Box>

    
            {filterChecked && (
              <Box>
                <FilterForms />
              </Box>
            )}
          </Paper>
          <Popover
            open={columnFilter}
            anchorEl={anchorEl}
            onClose={() => setColumnFilter(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className='reportColumnFilters'
            PaperProps={{
              style: { width: `300px` },
            }}
          >
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Search Column Name"
                value={columnFilterSearch}
                onChange={(e) => setColumnFilterSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box component={'form'}>
              <Box className='selectAllCheckbox' display='flex'>
                <Checkbox
                  checked={selectAllColumnsState}
                  onChange={(e) => {
                    updateCheckedColumns(e.target.checked)
                    setSelectAllColumnsState(!selectAllColumnsState)
                    handleSubmit((data) => hitSave(data,"all"))();
                  }}
                  className='search-result-item-checkbox'
                />
                <p>Select All Columns</p>
              </Box>
              <Box className="reportColumnCheckboxes">
                {columnFields.map((columns:any, index:any) => {
                  return (
                    <Controller
                      key={index}
                      name={columns.label}
                      defaultValue={columns.checkStatus}
                      control={control}
                      render={({ field: { value, onChange, ...restField } }:any) => (
                        <Box display={columns.label.toLowerCase().includes(columnFilterSearch) ? "flex" : 'none'}>
                          <Checkbox
                            onChange={(e) => {
                              onChange(e);
                              const columnFieldsCopy = JSON.parse(JSON.stringify(columnFields))
                              columnFieldsCopy.map((item: any) => {
                                if(item?.label ==  columns.label) {
                                  item.checkStatus = e.target.checked
                                }
                              })
                              setColumnFields(columnFieldsCopy)
                              handleSubmit((data) => hitSave(data,""))();
                            }}
                            checked={value}
                            className='search-result-item-checkbox'
                          />
                          <p>{columns.label}</p>
                        </Box>
                      )}
                    />
                  )
                })}
              </Box>
            </Box>
          </Popover>
        </>
    )
}
export const Filter = React.memo(FilterComponent);