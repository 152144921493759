import { configureStore, combineReducers } from '@reduxjs/toolkit'
import AuthReducer from '../features/auth/AuthReducer'
import ToggleReducer from '../features/toggle/ToggleReducer'
import DrawerReducer from '../features/mandateDetail/DrawerReducer'
import TaskDrawerReducer from '../features/taskDetail/DrawerReducer'
import FilterReducer from '../features/filters/FilterReducer'
import SmartFlowReducer from '../features/CallReducer/CallReducer'
import UserDetailReducer from '../features/userDetail/UserDetailReducer'
import PersistFilterReducer from '../features/filters/PersistFilter'
import chatGptReducer from '../features/chatGpt/ChatGptReducer'
import SystemConfigReducer from '../features/systemConfig/SystemConfig'
import minimizeReducer from '../features/chatGpt/minimizeReducer'
import notificationReducer from '../features/notification/notificationReducer'
import LoaderReducer from '../features/loadDetector/loadDetectorReducer';
import MandateProfilePersistReducer from "../features/mandateDetail/profilePersistReducer"
// logger middleware
import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

// persist configuration
const persistConfig = {
  key: 'root',
  version: 1,
  blacklist: ['drawertoggle','mandatedrawer'],
  storage
}

// // user persist - avoid persisting error state
// const userPersistConfig = {
//   key: 'user',
//   storage,
//   blacklist: ['error']
// }

// combine reducers
const rootReducer = combineReducers({
  // auth: AuthReducer,
  auth: AuthReducer,
  drawertoggle: ToggleReducer,
  mandatedrawer:DrawerReducer,
  filtersReducer: FilterReducer,
  taskdrawer:TaskDrawerReducer,
  smartflow: SmartFlowReducer,
  UserDetail: UserDetailReducer,
  PersistFilterReducer: PersistFilterReducer,
  chatGPT: chatGptReducer,
  SystemConfig: SystemConfigReducer,
  minimizeState:minimizeReducer,
  notificationState:notificationReducer,
  loaderState:LoaderReducer,
  profilePersistor:MandateProfilePersistReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  // redux-thunk and redux-logger were added as middleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  // The Redux DevTools Extension is disabled for production
  devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
