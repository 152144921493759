import React, { useEffect, useState } from 'react';
import DashboardFilterIndex from '../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import "../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail.scss"
import { IMaskInput } from 'react-imask';
import { ResponsiveBar } from '@nivo/bar'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { PRIMARY1 } from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooks';
import { jobsAgeingReportList } from '../../../services/DashboardService';
import { useLocation, useNavigate } from 'react-router-dom';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }


const JobOrderAgeing = () => {
    const location = useLocation();
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state !=  null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "filterListLabels" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.filterListLabels : {} : {}) : {})
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [sortColumn, setSortColumn] = useState<string>('candidateName');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [columnsort, setcolumnsort] = useState<string>('asc');
    const [sortingTableHead, setSortingTableHead] = useState<any>({});
    const [paginationCount, setPaginationCount] = useState(1)
    const userId = useAppSelector((state: any) => state.UserDetail.userId);
    const [jobAgeingList, setJobAgeingList] = useState([])
    const [jobAgeingTotal, setJobAgeingTotal] = useState(0)
    const navigate = useNavigate();
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [initalCount, setInitalCount] = useState(0)
    const [userDependendObj,setUserDependendObj] = useState({})
    const initialColumnFields = [
        { id: "1", label: "Client Name", checkStatus: true, reqObjkey: "clientname" },
        { id: "2", label: "Business Unit", checkStatus: true, reqObjkey: "buname" },
        { id: "3", label: "0 to 30 Days", checkStatus: true, reqObjkey: "jobTitle" },
        { id: "4", label: "31 to 60 Days", checkStatus: true, reqObjkey: "clientName" },
        { id: "5", label: "61 to 90 Days", checkStatus: true, reqObjkey: "currentOrganization" },
        { id: "6", label: "91 to 120 Days", checkStatus: true, reqObjkey: "totalExperience" },
        { id: "7", label: "120 to 150 Days", checkStatus: true, reqObjkey: "totalExperience" },
        { id: "8", label: "151 to 180 Days", checkStatus: true, reqObjkey: "totalExperience" },
        { id: "9", label: "Above 180 Days", checkStatus: true, reqObjkey: "totalExperience" }
      ];

    const [columnFields, setColumnFields] = useState(initialColumnFields);
    const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }
    const renderLoadMore = () => {;
        return (
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className='load-more-container'
                onClick={() => {setPaginationCount(paginationCount + 1)}}
            >
                <Typography component='span' className='load-more' sx={{ color: `${PRIMARY1}` }}>
                Load More <LoadMoreIcon className='load-more-icon' />
                </Typography>
            </Box>
        )
    }
    const sortTableHeadHandler = (column:any) => {
        let newDirection = '';
        if (columnsort === 'asc') {
            newDirection = '';
        } else if (columnsort === 'desc') {
            newDirection = 'asc';
        } else {
            newDirection = 'desc';
        }
        newDirection = column.reqObjkey != sortColumn ? 'desc' : newDirection
        setSortDirection(newDirection);
        setcolumnsort(newDirection);
        setSortColumn(column.reqObjkey);
        setSortingTableHead({
            [column.id]: newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
        });
        
        
    };
    const CustomTooltip = ({ data }:any) => {
        const color = data.id.indexOf("in") > -1 ? data.data.inServiceMandateColor : data.data.unServiceMandateColor
        const label = data.id.indexOf("in") > -1 ? data.data.inServiceMandateLabel : data.data.unServiceMandateLabel
        return (
          <Box
          sx={{
            position: 'relative',
            zIndex:"100",
            padding: '8px',
            background: "#000",
            border: '1px solid #000',
            borderRadius: '5px',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: '8px solid black',
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-1px)',
              width: 0,
              height: 0,
              borderLeft: '7px solid transparent',
              borderRight: '7px solid transparent',
              borderTop: `7px solid #000`,
            },
          }}
        >
            <Box>
                <Typography variant="body2" sx={{ fontSize: '11px', color: '#fff',textTransform:"capitalize" }}>{label}</Typography>
                <Typography variant="body1" sx={{ fontSize: '15px', color: '#fff' }}>{data?.formattedValue}</Typography>
            </Box>
        </Box>
        )
      };

    const jobAgeingRedirectHandler = (data:any) => {
        const jobAgeingObj = {
            "reportType": data.data.type,  
            "reportStage":data.id.indexOf("in") > -1 ? "inservice" : "unserved",
            "clientID_JO":data.data.clientId,
            "BUID_JO":data.data.buId,
            "label": data.data.label
        }
        console.log("reportSubmissionAPIData",reportSubmissionAPIData)
        console.log("reportSubmissionAPIData",jobAgeingObj)
        console.log("reportSubmissionAPIData",DashboardFilterDatas)
        console.log("reportSubmissionAPIData",reportSubmitdatalabels)
        navigate(`/jobOrderAgeingDetail`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels, jobOrderAgeingDetails: jobAgeingObj,userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj} });
    }
    const jobOrderBarChartWidget = (widgetData:any, widgetType:string,clientId:string,buId:string,label:string) => {
       let chartKeys:any = []
        if(widgetType == "mandate"){
            chartKeys = [
                'inServiceMandate',
                'unServiceMandate'
            ]
        }else {
            chartKeys = [
                'inServiceJobOrders',
                'unServiceJobOrders'
            ]
        }
       let jobOrderData:any = []
        if(widgetType == "mandate"){
            jobOrderData = [{
                "mandate": "AD",
                "inServiceMandate": widgetData.inservice_mandate_age_data,
                // "inServiceMandate": 60,
                "inServiceMandateColor": "#2282DB",
                "inServiceMandateLabel": "inService Mandate",
                "type": "Mandate",
                "clientId": clientId,
                "buId": buId,
                "label": label,
                "unServiceMandate": widgetData.unserved_mandate_age_data,
                // "unServiceMandate": 70,
                "unServiceMandateColor": "#7CC0FF",
                "unServiceMandateLabel": "unService Mandate",
              }]
        }else {
            jobOrderData = [{
                "mandate": "AD",
                "inServiceJobOrders": widgetData.inservice_job_order_age_data,
                // "inServiceJobOrders": 30,
                "inServiceMandateColor": "#D18B0E",
                "inServiceMandateLabel": "inService JobOrders",
                "type": "joborder",
                "clientId": clientId,
                "buId": buId,
                "label": label,
                "unServiceJobOrders": widgetData.unserved_job_order_age_data,
                // "unServiceJobOrders": 80,
                "unServiceMandateColor": "#FFC151",
                "unServiceMandateLabel": "unService JobOrders",
              }]
        }
        const countTotal = widgetType == "mandate" ? (jobOrderData[0]?.inServiceMandate + jobOrderData[0].unServiceMandate) : (jobOrderData[0]?.inServiceJobOrders + jobOrderData[0]?.unServiceJobOrders)
       return <div style={{width:"150px",height:"20px",overflow:"visible"}}>
        {(countTotal == 0) ?
        <>
        <p style={{margin:"0px",textAlign:"center"}}>-</p>
        </> :
        <ResponsiveBar
            data={jobOrderData}
            keys={chartKeys}
            indexBy="mandate"
            margin={{ top: 0, right: 30, bottom: 0, left: 0 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[jobOrderData[0].inServiceMandateColor,jobOrderData[0].unServiceMandateColor]}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}            
            enableGridY={false}
            enableLabel={false}
            enableTotals={true}
            totalsOffset={8}
            labelSkipWidth={14}
            labelSkipHeight={28}
            axisBottom={null}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            tooltip={(bar:any) => <CustomTooltip data={bar} />}            
            onClick={(data) => {jobAgeingRedirectHandler(data)}}
            onMouseEnter={(_datum, event:any) => {
                event.currentTarget.style.cursor = "pointer";
              }}
        />}
        </div>
    }
    const jobAgeingListAPi = async () => {
        const jobAgeingPayload:any = {
            "pageSize":10,
            "pageNo":paginationCount,
            "sortColumn" : sortColumn,
            "sortDirection": sortDirection
        }
        reportSubmissionAPIData["businessUnit"] != undefined && reportSubmissionAPIData["businessUnit"].length > 0 ? jobAgeingPayload["businessUnit"] = reportSubmissionAPIData["businessUnit"]: ""
        reportSubmissionAPIData["client"] != undefined ? jobAgeingPayload["client"] = reportSubmissionAPIData["client"]: ""
        reportSubmissionAPIData["coe"] != undefined ? jobAgeingPayload["coe"] = reportSubmissionAPIData["businessUnit"]: ""
        reportSubmissionAPIData["industry"] != undefined ? reportSubmissionAPIData["industry"].length > 0 ? jobAgeingPayload["industry"] = reportSubmissionAPIData["industry"] : "" : ""
        reportSubmissionAPIData["mandateSPOC"] != undefined && reportSubmissionAPIData["mandateSPOC"].length > 0 ? jobAgeingPayload["mandateSPOC"] = reportSubmissionAPIData["mandateSPOC"]: ""
        reportSubmissionAPIData["jobTitle"] != undefined ? jobAgeingPayload["jobTitle"] = reportSubmissionAPIData["jobTitle"]: ""
        reportSubmissionAPIData["joLocation"] != undefined && reportSubmissionAPIData["joLocation"].length > 0 ? jobAgeingPayload["joLocation"] = reportSubmissionAPIData["joLocation"]: ""
        reportSubmissionAPIData["primarySkills"] != undefined && reportSubmissionAPIData["primarySkills"].length > 0 ? jobAgeingPayload["primarySkills"] = reportSubmissionAPIData["primarySkills"]: ""
        reportSubmissionAPIData["mandateType"] != undefined && reportSubmissionAPIData["mandateType"].length > 0 ? jobAgeingPayload["mandateType"] = reportSubmissionAPIData["mandateType"]: ""
        reportSubmissionAPIData["jobMode"] != undefined && reportSubmissionAPIData["jobMode"].length > 0 ? jobAgeingPayload["jobMode"] = reportSubmissionAPIData["jobMode"]: ""
        reportSubmissionAPIData["roles"] != undefined && reportSubmissionAPIData["roles"].length > 0 ? jobAgeingPayload["roles"] = reportSubmissionAPIData["roles"]: ""
        reportSubmissionAPIData["customStartDate"] != undefined ? jobAgeingPayload["customStartDate"] = reportSubmissionAPIData["customStartDate"]: ""
        reportSubmissionAPIData["customEndDate"] != undefined ? jobAgeingPayload["customEndDate"] = reportSubmissionAPIData["customEndDate"]: ""
        reportSubmissionAPIData["customEndDate"] != undefined ? jobAgeingPayload["customEndDate"] = reportSubmissionAPIData["customEndDate"]: ""
        reportSubmissionAPIData["userId"] != undefined ? jobAgeingPayload["userID"] = reportSubmissionAPIData["userId"] : jobAgeingPayload["userID"] = [userId]
        console.log("reportSubmissionAPIData",reportSubmissionAPIData["userId"] != undefined)
        try {
            const res: any = await jobsAgeingReportList(jobAgeingPayload);
            const ageingReport = res.data.data.map((item:any) => {
            const unInserviceList = ["inservice_mandate_age_data","unserved_mandate_age_data","inservice_job_order_age_data","unserved_job_order_age_data"]
            const daysHeader = ["0-30days","31-60days","61-90days","91-120days","121-150days","151-180days","180+days"]
            const reportItem:any = {
                buId: item.bu_id,
                buName: item.bu_name,
                clientId: item.client_id,
                clientName: item.client_name
            }
            const unAvailableKey:any = {}
            const availableKeys: any = []
            unInserviceList.map((servList:string) => {
                if(servList in item) {
                    availableKeys.push(servList)
                    unAvailableKey[servList] = 0
                 }else{
                    unAvailableKey[servList] = 0
                 }
            })
            if(Object.keys(unAvailableKey).length > 0){
                daysHeader.map((daysItem:string) => {
                    reportItem[daysItem] = unAvailableKey
                })
            }
            availableKeys.map((aval:any) => {
                item[aval].map((ListItem:any,index:number) => {                    
                    const keyItem  = ListItem.age_category.replaceAll(" ","") 
                    // reportItem[keyItem][aval] = ListItem.count
                    const checkedObj = JSON.parse(JSON.stringify(reportItem[keyItem]))
                    checkedObj[aval] = ListItem.count
                    reportItem[keyItem] = checkedObj
                })
            })
            return reportItem
        })
        setJobAgeingTotal(res.data.totalRecords)
        if(paginationCount == 1){
            setJobAgeingList(ageingReport)
        }else{
            const jobListArray:any = [...jobAgeingList, ...ageingReport]
            setJobAgeingList(jobListArray)
        }
        
        } catch (error: any) {
            console.log(error);
        }
    }
    useEffect(() => {
        jobAgeingListAPi()
    },[sortDirection,paginationCount])
    useEffect(() => {
        if(initalCount == 0){
            setInitalCount(1)
        }else{
            if(paginationCount != 1){
                setPaginationCount(1)
            }else{
                jobAgeingListAPi()
            }
        }        
    },[reportSubmissionAPIData])
    const [headerTitle,setHeaderTitle] = useState(`<Box className='tasklist mar0'>
        <Typography display='flex' gap={1} className='tasklist-title'>
        <Box style="font-weight: 600; font-size: 16px">Dashboard</Box>
        </Typography>
    </Box>`)
    return (
        <div>
            <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={headerTitle} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} filterType={"mainScreen"}/>
        
            <Box className="reportTableContSect hiringTableSect" style={{marginTop:'20px'}}>                
                <div className='jobOrderAgeingHeaderSect'>
                    <h4 style={{margin:"0px"}}>Job Order Ageing Report ~ Based on Req. Start Date</h4>
                    <ul>
                        <li><span style={{backgroundColor:"#2282DB"}}></span>Inservice Mandates</li>
                        <li><span style={{backgroundColor:"#7CC0FF"}}></span>Unservice Mandates</li>
                        <li><span style={{backgroundColor:"#D18B0E"}}></span>Inservice Job Orders</li>
                        <li><span style={{backgroundColor:"#FFC151"}}></span>Unservice Job Orders</li>
                    </ul>
                </div>
                <TableContainer className="submissionReportContainer jobOrderTable" component={Paper} sx={{border:'1px solid #E5E8EB !important'}}>
                    <Table sx={{ minWidth:'auto' }} aria-label="simple table">
                        <TableHead  style={{
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 10,
                                }}>

                        <TableRow>
                            {columnFields.filter(column => column.checkStatus).map((column,index) => (
                                <TableCell  key={column.id} style={{ whiteSpace: 'nowrap', cursor:'pointer', position: parseInt(column.id) > 2 ? "relative" : "sticky", left: (parseInt(column.id) == 1 ? 0 : parseInt(column.id) == 2 ? 195 : "auto"), backgroundColor: "#F3F3F7",zIndex:parseInt(column.id) > 2 ? 1 : 10}} 
                                >
                                <div style={{textAlign: parseInt(column.id) > 2 ? "center" : "left"}} onClick={() => sortTableHeadHandler(column)}>
                                    {column.label}
                                    {parseInt(column.id) <= 2 && <>{
                                    sortingTableHead && sortingTableHead[column.id] ? 
                                        (sortingTableHead[column.id] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />
                                    }</>
                                }
                                </div>
                                </TableCell>
                            ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobAgeingList && jobAgeingList.length > 0 ? jobAgeingList.map((jobAgeing:any,index) => {
                                return (
                                <TableRow key={index} style={{ minHeight: 'fit-content'}}>
                                    <TableCell style={{ width: "150px",position: 'sticky', left: 0, background: 'white', zIndex: 1,whiteSpace: 'nowrap'}}><Tooltip title={jobAgeing.clientName}>{jobAgeing.clientName || "-"}</Tooltip></TableCell>
                                    <TableCell style={{ position: 'sticky', left: 195, background: 'white', zIndex: 1,whiteSpace: 'nowrap'}}><Tooltip title={jobAgeing.buName}>{jobAgeing.buName || "-"}</Tooltip></TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['0-30days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['0-30days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['31-60days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['31-60days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['61-90days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['61-90days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['91-120days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['91-120days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['121-150days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['121-150days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['151-180days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['151-180days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{jobOrderBarChartWidget(jobAgeing['180+days'],"mandate",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}{jobOrderBarChartWidget(jobAgeing['180+days'],"jobOrder",jobAgeing["clientId"],jobAgeing["buId"],jobAgeing.clientName)}</TableCell>
                                </TableRow>                                    
                                );
                            }) : (
                                <TableRow>
                                <TableCell colSpan={columnFields.length} align="center">
                                    {renderEmptySearchBox()}
                                </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                {(jobAgeingTotal > 10 && jobAgeingList.length < jobAgeingTotal ) && renderLoadMore()}
                </Box>
        </div>
    );
};

export default JobOrderAgeing;