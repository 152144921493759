import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox, Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input, Tab } from "@mui/material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import _ from 'lodash';

import { useForm } from 'react-hook-form';

import './HiringDemandCardDetail.scss'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactComponent as ClientIcon } from '../../../../assets/icons/widgets/Client.svg'
import { ReactComponent as MandateIcon } from '../../../../assets/icons/widgets/Mandate.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/icons/widgets/candidate.svg'
import { gethiringdemandClentMandatelistapi, gethiringdemandlistapi } from '../../../../services/DashboardService';
import HiringDemandTableSection from './HiringDemandTableSection';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactComponent as HiringDemandTabIcon1 } from '../../../../assets/icons/HiringDemandTabIcon1.svg'
import { ReactComponent as HiringDemandTabIcon2 } from '../../../../assets/icons/HiringDemandTabIcon2.svg'
import { ReactComponent as HiringDemandTabIcon3 } from '../../../../assets/icons/HiringDemandTabIcon3.svg'
import { ReactComponent as HiringDemandTabGrey1 } from '../../../../assets/icons/HiringDemandTab1_1.svg'
import { ReactComponent as HiringDemandTabGrey2 } from '../../../../assets/icons/HiringDemandTab2_1.svg'
import { ReactComponent as HiringDemandTabGrey3 } from '../../../../assets/icons/HiringDemandTab3_1.svg'
import DashboardFilterIndex from '../../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';




const HiringDemandCardDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const [HirningDemandParamsDetails, setHirningDemandParamsDetails] = useState<any>(location)
  const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>(location)
  const [exportdatareqest, setexportdatareqest] = useState<any>({})
  const [candidates, setCandidates] = useState<any[]>([]);
  const [candidateData, setCandidateData] = useState<any | any[]>([]);
  const [sortColumn, setSortColumn] = useState<string>('candidateName');
  const [sortDirection, setSortDirection] = useState<string>('asc');
  const { userRole, userId } = useAppSelector((state) => state.UserDetail)
  const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
  const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
  const [userDependendObj,setUserDependendObj] = useState({})
  const [paginationCountSize, setPaginationCountSize] = useState({
    inputPageSize: 10,
    pageSize: 1,
    inputPageNo: 1,
    totalRecords: 0,
  });

  const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: any }>({});
  const [reportSubmissionAPIData, setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state !=  null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
  const [tabValue, setTabValue] = React.useState((HirningDemandParamsDetails.state != null && "tabValue" in HirningDemandParamsDetails.state) ? HirningDemandParamsDetails?.state?.tabValue : HirningDemandParamsDetails?.state?.tabSwitch);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
  const [hiringDemandTabDetail,setHiringDemandTabDetail] = useState<any>((HirningDemandParamsDetails.state != null && "hiringTabObj" in HirningDemandParamsDetails.state) ? HirningDemandParamsDetails.state.hiringTabObj : {
    sortColumn: "",
    sortDirection: "",
    paginationCountSize: {
      inputPageSize: 10,
      pageSize: 1,
      inputPageNo: 1,
      totalRecords: 0,
    },
    textFieldValues: ""
  })

  const [hiringDemandTabBasedObject, setHiringDemandTabBasedObject] = useState<any>({
    "client":{
      data:[],
      requestData: []
    },
    "mandate":{
      data:[],
      requestData: []
    },
    "candidate":{
      data:[],
      requestData: []
    }
  })

  function toCamelCaseAndAppend(str:string, appendWord:string) {
    const camelCaseStr = str
        .toLowerCase()
        .split('_')
        .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join('');
    
    return camelCaseStr + appendWord.charAt(0).toUpperCase() + appendWord.slice(1);
}

const candidateRedirectNavigator = (candidateId:any) => {
  navigate(`/candidate/${candidateId}`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels , data: HirningDemandParamsDetails.state.data, datahandle:{resultType:HirningDemandParamsDetails.state.datahandle.resultType,resultStage:HirningDemandParamsDetails.state.datahandle.resultStage},userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj, hiringTabObj: hiringDemandTabDetail,source: "hiringDrillDown",tabValue: tabValue, cardType: HirningDemandParamsDetailsCopy.state.cardType} });
}

  const hiringListApi = async () => {
    const reqObj: any = {      
      // "sortColumn": hiringDemandTabDetail.sortColumn,
      // "sortDirection": hiringDemandTabDetail.sortDirection,
      "userId": [userId],
      "pageSize": hiringDemandTabDetail.paginationCountSize.inputPageSize,
      "pageNo": hiringDemandTabDetail.paginationCountSize.inputPageNo,
      "resultType": tabValue === "1" ? "client" : tabValue === "2" ? "mandate" : "joborders",
      "resultStage": location?.state?.datahandle?.resultStage === "active"
      ? "active"
      : location?.state?.datahandle?.resultStage === "inservice"
      ? "inservice"
      : location?.state?.datahandle?.resultStage === "unservice"
      ? "unserved"
      : location?.state?.datahandle?.resultStage === "onHoldXpheno"
      ? "onholdbyxpheno"
      : location?.state?.datahandle?.resultStage === "onHoldClient"
      ? "onholdbyclients"
      : location?.state?.datahandle?.resultStage === "onBoard"
      ? "onboards"
      : location?.state?.datahandle?.resultStage === "lostOpportunity"
      ? "lostoppertunity"
      : location?.state?.datahandle?.resultStage, 
    };
    const HirningDemandParamsDetailsData = HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {}
    const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
    if(Object.keys(reportSubmissionAPIData).length > 0){if("coe" in reportSubmissionAPIData){reqObj["coe"] = reportSubmissionAPIData?.coe}}else if("coe" in HirningDemandParamsDetailsData){reqObj["coe"] = HirningDemandParamsDetailsData?.coe}
      // if(Object.keys(reportSubmissionAPIData).length > 0){if("deliveryManagerUserID" in reportSubmissionAPIData){reqObj["deliverymanager"] = reportSubmissionAPIData?.deliveryManagerUserID}}else if(HirningDemandParamsDetailsData?.deliveryManagerUserID.length > 0){reqObj["deliverymanager"] = HirningDemandParamsDetailsData?.deliveryManagerUserID}
      // if(Object.keys(reportSubmissionAPIData).length > 0){if("teamLeadUserID" in reportSubmissionAPIData){reqObj["teamLead"]=reportSubmissionAPIData?.teamLeadUserID}}else if(HirningDemandParamsDetailsData?.deliveryManagerUserID.length > 0){reqObj["teamLead"] = HirningDemandParamsDetailsData?.deliveryManagerUserID}
      if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["jobTitle"] = reportSubmissionAPIData?.jobTitle}else if("jobTitle" in HirningDemandParamsDetailsData){reqObj["jobTitle"] = HirningDemandParamsDetailsData?.jobTitle}
      // if(Object.keys(reportSubmissionAPIData).length > 0){if("RecruiterUserID" in reportSubmissionAPIData){reqObj["Recruiter"]= reportSubmissionAPIData?.RecruiterUserID}}else if(HirningDemandParamsDetailsData?.RecruiterUserID.length > 0){reqObj["Recruiter"] = HirningDemandParamsDetailsData?.RecruiterUserID}
      if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["clientId"] = reportSubmissionAPIData?.client}else if("client" in HirningDemandParamsDetailsData){reqObj["clientId"] = HirningDemandParamsDetailsData?.client}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.joLocation.length > 0)reqObj["joLocation"] = reportSubmissionAPIData?.joLocation}else if("joLocation" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.joLocation.length > 0)reqObj["joLocation"] = HirningDemandParamsDetailsData?.joLocation}
      if(Object.keys(reportSubmissionAPIData).length > 0){reqObj["businessUnit"] = reportSubmissionAPIData?.businessUnit}else if("businessUnit" in HirningDemandParamsDetailsData){reqObj["businessUnit"] = HirningDemandParamsDetailsData?.businessUnit}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.mandateType.length > 0)reqObj["mandateType"] = reportSubmissionAPIData?.mandateType}else if("mandateType" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.mandateType.length > 0)reqObj["mandateType"] = HirningDemandParamsDetailsData?.mandateType}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.jobMode.length > 0)reqObj["jobMode"] = reportSubmissionAPIData?.jobMode}else if("jobMode" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.jobMode.length > 0)reqObj["jobMode"] = HirningDemandParamsDetailsData?.jobMode}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.mandateSPOC.length > 0)reqObj["mandateSPOC"] = reportSubmissionAPIData?.mandateSPOC}else if("mandateSPOC" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.mandateSPOC.length > 0)reqObj["mandateSPOC"] = HirningDemandParamsDetailsData?.mandateSPOC}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.roles.length > 0)reqObj["roles"] = reportSubmissionAPIData?.roles}else if("roles" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.roles.length > 0)reqObj["roles"] = HirningDemandParamsDetailsData?.roles}
      if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.primarySkills.length > 0)reqObj["primarySkills"] = reportSubmissionAPIData?.primarySkills}else if("primarySkills" in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.primarySkills.length > 0)reqObj["primarySkills"] = HirningDemandParamsDetailsData?.primarySkills}
      // if(Object.keys(reportSubmissionAPIData).length > 0){if(reportSubmissionAPIData?.industry.length > 0)reqObj["industry"] = reportSubmissionAPIData?.industry}else if("industry"in HirningDemandParamsDetailsData){if(HirningDemandParamsDetailsData.industry.length > 0)reqObj["industry"] = HirningDemandParamsDetailsData?.industry}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("customEndDate" in reportSubmissionAPIData) reqObj["customEndDate"] = reportSubmissionAPIData?.customEndDate.toString()}else if("customEndDate" in HirningDemandParamsDetailsData){reqObj["customEndDate"] = HirningDemandParamsDetailsData?.customEndDate.toString()}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("customStartDate" in reportSubmissionAPIData) reqObj["customStartDate"] = reportSubmissionAPIData?.customStartDate.toString()}else if("customStartDate" in HirningDemandParamsDetailsData){reqObj["customStartDate"] = HirningDemandParamsDetailsData?.customStartDate.toString()}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}
      // "customEndDate": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customEndDate.toString() : HirningDemandParamsDetailsData?.customEndDate.toString() || "",
      // "customStartDate": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customStartDate.toString() : HirningDemandParamsDetailsData?.customStartDate.toString() || "",
      Object.keys(hiringDemandTabDetail.textFieldValues).map((item:any) => {
        if(hiringDemandTabDetail.textFieldValues[item].length > 0)reqObj[toCamelCaseAndAppend(item,"search")] = hiringDemandTabDetail.textFieldValues[item]
      })
      if(HirningDemandParamsDetailsCopy.state.cardType == "rightCard"){
        if(hiringDemandTabDetail.sortColumn.length > 0){reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn}else{reqObj["sortColumn"] = (tabValue === "1" ? "client_name" : tabValue === "2" ? "mandate_name" : "candidate_name")}
        if(hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection}else{reqObj["sortDirection"] = ""}
      }else{
        if(hiringDemandTabDetail.sortColumn.length > 0 && hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn}
        if(hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection.toUpperCase()}
      }
        try {
      const res: any = HirningDemandParamsDetailsCopy.state.cardType == "leftCard" ? await gethiringdemandClentMandatelistapi(reqObj) : await gethiringdemandlistapi(reqObj);
      // setCandidates(res?.data?.data);
      const fullRequestObj = res.data.data;
      const requestObj = res?.data
      const headerBasedObj = res.data.data.map((item:any) => {
        const loopList = Object.keys(item)
          .filter(key => res.data.headerData.includes(key))
          .reduce((obj:any, key:any) => {
              obj[key] = item[key]; // Add remaining keys to the new object
              return obj;
          }, {});
        return loopList      
      })
      requestObj.data = headerBasedObj
      setHiringDemandTabBasedObject((prevState:any) => ({
        ...prevState,
        [tabValue === "1" ? "client" : tabValue === "2" ? "mandate" : "candidate"]: {
          ...prevState[tabValue === "1" ? "client" : tabValue === "2" ? "mandate" : "candidate"],
          data: requestObj,
          requestData: fullRequestObj
        },
      }));
      setexportdatareqest(reqObj)

    } catch (error: any) {
      console.log(error);
    }
  };


  useEffect(() => {
    hiringListApi();
  }, [HirningDemandParamsDetails, reportSubmissionAPIData,hiringDemandTabDetail,tabValue]);

  const renderEmptySearchBox = () => {
    return (
      <Box className="empty-data-card">
        <Box>
          <NoResultFound />
        </Box>
        <Box className="empty-search-message">
          No data found
        </Box>
      </Box>
    )
  }

  const updateDefaultStatus = () => {
    setHiringDemandTabDetail({
      sortColumn: "",
      sortDirection: "",
      paginationCountSize: {
        inputPageSize: 10,
        pageSize: 1,
        inputPageNo: 1,
        totalRecords: 0,
      },
      textFieldValues: ""
    })
  }

  const currentState = HirningDemandParamsDetails?.state?.datahandle?.resultStage ? 
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'active' ? 'Active' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'inservice' ? 'In Service' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'unservice' ? 'Un Serviced' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onHoldXpheno' ? 'On Hold By Xpheno' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onHoldClient' ? 'On Hold By Client' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'lostOpportunity' ? 'Lost Opportunity' :
  HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onBoard' ? 'On Boards' :
  '':'';

  const currentTabState = HirningDemandParamsDetails?.state?.datahandle?.resultType === "Job Orders"
  ? "Job Orders"
  : HirningDemandParamsDetails?.state?.datahandle?.resultType === "Client"
  ? "Client"
  : HirningDemandParamsDetails?.state?.datahandle?.resultType === "Mandate"
  ? "Mandate"
:''
  const HiringHeader = `<Box>
                <p class="breadcrumbFilter">Hiring Demand - ${currentTabState} ${currentState} Profile (${HirningDemandParamsDetailsCopy?.state?.data?.count})</p>
                <Typography display='flex' gap={'5px'} class='dashboardDetailHeaderTitle' style="margin-top:-15px;margin-bottom:10px;cursor:pointer">
                    <span style="color:gray">Overview Dashboard > </span><span style="color:#0033a0">Hiring Demand - ${currentTabState}${currentState} Profile</span>
                </Typography>
              </Box>`
  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
        <Box>
          <Box sx={{ padding: '10px' }}>
            <Box sx={{ paddingX: '5px' }}><KeyboardBackspaceIcon style={{ color: '#002882', cursor: 'pointer' }} onClick={() => { navigate("/dashboard", { state: { dashboardfilterObj: HirningDemandParamsDetailsCopy?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetailsCopy?.state?.filterList, filterListLabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels, userFilterObj: HirningDemandParamsDetailsCopy?.state?.userFilterObj, clientDependedObj: HirningDemandParamsDetailsCopy?.state?.clientDependedObj } }) }} /></Box>
            <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={HiringHeader} downloadName={`${currentTabState} ${currentState} Profile`} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={"subScreen"} screenType={HirningDemandParamsDetailsCopy.state.cardType == "rightCard" ? "hiringdrill": "hiringdrillLeft"}/>
          </Box>          
          {HirningDemandParamsDetailsCopy.state.cardType == "rightCard" && <Box sx={{ width: '100%', typography: 'body1' }} className="hiringTabTableSect">
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hiringTabTableHead">
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab icon={tabValue == "1" ? <HiringDemandTabIcon1 /> : <HiringDemandTabGrey1 />} label={<div><h4>client</h4><p>{hiringDemandTabBasedObject.client.data.clientCount}</p></div>} value="1" onClick={() => {updateDefaultStatus()}}/>
                    <Tab icon={tabValue == "2" ? <HiringDemandTabIcon2 /> : <HiringDemandTabGrey2 />} label={<div><h4>Mandate</h4><p>{hiringDemandTabBasedObject.client.data.mandateCount}</p></div>} value="2" onClick={() => {updateDefaultStatus()}} />
                    <Tab icon={tabValue == "3" ? <HiringDemandTabIcon3 /> : <HiringDemandTabGrey3 />} label={<div><h4>Candidate</h4><p>{hiringDemandTabBasedObject.client.data.candidateCount}</p></div>} value="3" onClick={() => {updateDefaultStatus()}}/>
                </TabList>
              </Box>
              <TabPanel value="1">
                {Object.keys(hiringDemandTabBasedObject.client.data).length > 0 ? <HiringDemandTableSection candidates={hiringDemandTabBasedObject.client} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={"client"}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
              </TabPanel>
              <TabPanel value="2">
              {Object.keys(hiringDemandTabBasedObject.mandate.data).length > 0 ? <HiringDemandTableSection candidates={hiringDemandTabBasedObject.mandate} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={"mandate"}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
              </TabPanel>
              <TabPanel value="3">
              {Object.keys(hiringDemandTabBasedObject.candidate.data).length > 0 ? <HiringDemandTableSection candidates={hiringDemandTabBasedObject.candidate} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={"candidate"} candidateRedirectHandler={(candidateId:any) => candidateRedirectNavigator(candidateId)}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
              </TabPanel>
            </TabContext>
          </Box>}

          {HirningDemandParamsDetailsCopy.state.cardType == "leftCard" && <>
            {Object.keys(hiringDemandTabBasedObject[HirningDemandParamsDetails?.state?.tabSwitch == "1" ? "client" : "mandate"].data).length > 0 ? <HiringDemandTableSection candidates={hiringDemandTabBasedObject[HirningDemandParamsDetails?.state?.tabSwitch == "1" ? "client" : "mandate"]} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={HirningDemandParamsDetails?.state?.tabSwitch == "1" ? "client" : "mandate"} dateConvertion={true}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
          </>
          }
          
        </Box>
      </div>
    </div>
  )
}

export default HiringDemandCardDetail