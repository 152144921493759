import { 
    Autocomplete, Box, Button, FormControl, FormControlLabel, 
    FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, 
    Slider, Switch, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, TextField 
  } from '@mui/material';
  import React from 'react';
  import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
  import RangeSlider from '../Common/RangeSlider/RangeSlider';
  import NumberWithIncDec from '../Common/NumberWithIncDec';
  import "./ArtemisIntelligentSkill.scss";
import NumberIncrementComp from './NumberIncrementComp';
  
  const ArtemisIntelligentSkill = ({artemisIntelligentState, setArtemisIntelligentState}:any) => {
    const skillMarks = [
      { value: 0, label: '0' },
      { value: 20, label: '20' },
      { value: 40, label: '40' },
      { value: 60, label: '60' },
      { value: 80, label: '80' },
      { value: 100, label: '100' }
    ];

    const handleValueChange = (index: number, newValue: number,type:string) => {
      const artemisIntelligentStateCopy = JSON.parse(JSON.stringify(artemisIntelligentState));
      artemisIntelligentStateCopy[index][type] = newValue
      setArtemisIntelligentState(artemisIntelligentStateCopy);
      };
    return (
      <Grid className='container-wrapper'>
        <Box className="artemisIntelligentSkillSect" style={{ flexDirection: "column" }}>
          <TableContainer className="artemisIntelligentSkillTable" component={Paper} key={1}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "30%" }}>SKILL NAME</TableCell>
                  <TableCell style={{ width: "30%" }}>WEIGHT</TableCell>
                  <TableCell style={{ width: "40%" }}>SKILL VARIANCE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {artemisIntelligentState.map((item:any,index:number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <p>{item.name}</p>
                      </TableCell>  
                      <TableCell>
                      <FormControl fullWidth required className='artemisSkillNumberField'>
                          <NumberIncrementComp min={0} max={100} step={1} fieldvalue={item.weight} onChange={(e:any) => {handleValueChange(index,e,"weight")}} />
                              {/* <input onChange={(e:any) => {console.log("weight",e.target.value)}} type='number'/> */}
                          </FormControl>
                      </TableCell>
      
                      <TableCell>
                              <Slider
                                defaultValue={item.skillVariance ?? 0}
                                aria-label="Always visible"
                                min={0}
                                max={100}
                                value={item.skillVariance}
                                marks={skillMarks}
                                valueLabelDisplay="auto"
                                onChange={(e:any) => {handleValueChange(index,e.target.value,"skillVariance")}}
                              />
                      </TableCell>
                    </TableRow>
                  )
                })}                
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    );
  };
  
  export default ArtemisIntelligentSkill;
  