import { Container, Grid, Box, Typography, IconButton, Divider } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import React, { FC, useState, useEffect } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { Link, useNavigate } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInput } from './FormInput'
import styled from '@emotion/styled'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import './LoginForm.css'
import { ReactComponent as UserIcon } from '../../assets/icons/login-user.svg'
import { ReactComponent as UserPassword } from '../../assets/icons/login-password.svg'
import MainLogo from '../../assets/images/xpheno.svg'
import ArtemisLogo from '../../assets/images/Artemis.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { login } from '../../features/auth/AuthReducer'
import ILoginData from '../../types/Login'
import {authUserDetails, authLogoutUser} from "../../features/userDetail/UserDetailReducer"
import underMaintenanceImg from "../../assets/images/page_under_maintenance.png"

import { Slider } from './SliderComponent/Slider'
import { resetNotificationAll } from '../../features/notification/notificationReducer'
import { resetFilterPersistAll } from '../../features/filters/PersistFilter'
import { industryDropDownData } from '../../features/filters/FilterReducer'
// ? Styled React Route Dom Link Component
export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`
export const LeftContainer = styled(Box)`
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

//  Login Schema with Zod
const loginSchema = object({
  username: string().min(1, 'Email is required').email('Email is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(7, 'Password must be more than 7 characters')
    .max(32, 'Password must be less than 32 characters'),
})

//  Infer the Schema to get the TS Type
type ILogin = TypeOf<typeof loginSchema>

export const LoginForm = ({underMaintenanceStatus,underMaintenanceStatusMessage}:any) => {
  const dispatch = useAppDispatch()
  dispatch(authLogoutUser())
  const navigation = useNavigate();

  const error = useAppSelector((state) => state.auth.error)
  // Default Values
  const defaultValues: ILogin = {
    username: '',
    password: '',
  }

  const [showPassword, setShowPassword] = useState(false)
  const [hasError, setHasError] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  // toast open

  // toast handleclose
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setHasError(false)
  }

  //  The object returned from useForm Hook
  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  })

  //  Submit Handler
  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    const logindata: ILoginData = {
      ...values,
      grantType: 'password',
    }
    console.log('data', logindata)
    dispatch(login(logindata))
      .then(unwrapResult)
      .then(() => {
        dispatch(resetNotificationAll())
        dispatch(resetFilterPersistAll())
        dispatch(industryDropDownData({industryOptionData: []}))
      })
      .catch((error:any) => {        
        if(error == "Your Password has Been Expired Please Reset Your Password"){
          setTimeout(() => {
            navigation("/forgot-password", {state:{loginState:"passwordExpire",type:"forgot"}})
          },4000)          
        }
        setHasError(true)
      })
  }

  useEffect(() => {
    const interval = setInterval(function () {
      if(document.querySelector(".introjs-overlay") != null){
        document.querySelector(".introjs-overlay")?.remove();
        document.querySelector(".introjs-helperLayer")?.remove();
        document.querySelector(".introjs-tooltipReferenceLayer")?.remove()
        clearInterval(interval);
      }
    }, 1000);
  },[])

  const forgotPasswordHandler = () => {
    navigation('/forgot-password',{state:{loginState:"",type:"forgot"}})
  }
  //  JSX to be rendered
  return (
    <Container maxWidth={false}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className='login-screens-container-grid'
      >
        <Grid item className='login-form-container-grid'>
          <FormProvider {...methods}>
            <Grid container className='login-screens-container-sub-grid'>
              <Grid
                item
                container
                sx={{
                  marginInline: 'auto',
                }}
              >
                {/* slidercomponent wrapper */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    paddingLeft: { sm: '3rem' },
                    paddingRight: { sm: '3rem' },
                    display: { sm: 'block', xs: 'none', md: 'block' },
                  }}
                >
                  <Slider />
                </Grid>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mr: '-1px', display: { xs: 'none', md: 'block' } }}
                />
                {/* Formcomponent wrapper */}
                <Grid
                  className={`${underMaintenanceStatus ? "underMaintenanceBlk" : ""}`}
                  item
                  xs={12}
                  sm={6}
                  sx={{ paddingLeft: { sm: '3rem' }, paddingRight: { sm: '3rem' } }}
                >
                  <Box></Box>
                  <Box
                    className={`${underMaintenanceStatus ? "underMaintenanceSect" : ""}`}
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                      mb: '1rem',
                    }}
                  >
                    <Box className="loginRightTopLogoSect">
                      <Box component='img' alt='Xpheno' src={MainLogo} />
                      <Box component='img' alt='Xpheno' src={ArtemisLogo} />                    
                    </Box>
                    {!underMaintenanceStatus && <Typography className='login-screen-welcome-text'>Hey there!</Typography>}
                    <Typography className='login-screen-welcome-note'>
                        {underMaintenanceStatus ? underMaintenanceStatusMessage : "Please login to your account using your registered Email ID"}
                    </Typography>
                  </Box>
                  {underMaintenanceStatus && <img src={underMaintenanceImg} />}
                  {!underMaintenanceStatus && <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                    }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                  >
                    <Typography
                      variant='h6'
                      component='p'
                      sx={{
                        paddingLeft: { lg: '3rem' },
                        textAlign: 'center',
                      }}
                    ></Typography>

                    <FormInput
                      label='Username'
                      type='email'
                      name='username'
                      focused
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <UserIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormInput
                      type={showPassword ? 'text' : 'password'}
                      label='Password'
                      name='password'
                      required
                      focused
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <UserPassword />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              className='eye-icon'
                            >
                              {showPassword ? (
                                <Visibility className='eye-icon' />
                              ) : (
                                <VisibilityOff className='eye-icon' />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box className="loginForgotPassword">
                      <Typography className='forgot-password-link' onClick={() => forgotPasswordHandler()}>Forgot password?</Typography>
                    </Box>

                    <LoadingButton
                      loading={false}
                      type='submit'
                      variant='contained'
                      className='login-button'
                    >
                      Log in
                    </LoadingButton>
                  </Box>
                  }
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <Box>
        <Snackbar
          open={hasError}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  )
}
