import {
    Datepicker,
    formatDate,
    Input,
    // MbscDatepickerChangeEvent,
    // MbscDateType,
    // MbscSelectChangeEvent,
    options,
    Page,
    Popup,
    Select,
    setOptions,
  } from '@mobiscroll/react';
  import { FC, useCallback, useEffect, useState } from 'react';
  import './DateRangePickerSelect.css'
import { Box, Button, IconButton, InputAdornment, Popover, TextField, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/calendar-2.svg'
import moment from 'moment';

  setOptions({
    theme: 'ios',
    themeVariant: 'light'
  });

  interface DateRangePickerSelectProps {
    onDateChange: (date:any,selectedVal:any) => void;
    value:any;
    currentYear?:any;
    type?:string
  }
  
  const startDate = '2024-08-28';
  const endDate = '2024-09-03';
  const now = new Date();
  const day = now.getDay();
  const monday = now.getDate() - day + (day === 0 ? -6 : 1);
  
  const respSelect = {
    xsmall: {
      touchUi: true,
    },
    small: {
      touchUi: false,
    },
  };
  
  const myData = [
    {
      value: 'custom',
      text: 'Custom',
    },
    {
      value: 'today',
      text: 'Today',
    },
    {
      value: 'yesterday',
      text: 'Yesterday',
    },
    {
        value: 'currentweek',
        text: 'Current Week',
    },
    {
      value: 'previousweek',
      text: 'Previous week',
    },
    {
      value: 'currentmonth',
      text: 'Current Month',
    },
    {
        value: 'currentquater',
        text: 'current-quarter',
    },
    {
        value: 'currentyear',
        text: 'Current Year',
    },
    {
        value: 'previousmonth',
        text: 'Previous Month',
    },
    {
        value: 'previousquater',
        text: 'Previous Quarter',
    }
    // {
    //   value: 'last-month',
    //   text: 'Last month',
    // },
    // {
    //   value: 'last-7-days',
    //   text: 'Last 7 days',
    // },
    // {
    //   value: 'last-30-days',
    //   text: 'Last 30 days',
    // },
    

  ];
  
  const DateRangePickerSelect: FC<DateRangePickerSelectProps> = ({ onDateChange, value, currentYear,type}) => {
    const [isDattepickerOpen, setisDattepickerOpen] = useState<boolean>(false);
    const [start, startRef] = useState<Input | null>(null);
    const [end, endRef] = useState<Input | null>(null);
    const [selected, setSelected] = useState<string>('custom');
    const [selectedDate, setSelectedDate] = useState<any>([value != null ? value[0] : null , value != null ? value[1] : null]);
    const [inputValue, setInputValue] = useState<string>('');
    const datePickerClass = type == "appTrends" ? "date-filter-input" : "date-filter-input-role"
    const respPopup = {
      xsmall: {
        display: 'bottom',
        touchUi: true,
        buttons: [
          {
            text: 'Apply',
            handler: () => {
              const date = selectedDate;
  
              changeInputValue(date[0] as string, (date[1] as string) || (date[0] as string));
              setisDattepickerOpen(false);
              onDateChange(date,"");
            },
          },
          'cancel',
        ],
      },
      custom: {
        breakpoint: 559,
        buttons: [],
        display: 'anchored',
        anchor: document.querySelector(`.${datePickerClass}`),
        anchorAlign: 'start',
        touchUi: false,
        scrollLock: false,
        showArrow: false,
        maxWidth: 920,
        width: 500
      },
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = isDattepickerOpen ? 'simple-popover' : undefined;
  
    const inputClick = () => {
      setisDattepickerOpen(true);
      handleClick
    }
  
    

    const changeInputValue = useCallback((start: string, end: string) => {
      const locale = options.locale || {};
      const dateFormat = locale.dateFormat || 'DD/MM/YYYY';
  
    //   setInputValue(formatDate(dateFormat, new Date(start)) + ' - ' + formatDate(dateFormat, new Date(end)));
    // setInputValue(`${start} - ${end}`)

    const formatDate = (date: string) => {
        return date.slice(0, 10);
      };
    const formattedStart = formatDate(start);
    const formattedEnd = formatDate(end);
    setInputValue(`${formattedStart} - ${formattedEnd}`);

}, []);
  
  
    // const cancelClick = useCallback(() => {
    //   setisDattepickerOpen(false);
    //   inputClick
    // }, []);

   

      
  
    // const onDateChange = useCallback((ev: MbscDatepickerChangeEvent) => {
    //   const date = ev.value as MbscDateType[];
  
    //   console.log("Dateiiis",date)
    //   setDisabledInput(false);
    //   setSelected('custom');
    //   setSelectedDate(date);
    // }, []);

    // const onDateChangeHandler = useCallback((ev: MbscDatepickerChangeEvent) => {
    //     const date = ev.value as MbscDateType[];
    //     setDisabledInput(false);
    //     setSelected('custom');
    //     setSelectedDate(date);
    //     onDateChange?.(date[0] as string, (date[1] as string) || (date[0] as string));
    //   }, [onDateChange]);

    const onDateChangeHandler =(ev: any) => {
        let date:any = ev.value as any[];
        
          if(date.length > 0){
            if(date[1] ==  null) date[1] = date[0]
          }else{
            date = [];  
          }
        
        // const selecteddatetype = myData?.filter((item:any)=>item.value == activeShortcut).map((item:any)=>item?.value)
        setSelected('custom');
        setSelectedDate(date);
        if(date.length > 0 && (date[0] != date[1]))onDateChange(date,"");
      }
  
    const onClose = useCallback(() => {
      setisDattepickerOpen(false);
    }, []);
  
    useEffect(() => {
        if(value != null && value != "")changeInputValue(value[0], value[1]);
    },[value]);
    return (
        <div>
      

      <Page>
      <Tooltip title={inputValue.length == 0 ? "select date":inputValue}><TextField onClick={inputClick} className={datePickerClass} defaultValue={inputValue} placeholder={`${inputValue.length == 0 ? "Date":""}`} value={inputValue} style={{fontSize:'13px'}} InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <IconButton>
                                  <CalenderIcon aria-describedby={id}/>
                                </IconButton>
                              </InputAdornment>
                            ),
                            style:{color:'#B3B5B7',height:'38px'}
                          }}/>
                          </Tooltip>
        <Popup isOpen={isDattepickerOpen} onClose={onClose} responsive={respPopup} cssClass={`datePickerPopup`}>
          <div className="custom-date-filter-container">
            <div className="custom-date-filter-header">
              <div className="mbsc-col-sm-4 mbsc-push-sm-8 demo-date-filtering-dates" style={{display:'flex'}}>
                <div className="demo-date-filtering-inputs">
                  <div hidden={true}>
                    {/* <Select
                    data={myData}
                    label="Date range"
                    inputProps={{
                      labelStyle: 'stacked',
                      inputStyle: 'box',
                    }}
                    responsive={respSelect}
                    touchUi={true}
                    value={selected}
                    onChange={onChange}
                  /> */}
                  </div>
                 
                    <Box className="date-range-fields" style={{marginTop: '0px'}}>

                    <TextField
                        size="small"
                        name="fromdate"
                        // style={{ width: '100%', color:'#000',fontSize:'12px'}}
                        value={moment(selectedDate[0]).format('MMM D, YYYY')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><span style={{fontSize:'12px', fontWeight:'400'}}>From:</span></InputAdornment>,
                          sx: {
                              color: '#000', // Text color
                              fontSize: '12px', // Font size
                              '&.Mui-disabled': {
                                  color: '#000', // Color for disabled state
                              },
                          },
                      }}
                      sx={{
                          width: '100%',
                      }}
                        disabled={true}
                    />

                    <TextField
                        size="small"
                        name="todate"
                        // style={{ width: '100%', color:'#000',fontSize:'12px'}}
                        value={moment(selectedDate[1]).format('MMM D, YYYY')}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">To:</InputAdornment>,
                        // }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><span style={{fontSize:'12px', fontWeight:'400'}}>To:</span></InputAdornment>,
                          sx: {
                              color: '#000', // Text color
                              fontSize: '12px', // Font size
                              '&.Mui-disabled': {
                                  color: '#000', // Color for disabled state
                              },
                          },
                      }}
                      sx={{
                          width: '100%',
                      }}
                        disabled={true}
                    />

                    </Box>
                </div>
              </div>
              <Box sx={{paddingX:'15px'}}>
              <div className="mbsc-col-sm-8 mbsc-pull-sm-4 daterangecontrolui">
                <Datepicker className="daterangecontrolui"
                  controls={['calendar']}
                  select={`preset-range`}
                  display="inline"
                  showRangeLabels={false}
                  pages={2}
                  startInput={start}
                  endInput={end}
                  returnFormat="iso8601"
                  showOnClick={false}
                  showOnFocus={false}
                  value={selectedDate}
                  selectSize = {7}
                  min={moment(`${"april"} ${currentYear}`, "MMMM YYYY").startOf('month').format('YYYY-MM-DD')}
                  max={moment(`${"march"} ${parseInt(currentYear) + 1}`, "MMMM YYYY").endOf('month').format('YYYY-MM-DD')}
                //   onChange={onDateChange}
                onChange={onDateChangeHandler}
                // touchUi={true}
                ></Datepicker>
                
              </div>
              </Box>

              {/* <div className="demo-date-filtering-desktop-buttons mbsc-button-group-justified" style={{display:'flex',justifyContent:'end'}}>
                  <Button className="apply-button" onClick={applyClick}>
                    Apply
                  </Button>
                  <Button className="cancel-button" onClick={cancelClick}>
                    Cancel
                  </Button>
                </div> */}
            </div>
          </div>
        </Popup>
      </Page>
      </div>
    );
  };
  
  export default DateRangePickerSelect;