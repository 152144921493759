import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, ListItemText, Menu, MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Slider, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as MenuIcon1 } from "../../assets/icons/candidate/candidate-sidebar-menu1.svg";
import { ReactComponent as MenuIcon2 } from "../../assets/icons/candidate/candidate-sidebar-menu2.svg";
import { ReactComponent as MenuIcon3 } from "../../assets/icons/candidate/candidate-sidebar-menu3.svg";
import { ReactComponent as MenuIcon4 } from "../../assets/icons/candidate/candidate-sidebar-menu4.svg";
import _ from "lodash";
import CloseIcon from '@mui/icons-material/Close';
import { string } from "zod";
import { renderSelectOptions } from "../../utils/design-utils";
import { resetFilterPreloadData, setFilterPreloadData, setFilterValues } from "../../features/filters/FilterReducer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CheckBoxTwoTone } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getCandidateFilterDropdowns } from "../../services/CandidateService";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from 'react-router-dom';
import { setFilterPersist, setPersistFilter } from "../../features/filters/PersistFilter";
import { Loading } from "../../pages/Loading";

interface SearchForm {
    profileType?: string;
    location?: string;
    preferredLocation?: string;
    primarySkills?: string[];
    secondarySkills?: string[];
    education?: string;
    company?: string;
    designation?: string;
    salaryRange?: Array<number> | [];
    experience?: Array<number> | [];
    lastActivity?: number;
}

let previousProfileType:string | undefined = '';

const VISIBLE = 1;
const HIDDEN = 0;

interface FieldConfig {
    name: string;
    label: string;
    multiple?: boolean;
}

const initialValues: SearchForm = {
    profileType: 'All',
    location: '',
    preferredLocation: '',
    primarySkills: [],
    secondarySkills:[],
    education: '',
    company: '',
    designation: '',
    salaryRange: [0, 100],
    experience: [0, 30],
    lastActivity: -1,
}

const CandidateSidebarSearch = ({showFilterCallback, searchFilter, setSearchFilter, tabVal, tabFilters, setTabFilters,searchListData,selectedMandate,selectedKeywords,advancedFilters,setAdvancedFilters,setApplyFilterStatus,applyFilterStatus,closeFilterTab}:any) => {
    const location = useLocation()
    // const preFilter = location?.state?.searchFilter
    const outerKey = `advancedFilter${tabVal}`;
    const defaultValues: SearchForm = {
        profileType: 'All',
        location: '',
        preferredLocation: '',
        primarySkills: [],
        secondarySkills: [],
        education: '',
        company: '',
        designation: '',
        salaryRange: [0, 100],
        experience: [0, 30],
        lastActivity: -1,
    }

    const [filterSectionVisibility, setFilterSectionVisibility] = useState(0); // -1 = unset, 0 = invisible, 1 = visible

    const dispatch = useAppDispatch()

    const filterPreloadData = useAppSelector((state) => state.filtersReducer.filterPreloadData)
    const persistanceDataChangeStatus = useAppSelector((state) => state?.PersistFilterReducer?.filterPersistance)
    const filters = useAppSelector((state) => state.filtersReducer.filters)

    const persistFilter = useAppSelector((state)=> state.PersistFilterReducer.persistFilter)

    const { handleSubmit, control, setValue, reset, getValues, watch } = useForm<SearchForm>({ defaultValues })

    useEffect(()=>{
        const formData = (filters?.advFilters?.[`advfilTab${tabVal}`])
        reset(formData)
      },[tabVal, filterPreloadData])

    const showFilterUI = () => {
        setFilterSectionVisibility(VISIBLE)
        if(persistanceDataChangeStatus[`tab${tabVal}`] == "changed"){
            setApplyFilterStatus({
                status: "search",
              })
        }
        const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));
        persistanceData[`tab${tabVal}`] = "unchanged"
        dispatch(setFilterPersist(persistanceData))
    }

    const closeFilterUI = () => {
        setFilterSectionVisibility(HIDDEN)
    }
    useEffect(() => {
        closeFilterUI()
    },[tabVal,closeFilterTab])

    // useEffect(() => {
    //     setFilterSectionVisibility(HIDDEN)
    // },[persistanceDataChangeStatus[`tab${tabVal}`]])

    const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("lg"));


    const filterSubmitHandler: SubmitHandler<SearchForm> = (filterValues: SearchForm) => {
        const formData = tabFilters[`tab${tabVal}`]
        const finalData = {...filters?.advFilters?.[`advfilTab${tabVal}`], ...filterValues }
        setApplyFilterStatus({
            status: "filterSearch",
        })
        setTabFilters({...tabFilters, [`tab${tabVal}`] : finalData})
    // dispatch(setFilterValues({...filters,advFilters:{...filters?.advFilters,[`advfilTab${tabVal}`]:finalData}})) 
        dispatch(setPersistFilter({...persistFilter,persistAdvancedFilters:{...persistFilter?.persistAdvancedFilters,[`advancedFilters${tabVal}`]:finalData}})) 
      if(isSmallScreen) {
        closeFilterUI();
      }    
    }

    const loadDefaultFilterPreloadData = () => {
        dispatch(resetFilterPreloadData({
            lastActivity: [
                {
                    label: 'Last 3 Days',
                    value: 3,
                },
                {
                    label: 'Last 7 days',
                    value: 7,
                },
                {
                    label: 'Last Month',
                    value: 30,
                },
                {
                    label: 'Last 3 Months',
                    value: 90,
                },
                                {
                    label: 'All Time',
                    value: -1,
                },
            ],
        }));
    }

    const watchFields = watch([ 'profileType']);
    const getTabKeyValue = (tabValue:number) => {
        return tabValue == 0 ? "keyword" : tabValue == 1 ? "sourcing" : tabValue == 2 ? "rpa" : tabValue == 3 ? "air" : ""
    }

    const loadFilterDropdownPreload = () => {
      const RPAModule = searchFilter?.module==="rpa"
        const mandateIdValue =persistFilter?.persistMandate?selectedMandate[`manTab${tabVal}`]?.value :"";
        const locationIdValue =persistFilter?.persistLocation? persistFilter?.persistLocation[`locTab${tabVal}`]:"";
        const searchQueryID =persistFilter?.persistSearchQuery?persistFilter?.persistSearchQuery:filters?.searchQueryId&&filters?.searchQueryId!=''?filters?.searchQueryId:searchFilter?.searchQueryId
        const keyword =selectedKeywords[`keyTab${tabVal}`]?selectedKeywords[`keyTab${tabVal}`]:"";
        if((!RPAModule && !persistFilter?.persistMandate)|| !(tabVal!=0&&persistFilter?.persistLocation&&persistFilter?.persistLocation?.[`locTab${tabVal}`])) {
            loadDefaultFilterPreloadData()
            return
        };
        const profileType = getValues('profileType');
        if(!RPAModule || (mandateIdValue || locationIdValue)){
            getCandidateFilterDropdowns(mandateIdValue, profileType, keyword, getTabKeyValue(tabVal),locationIdValue,searchQueryID).then(async(res) => {
                const data =await  _.get(res, 'data');
              if((tabVal===2 && data?.searchQueryBuilder)){
                const modifiedData =await {...data,[`searchOptions2`]:data?.searchQueryBuilder}
                if(modifiedData) {
                    dispatch(setFilterPreloadData({...modifiedData,dataLoad:true}))
                // dispatch(setFilterValues({...filters,noData:false}))
                }
              }
              else if(data!="no records found to fetch filter values"){
                // dispatch(setFilterPreloadData({...filterPreloadData,data,dataLoad:true}))
                dispatch(setFilterPreloadData({...data,[`searchOptions2`]:filterPreloadData?.searchOptions2,dataLoad:true}))
                // dispatch(setFilterValues({...filters,noData:false}))
              }
              else{
                dispatch(setFilterPreloadData({...filterPreloadData,dataLoad:true}))
              }
            }).catch((e:any) => {});
        }
    }

    useEffect(() => {
        // loadDefaultFilterPreloadData();
        showFilterCallback(showFilterUI);
    }, [])

    useEffect(() => {
        const profileType = getValues('profileType');
        if(previousProfileType!='' && profileType != previousProfileType) {
            reset({
                ...filters?.advFilters?.[`advfilTab${tabVal}`],
                profileType: profileType,
                lastActivity: getValues('lastActivity'),
            })
        }
        previousProfileType = profileType;
    }, [watchFields])

    useEffect(() => {
        loadFilterDropdownPreload()
    }, [tabVal,persistFilter?.persistLocation,filters.keyword, filters.mLocationId])
    const resetAllFilters = async(e:any) => {
        reset(initialValues);
        // setAdvancedFilters({...advancedFilters,[`advancedFilter0`]:null})
        dispatch(setPersistFilter({...persistFilter,persistAdvancedFilters:{...persistFilter?.persistAdvancedFilters,[`advancedFilter${tabVal}`]:null}}))
        const formData = tabFilters[`tab${tabVal}`]
        const finalData = {...formData, ...initialValues}
        setTabFilters({...tabFilters, [`tab${tabVal}`] : finalData})
       

        if(isSmallScreen) {
            closeFilterUI();
        }
    }


    const renderSidebarMenus = () => {
        const sidebarMenus = [{
            icon: MenuIcon1,
            onClick: showFilterUI,
            title: "Advance Filter"
        }, {
            icon: MenuIcon2,
        }, {
            icon: MenuIcon3,
        }, {
            icon: MenuIcon4,
        }];
        const sidebarMenuHtml = _.map(sidebarMenus, (value:any, index:number) => {
            const Icon:any = _.get(value, `icon`)
            const onClickMenuFunction:any = _.get(value, `onClick`, () => {})
            return (
                <Box className={"sidebar-menu-box " + ((index === 0) ? 'active' : '')} onClick={() => {
                        onClickMenuFunction()               
                }}>
                    <Tooltip title={value.title}>
                        <Box className={"sidebar-menu-icon"}>
                            {Icon && <Icon />}
                        </Box>
                    </Tooltip>
                </Box>
            )
        });
        return (
            <>
                {sidebarMenuHtml}
            </>
        )
    }

    const renderFilterHeader = () => {
        return (
            <Box className="candidate-sidebar-header-section">
                <Box className="candidate-sidebar-header-title">
                    Advanced Filter
                </Box>
                <CloseIcon style={{cursor:"pointer"}} className="candidate-sidebar-close-icon" onClick={closeFilterUI} />
            </Box>
        )
    }

    const renderFilterInputSectionTitle = (title:string) => {
        return (
            <Box className="input-section-title-container">
                <Box className="input-section-title">{title}</Box>
                <Box className="input-section-line"></Box>
            </Box>
        )
    }

    const renderFilterInputSection = (title:string, fieldConfigList: FieldConfig[]) => {
        
        if(Object.keys(advancedFilters)?.length){
            const filterInputsList:any[] = [];
            _.forEach(fieldConfigList, (fieldConfig: FieldConfig, index:number) => {
                const { name } = fieldConfig;
                const outerKey = `advancedFilter${tabVal}`;
                const innerKey = name;
                if (advancedFilters[outerKey]) {
                    if (advancedFilters[outerKey][innerKey] !== undefined) {
                        const value = advancedFilters[outerKey][innerKey];
                    }
                }
                // if(advancedFilters?.[`advanceFilter${tabVal}`][name]){
                //     console.log(advancedFilters?.[`advanceFilter${tabVal}`][name],'advancedFilters[`advanceFilter${tabVal}`]')
                // }
                
                // if(valueList?.length > 0) {
                    filterInputsList.push(renderFilterDropdown(fieldConfig, index));
                // }
            });
    
            if(filterInputsList?.length === 0) {
                return null;
            }
            return (
                <>
                    {renderFilterInputSectionTitle(title)}
                    {filterInputsList}
                </>
            )
        }
       
    }

    const renderInputProfileType = () => {
        return (
        //   <FormControl>
        //     <FormLabel className='sidebar-filter-field-label'>Profile Type *</FormLabel>
        //     <Controller
        //       control={control}
        //       name='profileType'
        //       render={({ field:{onChange,value} }) => (
        //         <Select
        //           labelId='demo-multiple-checkbox-label'
        //           id='demo-multiple-checkbox'
        //         //   multiple
        //           value={value}
        //           onChange={(e)=>{console.log(e);onChange(e)}}
        //           input={<OutlinedInput label='Tag' />}
        //           renderValue={(selected) => selected.join(', ')}
        //           MenuProps={MenuProps}
        //         >
        //              <MenuItem value={"Basic"}>{"Basic"}</MenuItem>
        //              <MenuItem value={"Complete"}>{"Complete"}</MenuItem>
        //         </Select>
        //       )}
        //     />
        //   </FormControl>
        <Box className={"sidebar-filter-container " + 'last-active-wrapper'}>
                <FormControl fullWidth>
                    <InputLabel className="sidebar-filter-dropdown-label" >{"Profile Type"}</InputLabel>
                    <Controller
                        control={control}
                        name={"profileType"}
                        render={({ field }:any) => (
                            <Select
                                label={"Profile Type"}
                                className="sidebar-filter-dropdown testing"
                                {...field}
                                defaultValue={persistFilter?.[`advancedFilters${tabVal}`]?.profileType??"All"}
                                // endAdornment={
                                //     <IconButton 
                                //         sx={{visibility: "visible"}} 
                                //         onClick={() => setValue("profileType",undefined)}>
                                //             <ClearIcon sx={{fontSize: '1rem', mr: '0.5rem'}} />
                                //     </IconButton>}
                            >
                                 <MenuItem className="responsive-list-menu" value={"All"}>{"All"}</MenuItem>
                                 <MenuItem className="responsive-list-menu" value={"basic"}>{"Basic"}</MenuItem>
                                 <MenuItem className="responsive-list-menu" value={"complete"}>{"Complete"}</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Box>
        )
    }

    const renderFilterDropdown = ( { name, label, multiple = false, wrapperClassName = ''} : { name: any, label: string, multiple?: boolean, wrapperClassName?: string}, index = 0) => {
        const value = multiple ? [] : '';
        let valueList;
        const handleChange = () => { };
        const optionName = name;
        if(name!="lastActivity"){
            // valueList = _.get(advancedFilters, optionName, []);
            const outerKey = `advancedFilter${tabVal}`;
            const innerKey = name;
            if (advancedFilters[outerKey]) {
                if (advancedFilters[outerKey][innerKey]!=undefined && advancedFilters[outerKey][innerKey]!=null) {
                    const value = advancedFilters[outerKey][innerKey];
                    valueList = value
                }
                else{
                    return
                }
            }
        }
        else if(name==="lastActivity"){
            valueList = _.get(filterPreloadData, name, []);
        }
        
        
        const dropdownOptions = _.map(valueList, (value:any) => {   
            if(typeof value === "string") {
                return {
                    label: value,
                    value: value,
                }
            } else {
                return value;
            }

        });
        return (
            <Box className={"sidebar-filter-container " + wrapperClassName} key={index}>
                <FormControl fullWidth className="advanceFilterCandidate">
                    {multiple  && <InputLabel className="sidebar-filter-dropdown-label" id={`${name}-label`}>{label}</InputLabel>}
                    {!multiple ? <Controller
                        control={control}
                        name={name}
                        render={({field : {onChange, value, onBlur}})=>(
                            <Autocomplete
                                size={"small"} 
                                  options={dropdownOptions}
                                  value={dropdownOptions.find((option:any) => option.value === value) || null}
                                  onChange={(event, value:any) => {
                                        onChange(value?.value || null)
                                  }}
                                  renderInput={(params) => <TextField   {...params} label={label} />}                                  
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.value} style={{ maxHeight: '200px',fontSize:"11px" }}>
                                        {option.label}
                                      </li>
                                    )
                                  }}
                                />                            
                          )}
                    />:
                    <Controller
                        control={control}
                        name={name}
                        render={({ field }) => {
                            return(
                                <Select
                                    labelId={`${name}-label`}
                                    label={label}
                                    className="sidebar-filter-dropdown"
                                    multiple
                                    {...field}
                                    value={field.value??[]}
                                    disabled={dropdownOptions?.length === 0}
                                    endAdornment={
                                        <IconButton 
                                            sx={{visibility: ((multiple ? JSON.stringify(field.value) !== "[]" : field.value)) ? "visible": "hidden"}} 
                                            onClick={() => setValue(name, multiple ? [] : '')}>
                                                <ClearIcon sx={{fontSize: '1rem', mr: '0.5rem'}} />
                                        </IconButton>}
                                >
                                    {renderSelectOptions(dropdownOptions)}
                                </Select>
                        )}}
                    />}
                </FormControl>
            </Box>
        )
    }

    const renderCurrencyDropdowns = () => {
        return (
            <>
                <Box>
                    Select Unit
                </Box>
                <Select
                    labelId={`select-unit-label`}
                    value={'INR'}
                    label={'INR'}
                    className="select-unit-dropdown"
                    variant="outlined"
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                >
                    {renderSelectOptions([
                        {
                            label: 'INR',
                            value: 'INR',
                        },
                    ])}
                </Select>
            </>
        )
    }

    const renderRangeInput = (
        { name, minFieldName, maxFieldName, rangeUnit, label, unitTypeUI = () => {} } : 
        { name: any, minFieldName: string, maxFieldName:string, rangeUnit:string, label:string, unitTypeUI?: any  }) => {
        const rangeValueText = (value: number) => {
            return `${value} ${rangeUnit}`;
        }

        const minRangeInput = _.get(advancedFilters, minFieldName, -1);
        const maxRangeInput = _.get(advancedFilters, maxFieldName, -1);

        if(minRangeInput === -1 || maxRangeInput === -1) return null;

        return (
            <FormControl 
                className="range-form-control"
                fullWidth
            >
                <Box className="range-label">
                    {label}
                </Box>
                <Box className="select-unit-type-container">
                    {unitTypeUI()}
                </Box>
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <Slider
                            min={minRangeInput}
                            max={maxRangeInput}
                            valueLabelDisplay="on"
                            getAriaValueText={rangeValueText}
                            valueLabelFormat={rangeValueText}
                            {...field}
                        />
                    )}
                />
        </FormControl>
        )
    }

    const renderExperienceRange = () => {
        // return null;
        return (
            <Box>
                {renderRangeInput({
                    name: 'experience',
                    label: 'Experience',
                    minFieldName: 'minExperience',
                    maxFieldName: 'maxExperience',
                    rangeUnit: 'Years',
                })}
            </Box>
        );
    }

    const renderSalaryRange = () => {
        return (
            <Box>
                {renderRangeInput({
                    name: 'salaryRange',
                    label: 'Salary Range',
                    minFieldName: 'minSalary',
                    maxFieldName: 'maxSalary',
                    rangeUnit: 'Lakhs',
                    unitTypeUI: renderCurrencyDropdowns,
                })}
            </Box>
        ) 
    }

    const renderFilterBody = () => {
        const outerKey = `advancedFilter${tabVal}`;
        return (
            <Box className="candidate-sidebar-body-section">
                {renderFilterInputSectionTitle('Profile Details')}
                {renderInputProfileType()}
                {
                    advancedFilters[outerKey] != null && (
                        <>
                        {
                           advancedFilters[outerKey]?.location !=null || advancedFilters[outerKey]?.preferredLocation !=null ?(
                           <>
                            {renderFilterInputSection('Location', [
                                { name: "location", label: "Location" },
                                { name: "preferredLocation", label: "Preferred Location" },
                            ])}
                            </>
                           ) :<></>
                        }
                             {
                           advancedFilters[outerKey]?.primarySkills !=null || advancedFilters[outerKey]?.secondarySkills !=null || advancedFilters[outerKey]?.education !=null  ?(
                           <>
                            {renderFilterInputSection('Skills', [
                                { name: "primarySkills", label: "Primary Skills", multiple: true },
                                { name: "secondarySkills", label: "Secondary Skills", multiple: true },
                                { name: "education", label: "Education" }
                            ])}
                           </>
                           ):<></>}
                           {
                           advancedFilters[outerKey]?.company !=null || advancedFilters[outerKey]?.designation !=null?(
                           <>
                            {renderFilterInputSection('Organization', [
                                { name: "company", label: "Company" },
                                { name: "designation", label: "Designation" },
                            ])}
                           </>
                           ):<></>}
                           
                            {
                                searchListData?.length > 0 && (
                                    <>
                                        {renderSalaryRange()}
                                        {renderExperienceRange()}
                                    </>
                                )
                            }
                        </>
                    )
                }
                {renderFilterDropdown({ name: "lastActivity", label: "Last Activity", wrapperClassName: 'last-active-wrapper' })}
            </Box>
        )
    }

    const renderFilterFooter = () => {
        return (
            <Box className="candidate-sidebar-footer-section">
                <Button 
                     onClick={resetAllFilters}
                     className="candidate-sidebar-footer-button reset-button">
                        Reset
                </Button>
                <Button type="submit" className="candidate-sidebar-footer-button apply-button">Apply</Button>
            </Box>
        )
    }

    const renderFilterForm = () => {
        return (
            <>
            
            <Box 
                component={'form'}
                onSubmit={handleSubmit(filterSubmitHandler)}
                >
                    {renderFilterHeader()}
                    {applyFilterStatus?.status != "search" ? 
                    <>
                    {renderFilterBody()}
                    {renderFilterFooter()}
                    </>
                    :<Box className="candiFilterLoader"><Loading /><p>Candidate Filter data loading</p></Box>}
            </Box>
            </>
        )
    }


    let filterFormClass = isSmallScreen ? "candidate-sidebar-filter-form-mobile" : "candidate-sidebar-filter-form"

    if(filterSectionVisibility === VISIBLE) {
        filterFormClass += " show-form"
    } else if(filterSectionVisibility === HIDDEN) {
        filterFormClass += " hide-form"
    }
    return (
        <>
            <Box 
                className="candidate-section-box candidate-sidebar-section-box"
                sx={{
                    marginLeft: {xs: '0', lg: '1.25rem'},
                }}
                >
                <Box 
                    className={filterFormClass}
                    sx={{
                        display: {xs: 'none', lg: 'block'},
                    }}
                >
                    {renderFilterForm()}
                </Box>
                <Box className="candidate-sidebar-filter-menu"
                    sx={{
                        display: {xs: 'none', lg: 'flex'},
                    }}>
                    {renderSidebarMenus()}
                </Box>
            </Box>
        </>
    )
}

export default CandidateSidebarSearch;