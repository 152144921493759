import { Avatar, Box, Button, Checkbox, CircularProgress, CircularProgressProps, FormControlLabel, FormGroup, Popover, Typography } from "@mui/material";

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Loading } from "../../pages/Loading";
import { PRIMARY1 } from "../../utils/constants";
import { ReactComponent as LoadMoreIcon } from '../../assets/icons/load-more.svg'
import { stringAvatar } from '../../utils/design-utils'
import * as _ from "lodash";
import { convertToDisplayDate } from "../../utils/date-utils";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import {useNavigate,useLocation} from "react-router-dom"
import {useAppDispatch, useAppSelector } from "../../store/hooks";
import { ReactComponent as NoResultFound } from '../../assets/icons/no-result-found.svg'
import { getCandidateByIntelligentSearch, GetCandidateDetails, getCandidateList, getCandidateTypeSense, initiateRpa } from "../../services/CandidateService";
import { profile } from "console";
import { toast } from "react-toastify";
import { ClassNames } from "@emotion/react";
import { getValue } from "@testing-library/user-event/dist/utils";
import { blue } from "@mui/material/colors";
import {ReactComponent as CloseIcon} from "../../assets/icons/modalcloseicon.svg"
import { BorderAllRounded } from "@mui/icons-material";
import { setFilterValues } from "../../features/filters/FilterReducer";
import { ReactComponent as FacesheetIcon } from '../../assets/icons/facesheet.svg'
import { ReactComponent as ShowMoreIcon } from '../../assets/icons/showmore.svg'
import { ReactComponent as ClippadIcon } from '../../assets/icons/clippad.svg'
import { ReactComponent as ViewNotesIcon } from '../../assets/icons/ViewNotes.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg'
import { ReactComponent as SharecvIcon } from '../../assets/icons/share-cv.svg'
import { ReactComponent as PrescreenIcon } from '../../assets/icons/prescreen-icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import AssignMandateLocationPopup from "../Mandates/MandateProfile/AssignMandateLocationPopup/AssignMandateLocationPopup";
import CandidateLocationIcon from "../../assets/icons/candidate_location_icon.svg"
import CandidateDesignationIcon from "../../assets/icons/candidate_designation_icon.svg"
import CandidateCompanyIcon from "../../assets/icons/candidate_company_icon.svg"
import CandidateCalendarIcon from "../../assets/icons/candidate_calendar_icon.svg"
import { setCandidateApiGatewayStatus, setFilterPersist, setPersistFilter } from "../../features/filters/PersistFilter";

export interface ListState {
    page: number
    size: number
}

const CandidateSearchResults = ({searchListCount,setSearchResultCount, searchFilter, immediateFilter,setImmediateFilter, handleSetPersist, tabVal,advancedFilters,setAdvancedFilters,searchListData,setSearchListData,selectedKeywords,applyFilterStatus,setApplyFilterStatus}:any) => {
   const persistFilter = useAppSelector((state)=> state.PersistFilterReducer.persistFilter)
   const apiGatwayStatus = useAppSelector((state)=> state.PersistFilterReducer.apiTimeOutStatus)
   const { userId, userRole } = useAppSelector((state) => state?.UserDetail)  
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(false)
    const [isLoadingNextPage, setLoadingNextPage] = useState(false)
    const showLoading = () => setLoading(true)
    const hideLoading = () => setLoading(false)
    const showLoadingNextPage = () => setLoadingNextPage(true)
    const hideLoadingNextPage = () => setLoadingNextPage(false)
    const [refreshCounter, setRefreshCounter] = useState(0);
    const reloadApi = () => setRefreshCounter((refreshCounter) => refreshCounter+1);
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)

    const filters = useAppSelector((state) => state.filtersReducer.filters)
    const filterPreloadData = useAppSelector((state) => state.filtersReducer.filterPreloadData)
  

    const [listState, setListState] = useState<ListState>({
        page: 1,
        size: 10,
    })

    const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
    const navigate =useNavigate()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [popupTag, setPopupTag] = useState<string>("")
    const [selectedCandidate, setSelectedCandidate] = useState<any>({})
    const [isAssignPopup, setIsAssignPopup] = useState<boolean>(false)
    const location = useLocation();
    useEffect(()=>{
      dispatch(setPersistFilter({...persistFilter,searchCount:searchListCount}))
    },[searchListCount])
    const toggleItemIdSelection = (id:string) => {
        return (e:any) => {
            if(_.includes(selectedItemIds, id)) {
                setSelectedItemIds((selectedItemIds => {
                    const newSelectedItemIds:string[] = _.cloneDeep(selectedItemIds);
                    _.remove(newSelectedItemIds, (itemId:string) => itemId === id);
                    return newSelectedItemIds;
                }))
            } else {
                setSelectedItemIds([...selectedItemIds, id])
            }
            e.stopPropagation();
            return false;
        }
    };


    const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
      return (
        <Box className='circularProgressBox' sx={{ position: 'relative', display: 'inline-flex'  ,borderRadius:'50%', } } >
          <CircularProgress className="circularProgressBG" variant='determinate' thickness={2} value={100} sx={{  }} />
          <CircularProgress variant='determinate' {...props} sx={{  }} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='caption' component='div' color='text.secondary'>{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        </Box>
      )
    }
    const renderCandidateDetailItem = (icon: any, text: string | undefined, type: string) => {
        // if(!text) return null;
        return (
            <Box className="candidate-info-item">
                <Box className="candidate-info-item-icon"><img src={icon} /></Box>
                {(text != undefined && text.length > 0) && <Box className="candidate-info-item-text">{(type == 'experience' && text == "0 years") ? "" : text}</Box>}
            </Box>
        )
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, tag:string) => {
      setAnchorEl(event.currentTarget)
      setPopupTag(tag)
    }
    const handleClose = () => {
      setAnchorEl(null)
      setPopupTag("")
    }

    const handleClickItem = (candidateItem:any) => {
      handleSetPersist()
        const id = _.get(candidateItem, 'id', '');
        navigate(`/candidate/${id}`, {state: {searchFilter}})
    }
    const showProgrssbar =()=>{
        if(tabVal!=3 || filters?.tags?.length  ||filters?.searchQueryId){
            return false
        }else{
            return true
        }
    }
    const editCandidate = (id:any) => {
          GetCandidateDetails(id)
          .then((res:any) => {
            const candidateDetail = res.data;
            navigate(`/createCandidate`,{state:{candidateDetail: candidateDetail,status:"edit"}})
          })
          .catch((error) => {
            console.log('error', error.message)
          })      
    }
    const renderSearchListItem = (candidateItem:any) => {
        const name = _.get(candidateItem, 'name', '');
        const id = getUniqueId(candidateItem);
        const isItemSelected = _.includes(selectedItemIds, id);
        return (
          <Box className='search-result-box'>
            <Box className='search-result-body-wrap'>
              <Box className='search-result-body'>
                <Box className='search-result-body-title-row'>
                  {isDeepSearchAllowed() && (
                    <Checkbox
                      size='small'
                      checked={isItemSelected}
                      onClick={toggleItemIdSelection(id)}
                      disabled={_.get(candidateItem, 'status') !== 'basic'}
                      className='search-result-item-checkbox'
                    />
                  )}
                  <Avatar {...stringAvatar(name)} className='search-result-avatar' />
                  <Box className='search-result-candidate-data'>
                    <Box
                      className='search-result-candidate-name'
                      onClick={() => {
                        if(userPermissions?.candidate_module?.list?.view?.selected && userRole != "Admin"){
                          handleClickItem(candidateItem)
                        }else if(userRole == "Admin"){
                          handleClickItem(candidateItem)
                        }
                    }}
                    >
                      {name}
                    </Box>
                    <Box className='search-result-candidate-details'>
                      {renderCandidateDetailItem(
                        CandidateLocationIcon,
                        _.get(candidateItem, 'currentLocation', ''), ''
                      )}
                      {renderCandidateDetailItem(
                        CandidateDesignationIcon,
                        _.get(candidateItem, 'currentDesignation', ''), ''
                      )}
                      {renderCandidateDetailItem(
                        CandidateCalendarIcon,
                        `${_.get(candidateItem, 'experience', '')} years`, 'experience'
                      )}
                      {renderCandidateDetailItem(
                        CandidateCompanyIcon,
                        _.get(candidateItem, 'currentCompany', ''), ''
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className='search-result-body-summary-text'>
                  {_.get(candidateItem, 'about', '')}
                </Box>
              </Box>
              <Box className='circular-progress' style={{justifyContent:"start"}}>
                <Box style={{display:"flex", width:"100%", justifyContent:"end"}} >
                  {_.get(candidateItem, 'status') ? (
                    <Box className='type-badge' style={{height:"fit-content"}}>
                      <span>{_.get(candidateItem, 'status') === 'basic' ? 'Basic' : 'Complete'}</span>{' '}
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box style={{height:"fit-content"}} onClick={(e:any)=>handleClick(e, `showMore-${candidateItem?.id}`)} >
                    <ShowMoreIcon style={{height:"26px", width:"26px", cursor:"pointer"}} />
                  </Box>
                  <Popover
                    id={id}
                    open={popupTag === `showMore-${candidateItem?.id}`}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className='show-more-popup'
                  >
                    <Box
                      className="popup-wrapper"
                    >
                      {(userPermissions?.candidate_module?.view?.assign_jo?.selected && userRole != "Admin") && <Box 
                        className="icon-wrapper" 
                        onClick={()=> {
                          setSelectedCandidate(candidateItem)
                          setIsAssignPopup(true)
                          handleClose()
                        }}
                      >
                        <ClippadIcon/>
                        <Typography className='pop-text'>Assign JO</Typography>
                      </Box>}
                      {(userRole == "Admin") && <Box 
                        className="icon-wrapper" 
                        onClick={()=> {
                          setSelectedCandidate(candidateItem)
                          setIsAssignPopup(true)
                          handleClose()
                        }}
                      >
                        <ClippadIcon/>
                        <Typography className='pop-text'>Assign JO</Typography>
                      </Box>}
                      <Box className="icon-wrapper">
                        <EditIcon/>
                        <Typography className='pop-text' onClick={() => {editCandidate(candidateItem.id)}}>Edit</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <SearchIcon/>
                        <Typography className='pop-text'>Manual Deep Search</Typography>
                      </Box>
                      {/* <Box className={`icon-wrapper ${candidateItem?.status !== 'basic' && "disable-popup"}`} */}
                      <Box className={`icon-wrapper disable-popup`}
                        // onClick={()=>{
                        //   if(!(candidateItem?.status !== 'basic')){
                        //     const canData:any = {
                        //       id: candidateItem?.uniqueId,
                        //       name: candidateItem?.name,
                        //       position: candidateItem?.currentDesignation,
                        //       currentCompany: candidateItem?.currentCompany
                        //     }
                        //     onInitateRpaTrigger(canData)
                        //     handleClose()
                        //   }
                        // }} 
                        >
                        <ViewNotesIcon className='notes-icon'/>
                        <Typography className='pop-text'>Initiate Deep Search</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <TrashIcon/>
                        <Typography className='pop-text'>Delete</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <SharecvIcon/>
                        <Typography className='pop-text'>Share CV</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <PrescreenIcon/>
                        <Typography className='pop-text'>Initiate Prescreening</Typography>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
                {(tabVal != 3 && showProgrssbar() && candidateItem.confidenceScore != undefined) &&
                (
                  <CircularProgressWithLabel
                    thickness={2}
                    value={_.get(candidateItem, 'confidenceScore')}
                  />
                )}
                {(tabVal == 3 && candidateItem.candidateScore != undefined) &&
                (
                  <CircularProgressWithLabel
                    thickness={2}
                    value={_.get(candidateItem, 'candidateScore') >= 10 ? 100 : _.get(candidateItem, 'candidateScore') * 10 }
                  />
                )}
              </Box>
            </Box>
            <Box
              className='search-result-footer'
              sx={{ height: { xs: '3.5rem', lg: '2.0625rem' } }}
              style={{justifyContent:"space-between"}}
            >
              <Box style={{display:"flex", alignItems:"center"}} >
                <Box className='footer-data-container'>
                  <Box className='footer-data-label'>Modified on: </Box>
                  <Box className='footer-data-text'>
                    {convertToDisplayDate(_.get(candidateItem, 'modifiedOn', ''))}
                  </Box>
                </Box>
                <Box className='footer-data-container'>
                  <Box className='footer-data-label'>Active: </Box>
                  <Box className='footer-data-text'>
                  
                    {_.get(candidateItem, 'sourceFrom', '') == "manual" ? "" : convertToDisplayDate(_.get(candidateItem, 'lastActive', ''))}
                  </Box>
                </Box>
                <Box className='footer-data-container'>
                  <Box className='footer-data-label'>Artemis Creation Date: </Box>
                  <Box className='footer-data-text'>
                    {convertToDisplayDate(_.get(candidateItem, 'createdAt', ''))}
                  </Box>
                </Box>
              </Box>
              <Box style={{ opacity: '0.5', display:"flex", gap:"4px", alignItems:"center" }}>
                <FacesheetIcon />
                <Box style={{ color: '#002882', fontSize: ".8125rem", fontWeight:400 }}>
                  Generate Facesheet
                </Box>
              </Box>
            </Box>
          </Box>
        )
    }
    // const ButtonComponent = ({name, index}:{name: any, index: number}) => {
    //   return (
        
    //   )
    // }
    const handlePage = () => {
        setListState((listState) => {
            return {
                ...listState,
                page: listState.page + 1,
            }
        })
    }

    const loadFirstPage = () => {
        setListState((listState) => {
            return {
                ...listState,
                page: 1,
            }
        })
    }

    const getTabKeyValue = (tabValue:number) => {
      return tabValue == 0 ? "keyword" : tabValue == 1 ? "sourcing" : tabValue == 2 ? "rpa" : tabValue == 3 ? "sourcing" : ""
  }

  const manipulateCanidate = (data:any) => {
    const canidateObj = data.map((item:any) => {
      return {
        "id": tabVal == 3 ? item?.id : item?.document?.id,
        "name": tabVal == 3 ? item?.name : item?.document?.name,
        "experience": tabVal == 3 ? item?.experience : item?.document?.experience,
        "ctcInLacs": tabVal == 3 ? item?.ctc_in_lacs : item?.document?.ctc_in_lacs,
        "expectedCtc": tabVal == 3 ? item?.expected_ctc : item?.document?.expected_ctc,
        "currentCompany": tabVal == 3 ? item?.currentcompany : item?.document?.current_company,
        "currentDesignation": tabVal == 3 ? item?.currentdesignation : item?.document?.current_designation,
        "currentLocation": tabVal == 3 ? item?.currentlocation : item?.document?.current_location,
        "preferredLocations": tabVal == 3 ? item?.preferred_locations != undefined ? item?.preferred_locations : "" : item?.document?.preferred_locations != undefined ? item?.document?.preferred_locations : "",
        "previousCompany": "",
        "educationDetails": tabVal == 3 ? item?.education_details : item?.document?.education_details,
        "keySkills": tabVal == 3 ? item?.key_skills != undefined ? item?.key_skills : "" : item?.document?.key_skills != undefined ? item?.document?.key_skills : "",
        "otherSkills": tabVal == 3 ? item?.other_skills != undefined ? item?.other_skills : "" : item?.document?.other_skills != undefined ? item?.document?.other_skills : "",
        "about": tabVal == 3 ? item?.about : item?.document?.about,
        "profileViewedStatus": "",
        "modifiedOn": tabVal == 3 ? item?.modifiedon : item?.document?.modified_on,
        "profileViews": 0,
        "downloads": 0,
        "lastActive": tabVal == 3 ? item?.lastactive : item?.document?.last_active,
        "address": null,
        "uniqueId": "",
        "displayPicture": "",
        "noticePeriod": null,
        "dateOfBirth": null,
        "phoneNumber": null,
        "email": null,
        "linkedin": null,
        "workSummary": null,
        "createdAt": tabVal == 3 ? item?.createdat : item?.document?.created_at,
        "workExperiences": null,
        "itSkills": null,
        "certification": null,
        "detailedEducation": null,
        "knownLanguages": null,
        "candidateResume": null,
        "status": tabVal == 3 ? item?.status : item?.document?.status,
        "personalDetails": null,
        "workAuthorization": null,
        "desiredJobDetails": null,
        "workDetails": null,
        "sourceFrom": tabVal == 3 ? item?.sourcefrom : item?.document?.source_from,
        "remarks": null,
        "duplicatProfile": null,
        "currentCtcStart": null,
        "expectedCtcStart": null,
        "faceBook": null,
        "twitter": null,
        "social": "",
        "linkedinData": null,
        "confidenceScore": tabVal == 3 ? item?.confidencescore : item?.document?.confidence_score,
        "candidateScore" : tabVal == 3 ? item?.confidencescore : "",
        "stageId": 0,
        "statusId": 0,
        "stageName": "",
        "statusName": "",
        "subStages": "",
        "mandatelocationCount": 0,
        "isEnd": 0,
        "stageOrder": 0,
        "statusOrder": 0,
        "filterMandateId": null,
        "mlocationId": null,
        "trackStatusId": null,
        "trackCreatedBy": null,
        "collaborators": null
    }
    })
    return canidateObj
  }
    const makeCandidateListApi = useCallback(_.debounce((type:string) => {
      if(isNaN(parseInt(filters?.mandateId)) || !persistFilter?.persistMandate?.[`manTab${tabVal}`] || persistFilter?.persistMandate?.[`manTab${tabVal}`]==="" || filterPreloadData?.dataLoad===false){
        if(!isBasicFiltersApplied() || filterPreloadData?.dataLoad===false) {
          setSearchListData({...searchListData,[`searchList${tabVal}`]:[]});
          hideLoading();
          hideLoadingNextPage();
          return;
      }
      }
      
        const queryParams:any = {
            ...listState,
            profileType:filters?.advFilters?.[`advfilTab${tabVal}`]?.profileType??_.get(filters, 'profileType', 'All'),
        }
        let lastActivity = _.get(filters?.advFilters?.[`advfilTab${tabVal}`], 'lastActivity', -1);
        if(lastActivity === -1) {
            lastActivity = "";
        }
        const selectedTabAdvFilterVal = filters?.advFilters?.[`advfilTab${tabVal}`]
        let requestBody:any = {}
        let searchQuery:any = {
            searchKey : persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]
          }
         if(tabVal == 1){
          searchQuery = {
            mandateId:persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
            mlocationId:persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:"",
            tags:persistFilter?.persistTags?.length > 0 && tabVal === 1? persistFilter?.persistTags:[]
          }
        }else if(tabVal == 2){
          searchQuery = {
            mandateId:persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
            mlocationId:persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:"",
            searchQueryId:persistFilter?.persistSearchQuery && tabVal===2?persistFilter?.persistSearchQuery:""
          }
        }     
        const filterBy:any = []
        if(!(applyFilterStatus?.status == "search" || type == "search")){
          requestBody = {
            status : (filters?.advFilters?.[`advfilTab${tabVal}`]?.profileType != "All" ? filters?.advFilters?.[`advfilTab${tabVal}`]?.profileType : "" )??"",
            currentLocation: filters?.advFilters?.[`advfilTab${tabVal}`]?.location??"",
            preferredLocation: filters?.advFilters?.[`advfilTab${tabVal}`]?.preferredLocation??"",
            primarySkills: (filters?.advFilters?.[`advfilTab${tabVal}`]?.primarySkills.length > 0 ? filters?.advFilters?.[`advfilTab${tabVal}`]?.primarySkills : [])??"",
            secondarySkills: filters?.advFilters?.[`advfilTab${tabVal}`]?.secondarySkills??"",
            education: filters?.advFilters?.[`advfilTab${tabVal}`]?.education??"",
            currentCompany: filters?.advFilters?.[`advfilTab${tabVal}`]?.company??"",
            currentDesignation: filters?.advFilters?.[`advfilTab${tabVal}`]?.designation??"",
            lastActive: filters?.advFilters?.[`advfilTab${tabVal}`]?.lastActivity??"",
          }
          Object.keys(requestBody).map((item:any) => {
            if(requestBody[item].length > 0 ){
              filterBy.push(`${item}: [${requestBody[item]}]`)
            }
          })
        }else{
          requestBody = {
            // "mandateId" : "",
            // "location" : "Hyderabad",
            // "preferredLocation" : "secunderabad",
            // "primarySkills" : [],
            // "secondarySkills" : [],
            // "education" : "",
            // "company" : "",
            // "designation" : "JAVA",
            // "minSalary" :  5,
            // "maxSalary" : 10,
            // "lastActivity" : 25,
            // "keyword": "java",
            // "booleanSearch": true,
            // "matchCriteria": "skills",
            // "confidenceScore" : 80,
            // ...filters,
            ...filters?.advFilters?.[`advfilTab${tabVal}`],
            primarySkills:persistFilter?.[`advancedFilters${tabVal}`]?.primarySkills?? _.get(filters?.advFilters?.[`advfilTab${tabVal}`], 'primarySkills', []),
            secondarySkills:persistFilter?.[`advancedFilters${tabVal}`]?.secondarySkills?? _.get(filters?.advFilters?.[`advfilTab${tabVal}`], 'secondarySkills', []),
            keyword:persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]??"",
            tags:persistFilter?.persistTags?.length > 0 && tabVal === 1? persistFilter?.persistTags:[],
            searchQueryId:persistFilter?.persistSearchQuery && tabVal===2?persistFilter?.persistSearchQuery:"",
            // "primarySkills":[],
            // "secondarySkills":[],
            minSalary:persistFilter?.[`advancedFilters${tabVal}`]?.salaryRange[0]?? _.get(filters, 'salaryRange.0'),
            maxSalary:persistFilter?.[`advancedFilters${tabVal}`]?.salaryRange[1]?? _.get(filters, 'salaryRange.1'),
            mandateId:persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
            lastActivity: lastActivity,
            minExperience:persistFilter?.[`advancedFilters${tabVal}`]?.experience[0]?? _.get(filters, 'experience.0'),
            maxExperience:persistFilter?.[`advancedFilters${tabVal}`]?.experience[1]?? _.get(filters, 'experience.1'),
            matchCriteria:persistFilter?.persistCriteria && typeof(persistFilter?.persistCriteria)==="string" && tabVal===3?persistFilter?.persistCriteria:"",
            screenFlag: "candidatesearch",
            mlocationId:persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:""
        };
        delete requestBody.salaryRange;
        delete requestBody.mLocationId
        }
        
        // delete requestBody.matchCriteria;
        // delete requestBody.confidenceScore;
        const { page } = listState;
        queryParams["tabType"] = getTabKeyValue(tabVal)
        if(type != "search"){
          let intelligentRequestBody:any = {}
          if(tabVal == 3){
            let locSelected:any = {};
            if(persistFilter?.locationOptions?.[`locList${tabVal}`] != null && (persistFilter?.persistLocation && persistFilter?.persistLocation[`locTab${tabVal}`])){
              locSelected = persistFilter?.locationOptions?.[`locList${tabVal}`].find((o:any) => o.id == persistFilter?.persistLocation[`locTab${tabVal}`])
            }
            intelligentRequestBody = {
              "mandateId": persistFilter?.persistMandate[`manTab${tabVal}`]?.value,
              "skillWeights": persistFilter.persistIntelligentTab.skillWeights,
              "skillThresholds": persistFilter.persistIntelligentTab.skillThresholds,
              "minExperience": (persistFilter.persistIntelligentTab.minExperience != "" && persistFilter.persistIntelligentTab.minExperience != null) ? parseInt(persistFilter.persistIntelligentTab.minExperience) : 0,
              "minCtc": (persistFilter.persistIntelligentTab.minCtc != "" && persistFilter.persistIntelligentTab.minCtc != null) ? parseInt(persistFilter.persistIntelligentTab.minCtc) : 0,
              "maxCtc": (persistFilter.persistIntelligentTab.maxCtc != "" && persistFilter.persistIntelligentTab.maxCtc != null) ? parseInt(persistFilter.persistIntelligentTab.maxCtc) :  150,
              // "location": locSelected?.location,
              "pageNo": listState.page,
              "pageSize": 10,
              "location": Object.keys(locSelected).length > 0 ? locSelected?.location : "any",
              "typesenseSearchDto":{
                "queryBy": "name,about,current_location,preferred_locations,current_designation,education_details,key_skills,other_skills,phone_number,e_mail",
                "useCache": true,
                "cacheTime": 60,
                "searchQuery": "*",
                "offset":listState.page,
                "limit":10,
                "tabId":0,
                "filterBy": filterBy.join(" && ")                
                }
            }
          }
          // if(tabVal == 3)if(intelligentRequestBody?.mandateId == null || intelligentRequestBody?.location == null){hideLoading();return;}
          //  (tabVal == 3 ? getCandidateByIntelligentSearch(intelligentRequestBody): 
          (tabVal == 3 ? getCandidateByIntelligentSearch(intelligentRequestBody): getCandidateTypeSense(searchQuery, filterBy, queryParams, tabVal)).then((res:any) => {
              setTimeout(async () => {
                const response = _.get(res,'data',{})
                const candidateData = tabVal != 3 ? response : response.data;
                const candidateListArray = tabVal != 3 ? candidateData?.hits : candidateData
                const listItems = candidateListArray.length > 0 ? manipulateCanidate(candidateListArray) : [];
                const listCount = tabVal != 3 ? (candidateData?.found != candidateListArray.length && candidateListArray.length < 10) ? candidateData?.found - 1 : candidateData?.found : candidateData.length > 0 ?  candidateListArray.length < 10 ? candidateData[0].total_count - 1 : candidateData[0].total_count : 0
                if(Object.keys(response)?.length > 0){
                  const {candidates,...rest} = response
                 
                  // setAdvancedFilters(rest)
                  // setAdvancedFilters({...advancedFilters,[`advancedFilter${tabVal}`]:rest})
                }
                setSearchResultCount({...searchListCount,[`searchCount${tabVal}`]:listCount});
                if(page > 1) {
                    // setSearchListData((existingItems:any) => {
                    //     return [
                    //         ...existingItems,
                    //         ...listItems,
                    //     ]
                    // });
                    if (searchListData && Object.keys(searchListData).length) {
                      setSearchListData({
                        ...searchListData,
                        [`searchList${tabVal}`]: [...searchListData[`searchList${tabVal}`] || [], ...listItems]
                      });
                    }
                } else {
                  if(Object.keys(searchListData)?.length){
                    setSearchListData({...searchListData,[`searchList${tabVal}`]:listItems});
                  }
                }
                hideLoading();
                hideLoadingNextPage();
              },500)  
              dispatch(setCandidateApiGatewayStatus(false))
              
            }).catch((error) => {
                if(page === 1) {
                  if(Object.keys(searchListData)?.length){
                    setSearchListData({...searchListData,[`searchList${tabVal}`]:[]});
                  }
                }
                hideLoading();
                hideLoadingNextPage();
                if (error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED' || !error.response) {
                  dispatch(setCandidateApiGatewayStatus(true))
                }else{
                  dispatch(setCandidateApiGatewayStatus(false))
                }
                
            })

      }
        if(applyFilterStatus?.status == "search" || type == "search"){
          getCandidateList(requestBody, queryParams,"FILTER_DATA").then((res) => {
              const response = _.get(res,'data',{})
              if(Object.keys(response)?.length > 0){
                const {candidates,...rest} = response
                setAdvancedFilters({...advancedFilters,[`advancedFilter${tabVal}`]:rest})
              }
              setTimeout(() => {
                setApplyFilterStatus({
                  status: "loadSearch",
                })                
              },500)
              
            }).catch(() => {
              hideLoading();
              hideLoadingNextPage();
          })
        }
    }, 300), [listState,filters,tabVal])

    useEffect(() => {
        // const renderRequired = {skip: false};
       if(refreshCounter !== 0){
            makeCandidateListApi();
        }
        // return () => {
        //   console.log(`skip set for ${refreshCounter}`)
        //   renderRequired.skip = true
        // }
    }, [refreshCounter])

    useEffect(() => {
      if(apiGatwayStatus)makeCandidateListApi()
    },[searchFilter])

    useEffect(() => {
      if(applyFilterStatus.status == "search"){
        makeCandidateListApi("search")
      }
    },[applyFilterStatus])

    useEffect(() => {
        showLoadingNextPage();
        reloadApi();
    }, [listState])

    useEffect(() => {
        showLoading();
        loadFirstPage();
        reloadApi();
    }, [filters])

    const LoadMore = ({isLoadingNextPage}:{isLoadingNextPage:boolean}) => {
        if(isLoadingNextPage) {
          return (
            <Box className="load-more-section-loading-container">
              <Loading />
            </Box>
          );
        }
        return (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            className='load-more-container'
            onClick={handlePage}
          >
            <Typography component='span' className="common-load-more" sx={{ color: `${PRIMARY1}` }}>
              Load More <LoadMoreIcon className='load-more-icon' />
            </Typography>
          </Box>
        )
    }

    const renderSearchResultsList = () => {
        const searchListItems = _.map(searchListData?.[`searchList${tabVal}`], (candidateItem:any) => {
            return renderSearchListItem(candidateItem);
        });
        return (
            <Box>
                {searchListItems}
                {(searchListCount?.[`searchCount${tabVal}`]> searchListData?.[`searchList${tabVal}`]?.length) && <LoadMore isLoadingNextPage={isLoadingNextPage} />}
            </Box>
        )
    }

    const getVisibleSelectedItems = () => {
        return _.filter(searchListData?.[`searchList${tabVal}`], (item:any) => {
            return _.includes(selectedItemIds, getUniqueId(item));
        });
    }

    const isAllItemsSelected = () => {
        const visibleSelectedItems = getVisibleSelectedItems();
        return searchListData?.[`searchList${tabVal}`]?.length!=0 && searchListData?.[`searchList${tabVal}`].filter((item:any)=> item.status !== "complete")?.length === visibleSelectedItems?.length;
    }

    const isAtleastOneItemSelected = () => {
        const visibleSelectedItems = getVisibleSelectedItems();
        return searchListData?.[`searchList${tabVal}`]?.length!=0 && visibleSelectedItems?.length>0;
    }

    const getUniqueId = (item:any) => {
        return _.get(item, 'id', '');
    }

    const selectAllItemsToggle = () => {
        if(isAllItemsSelected()) {
            setSelectedItemIds([]);
            const completedListStatus = searchListData?.[`searchList${tabVal}`]?.filter((data:any)=> data.status !== "complete");
            if(completedListStatus.length == 0) toast.error('Already profiles are in completed state')
        } else {
            const allItemIds = _.map(searchListData?.[`searchList${tabVal}`]?.filter((data:any)=> data.status !== "complete"), getUniqueId);
            setSelectedItemIds(allItemIds);
        }
    }

    const isDeepSearchAllowed = () => {
        const profileType = _.get(filters, 'profileType', 'basic');
        return (profileType !== "complete");
    }

    const onInitateRpaTrigger = (singleProfile:any=null) => {
        // let selectedItems:any[] = [];
        const selectedDataItems = getVisibleSelectedItems()
        const selectedQueryItems = _.map(selectedDataItems, (item:any) => {
            return {
                id: _.get(item, 'uniqueId'),
                name: _.get(item, 'name'),
                position: _.get(item, 'currentDesignation'),
                currentCompany: _.get(item, 'currentCompany')
            }; 
        })
        const apiPayload = singleProfile ? [singleProfile] : selectedQueryItems
        initiateRpa(apiPayload).then(() => {
            toast.success('Deep search initiated successfully!')
        }).catch((e) => {
            toast.error('Deep search initiation failed!')
        })
        setSelectedItemIds([])
    }

    const renderSearchResultsHeader = () => {
        return (
          <Box
            className='candidate-search-result-header'
            sx={{
              flexDirection: { xs: 'column', lg: 'row' },
              alignItems: { xs: 'left', lg: 'center' },
              justifyContent: { xs: 'flex-start', lg: 'space-between' },
            }}
          >
            
            <Box
              className='candidate-search-title'
              sx={{
                marginBottom: { xs: '1rem', lg: '0rem' },
              }}
            >
              Search Results ({searchListCount?.[`searchCount${tabVal}`]})
            </Box>
            
            {isDeepSearchAllowed() && (
              <Box className='candidate-search-inputs-container'>
                <FormGroup className='checkbox-form-group'>
                  <FormControlLabel
                    className='checkbox-form-label'
                    control={
                      <Checkbox
                        size='small'
                        onClick={selectAllItemsToggle}
                        checked={isAllItemsSelected()}
                      />
                    }
                    label='Select All'
                  />
                </FormGroup>
                <Button
                  // disabled={!isAtleastOneItemSelected()}
                  disabled={true}
                  onClick={()=> onInitateRpaTrigger()}
                  className='initiate-rpa-button'
                >
                  Initiate Deep Search
                </Button>
              </Box>
            )}
          </Box>
        )
    }
    const onChipReomve = (name: string) => {
        const data =immediateFilter?.split('+')?.filter((ele: string) => ele !== name)?.join('+')
        setImmediateFilter(data)
    }
    const renderMatchCriteriaChips = () => {
      return (
        <Box className='match-criteria-chips'>
          <div className='header-chips'>Match Criteria :</div>
          {immediateFilter && immediateFilter
            ?.split('+')
            ?.map((ele: any, index: number) => (
              <div key={index} className='chips-inside'>
                <div style={{ margin: '5px' }}>{ele}</div>
                <div style={{ margin: '5px 10px 5px 5px' }}>
                  <CloseIcon className= 'closeIcon'style={{ width: 10, height: 10, color: 'blue', cursor:"pointer" }} onClick={() => onChipReomve(ele)} />
                </div>
              </div>
            ))}
        </Box>
      )
    }


    const renderSearchResults = () => {
        return (
            <>
                {/* <pre>{JSON.stringify(filters, null, 2)}</pre> */}
                {renderSearchResultsHeader()}
                {immediateFilter?.length !== 0 && renderMatchCriteriaChips()}
                {renderSearchResultsList()}
            </>
        )
    }

    const isBasicFiltersApplied = () => {
        const mandateIdValue = persistFilter?.persistMandate?.[`manTab${tabVal}`];
        const keyword = persistFilter?.keywordsPersist?.[`keyTab${tabVal}`] && persistFilter?.keywordsPersist?.[`keyTab${tabVal}`] !="";
        return (mandateIdValue || keyword)
    }

    const renderEmptySearchBox = () => {
        const emptyMessage = isBasicFiltersApplied() ?  `No data found` : `Let’s search for profiles`;
        return (
            <Box className="candidate-empty-search-box">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    {emptyMessage}
                </Box>
            </Box>
        )
    }
    return (
        <>
            <Box className="candidate-section-box candidate-search-results-section-box">
                {isLoading && <Box className="candidate-loading-box"><Loading /></Box>}
                {(!isLoading && searchListData?.[`searchList${tabVal}`]?.length > 0) && renderSearchResults()}
                {(!isLoading && searchListData?.[`searchList${tabVal}`]?.length === 0) || (!searchListData?.[`searchList${tabVal}`]) ? renderEmptySearchBox():<></>}
            </Box>
            {isAssignPopup && 
              <AssignMandateLocationPopup
                isPopupOpen={isAssignPopup}
                handlePopupClose={()=> {setIsAssignPopup(false); loadFirstPage()}}
                candidateDetailsData={selectedCandidate}
              />
            }
        </>
    )
}

export default CandidateSearchResults;