import { forminstance, instance, reportInstance } from "./http-common";


export const getHiringDemand = async () => {
    const response = await reportInstance.post(`/dashboard/hiringDemand`);
    return response;
}

export const getstageWiseConversation = async (listData:any) => {
    const response = await reportInstance.post(`/dashboard/recrutingEffeciency`,listData);
    return response;
}

export const getTopJobsWithCandidate = async (payload:any) => {
    const response = await reportInstance.post(`/dashboard/topJobsWithCandidate`,payload);
    return response;
}

export const getofferMgtEffectiveness = async (listData:any) => {
    const response = await reportInstance.post(`/dashboard/offerMgtEffectiveness`,listData);
    return response;
}

export const getStageFilter = async () => {
    const response = await reportInstance.get(`/dashboard/stageFilter`);
    return response;
}

export const getStatusFilter = async (statusId:string) => {
    const response = await reportInstance.get(`/dashboard/statusFilter?stageId=${statusId}`);
    return response;
}


export const dashoardClient = async () => {
    const response = await instance.get(`/master/clients`);
    return response;
}

export const MandateSPOCapi = async (reqobj:any) => {
    const response = await instance.post(`/mandate/getMandateSpocById`,reqobj);
    return response;
}


export const getJobTitleapi = async (queryString:any,reqObj:any) => {
    const response = await instance.post(`mandate/getJobTitleDropDown?${queryString}`,reqObj);
    return response;
}


export const getJoLocationsapicall = async (reqObj:any) => {
    const response = await instance.post(`mandate/getMandateJoLocations`,reqObj);
    return response;
}

// dashboard widget API

export const getHiringDemandList = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/hiringDemand',listData)
    return response;
}


export const gethiringdemandlistapi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/hiringDemandDrillDown',listData)
    return response;
} 

export const gethiringdemandClentMandatelistapi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/hiringDemandClientAndMandateDrillDown',listData)
    return response;
} 

export const getFunnelConversionListApi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/recrutingEffeciencyDrillDown',listData)
    return response;
}

export const getOfferMgtListApi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/offerMgtEffectivenessDrillDown',listData)
    return response;
}

export const getJobWithCandiListApi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/topJobsWithCandidateDrillDown',listData)
    return response;
}

export const jobsAgeingReportList = async (reqBody:any) => {
    const response = await reportInstance.post('dashboard/JOsAgeingReport',reqBody)
    return response;
}

export const getJobOrderAgeingDrillApi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/JOsAgeingReportDrillDown',listData)
    return response;
}

export const getApptrendsData = async (request:any) => {
    const response = await reportInstance.post('/dashboard/appUsageTrend',request)
    return response;
}

export const getApptrendsRoleWiseData = async (request:any) => {
    const response = await reportInstance.post('/dashboard/appUsageTrendRoleWise',request)
    return response;
}

export const getAppTrendsDrillApi = async (listData:any) => {
    const response = await reportInstance.post('/dashboard/appUsageTrendDrillDown',listData)
    return response;
}