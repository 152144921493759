import { Box, Typography, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as GroupHistoryIcon } from '../../../assets/icons/GroupHistory.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as ClientLogo } from '../../../assets/images/default_candidate_icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'

import { ReactComponent as MandatesIcon } from '../../../assets/icons/mandates.svg'
import { ReactComponent as AllocationMandate } from '../../../assets/images/Allocated_Mandates.svg'
import { ReactComponent as DeAllocationMandate } from '../../../assets/images/Deallocated_Job_Orders.svg'
import { ReactComponent as LocationCount } from '../../../assets/images/location.svg'
import { ReactComponent as OpenJobOrder } from '../../../assets/images/Open_Job_Orders.svg'

import {KeyboardArrowDown ,KeyboardArrowUp} from '@mui/icons-material';
import './RecruiterCard.scss'
const RecruiterCard = ({mandateOpen,setmandateOpen, className, onChange, option, ...rest }: any) => {
    return (
        <Box className={`recruiter-tag-wrapper sticky-left ${mandateOpen ? "active" : ""} ${className}`} {...rest}>
            <div className='recruiter-container'>
                <div className='recruiter-detail'>
                    <ClientLogo />
                    <div>
                        <Tooltip title={option?.title}>
                            <Typography className='recruiter-title'>{option?.title}</Typography>
                        </Tooltip>
                        <Typography className='recruiter-id'>{option?.mandateId}</Typography>
                    </div>
                </div>
            </div>
            <div className='mandate-drop-container'>
                <span className='mandate-group'>
                    <Box className="recruiterCardInfo">
                        <Tooltip title="Active mandates"><AllocationMandate /></Tooltip>
                        <div>{option?.clientName?.allocatedMandates}</div>
                    </Box>
                    <Box className="recruiterCardInfo">
                        <Tooltip title="Dellocated Job Orders"><DeAllocationMandate /></Tooltip>
                        <div>{option?.clientName?.closedJobOrder}</div>
                    </Box>
                    <Box className="recruiterCardInfo">
                        <Tooltip title="Allocated locations"><LocationCount /></Tooltip>
                        <div>{option?.clientName?.openLocation}</div>
                    </Box>
                    <Box className="recruiterCardInfo">
                        <Tooltip title="Open Job Orders"><OpenJobOrder /></Tooltip>
                        <div>{option?.clientName?.openJobOrder}</div>
                    </Box>
                    
                </span>
                <span className='' >
                    {mandateOpen ? <KeyboardArrowUp style={{cursor:"pointer"}} onClick={()=>setmandateOpen(false)} /> : <KeyboardArrowDown style={{cursor:"pointer"}} onClick={()=>{setmandateOpen(true); onChange()}} />}

                </span>
            </div>
        </Box>
    )
}

export default RecruiterCard


