/** @jsxImportSource @emotion/react */
import {Grid } from '@mui/material'
import "../App.scss"
import {useState } from 'react'
import JobswithCandidateWidget from '../components/Dashboard/OverviewDashboard/JobswithCandidateWidget/JobswithCandidateWidget'
import OfferManagementWidget from '../components/Dashboard/OverviewDashboard/OfferManagementWidget/OfferManagementWidget'
import ClientCandidateActivity from '../components/Dashboard/OverviewDashboard/ClientCandidateactivity/ClientCandidateactivity'
import RecruiteConltantCanditateactivityWidget from '../components/Dashboard/OverviewDashboard/RecruiteConltantCanditateactivityWidget/RecruiteConltantCanditateactivityWidget'
import CandidateSatusactivity from '../components/Dashboard/OverviewDashboard/CandidateSatusactivity/CandidateSatusactivity'
import JobOrderTableWidget from '../components/Dashboard/OverviewDashboard/JobOrderWidget/JobOrderTableWidget'
import DashboardFilterIndex from '../components/Common/CustomeDasboardFilter/Filters/DashboardFilterIndex'
import FunnelWidget from '../components/Dashboard/OverviewDashboard/FunnelWidget/FunnelWidget'
import { useLocation } from 'react-router-dom'
import HiringDemandCard from '../components/Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCard'



function Dashboard() { 
  const location = useLocation();
  const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
  const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state !=  null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
  const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "filterListLabels" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.filterListLabels : {} : {}) : {})
  const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
  const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
  const [userDependendObj,setUserDependendObj] = useState({})
  const [headerTitle,setHeaderTitle] = useState(`<Box className='tasklist mar0'>
    <Typography display='flex' gap={1} className='tasklist-title'>
    <Box style="font-weight: 600; font-size: 16px">Dashboard</Box>
    </Typography>
</Box>`)

  return (
    <div className='dashboardScreenSect'>
      <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData"]} headerTitle={headerTitle} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} filterType={"mainScreen"} screenType={"dashboard"}/>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm:1, md: 2 }}>
      
      <Grid item xs={12} lg={12}>
      <HiringDemandCard HirningDemandParamsDetails={HirningDemandParamsDetails}  reportSubmissionAPIData={((Object.keys((HirningDemandParamsDetails).length > 0 ? Object.keys(HirningDemandParamsDetails?.state?.filterList).length > 0 : false)) && Object.keys(reportSubmissionAPIData).length == 0) ? HirningDemandParamsDetails?.state?.filterList : reportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} userRoleObjectSelected={userRoleObjectSelected} userDependendObj={userDependendObj}/>
      </Grid>

      <Grid item xs={12} lg={12}>
        <FunnelWidget HirningDemandParamsDetails={HirningDemandParamsDetails} reportSubmissionAPIData={((Object.keys((HirningDemandParamsDetails).length > 0 ? Object.keys(HirningDemandParamsDetails?.state?.filterList).length > 0 : false)) && Object.keys(reportSubmissionAPIData).length == 0) ? HirningDemandParamsDetails?.state?.filterList : reportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} userRoleObjectSelected={userRoleObjectSelected} userDependendObj={userDependendObj}/>
      </Grid>

      {/* <Grid item xs={12} lg={6} style={{pointerEvents:"none",opacity:"0.5"}}>
        <JobswithCandidateWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid> */}
      <Grid item xs={12} lg={6}>
        <OfferManagementWidget HirningDemandParamsDetails={HirningDemandParamsDetails} reportSubmissionAPIData={((Object.keys((HirningDemandParamsDetails).length > 0 ? Object.keys(HirningDemandParamsDetails?.state?.filterList).length > 0 : false)) && Object.keys(reportSubmissionAPIData).length == 0) ? HirningDemandParamsDetails?.state?.filterList : reportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} userRoleObjectSelected={userRoleObjectSelected} userDependendObj={userDependendObj}/>
      </Grid>

      {/* <Grid item xs={12} lg={6} style={{pointerEvents:"none",opacity:"0.5"}}>
        <ClientCandidateActivity reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>
      <Grid item xs={12} lg={6} style={{pointerEvents:"none",opacity:"0.5"}}>
      <RecruiteConltantCanditateactivityWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid> */}

      {/* <Grid item xs={12} lg={12} style={{pointerEvents:"none",opacity:"0.5"}}>
        <CandidateSatusactivity reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid> */}

      {/* <Grid item xs={12} lg={12} style={{pointerEvents:"none",opacity:"0.5"}}>
        <JobOrderTableWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid> */}

    </Grid>

    {/* <Box sx={{marginTop:"15px"}}></Box> */}
    </div>
  )
}

export default Dashboard
