import {
    Datepicker,
    formatDate,
    Input,
    // MbscDatepickerChangeEvent,
    // MbscDateType,
    // MbscSelectChangeEvent,
    options,
    Page,
    Popup,
    Select,
    setOptions,
  } from '@mobiscroll/react';
  import { FC, useCallback, useEffect, useState } from 'react';
  import './DateRangePickerSelect.css'
import { Box, Button, IconButton, InputAdornment, Popover, TextField, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/calendar-blue.svg'
import moment from 'moment';

  setOptions({
    theme: 'ios',
    themeVariant: 'light'
  });

  interface DateRangePickerSelectProps {
    onDateChange: (date:any) => void;
    value:any;
    setDateRangeType:any;
    dateRangeType:string;
    dateSelection?:string
  }
  
  const startDate = '2024-08-28';
  const endDate = '2024-09-03';
  const now = new Date();
  const day = now.getDay();
  const monday = now.getDate() - day + (day === 0 ? -6 : 1);
  
  const respSelect = {
    xsmall: {
      touchUi: true,
    },
    small: {
      touchUi: false,
    },
  };
  
  const myData = [
    {
      value: 'custom',
      text: 'Custom',
    },
    {
      value: 'today',
      text: 'Today',
    },
    {
      value: 'yesterday',
      text: 'Yesterday',
    },
    {
        value: 'currentweek',
        text: 'Current Week',
    },
    {
      value: 'previousweek',
      text: 'Previous week',
    },
    {
      value: 'currentmonth',
      text: 'Current Month',
    },
    {
        value: 'currentquater',
        text: 'current-quarter',
    },
    {
        value: 'currentyear',
        text: 'Current Year',
    },
    {
        value: 'previousmonth',
        text: 'Previous Month',
    },
    {
        value: 'previousquater',
        text: 'Previous Quarter',
    }
    // {
    //   value: 'last-month',
    //   text: 'Last month',
    // },
    // {
    //   value: 'last-7-days',
    //   text: 'Last 7 days',
    // },
    // {
    //   value: 'last-30-days',
    //   text: 'Last 30 days',
    // },
    

  ];
  
  const DateRangePickerSelect: FC<DateRangePickerSelectProps> = ({ onDateChange, value, setDateRangeType,dateRangeType, dateSelection }) => {
    const formatDate = (date: Date) => {
      return date.toISOString().slice(0, 10);
    };
    const [isDattepickerOpen, setisDattepickerOpen] = useState<boolean>(false);
    const [start, startRef] = useState<Input | null>(null);
    const [end, endRef] = useState<Input | null>(null);
    const [selected, setSelected] = useState<string>('custom');
    const [selectedDate, setSelectedDate] = useState<any>(dateSelection == "range" ? [value != null ? value[0] : null , value != null ? value[1] : null] : value);
    const [inputValue, setInputValue] = useState<string>('');
    const [disabledInput, setDisabledInput] = useState<boolean>(false);
    const [activeShortcut, setactiveShortcut] = useState<any>(dateRangeType)
  
    const respPopup = {
      xsmall: {
        display: 'bottom',
        touchUi: true,
        buttons: [
          {
            text: 'Apply',
            handler: () => {
              const date = selectedDate;
  
              changeInputValue(date[0] as string, (date[1] as string) || (date[0] as string));
              setisDattepickerOpen(false);
              onDateChange(date);
            },
          },
          'cancel',
        ],
      },
      custom: {
        breakpoint: 559,
        buttons: [],
        display: 'anchored',
        anchor: document.querySelector('.date-filter-input'),
        anchorAlign: 'start',
        touchUi: false,
        scrollLock: false,
        showArrow: false,
        maxWidth: 920,
        width: 500
      },
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = isDattepickerOpen ? 'simple-popover' : undefined;
  
    const inputClick = () => {
      setisDattepickerOpen(true);
      handleClick
    }
  
    

    const changeInputValue = useCallback((start: string, end: string) => {
      console.log("datepicker1")
      const locale = options.locale || {};
      const dateFormat = locale.dateFormat || 'DD/MM/YYYY';
  
    //   setInputValue(formatDate(dateFormat, new Date(start)) + ' - ' + formatDate(dateFormat, new Date(end)));
    // setInputValue(`${start} - ${end}`)

    const formatDate = (date: string) => {
        return date.slice(0, 10);
      };
    const formattedStart = dateSelection == "range" ? formatDate(start) : start;
    const formattedEnd = dateSelection == "range" ? formatDate(end) : end;
    if(dateSelection == "range"){
      setInputValue(`${formattedStart} - ${formattedEnd}`);
    }else{
      setInputValue(`${formattedStart}`);
    }

}, []);
  
    const applyClick = useCallback(() => {
      console.log("datepicker1")
        console.log("selectedDate",selectedDate)
        const selectedDateArray = selectedDate
        if(dateSelection == "range"){
          if(selectedDateArray[1] ==  null) selectedDateArray[1] = selectedDateArray[0]
          changeInputValue(selectedDateArray[0] as string, (selectedDateArray[1] as string));
        }else{
          changeInputValue(selectedDateArray as string, (selectedDateArray as string));
        }
      // setisDattepickerOpen(false);
      onDateChange(selectedDateArray);
    }, [selectedDate, changeInputValue,onDateChange]);
  
    // const cancelClick = useCallback(() => {
    //   setisDattepickerOpen(false);
    //   inputClick
    // }, []);

   

    
      
      const onChange = useCallback(
        (event: any) => {
          const s = event;
          console.log("datepicker1",s)          

          if (s === 'custom') {
            setDisabledInput(false);
          } else {
            setDisabledInput(true);
      
            switch (s) {
              case 'today': {
                setSelectedDate([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]);
                setactiveShortcut("today")
                break;
              }
              case 'yesterday': {
                setSelectedDate([moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD'), moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD')]);
                setactiveShortcut("yesterday")
                break;
              }
              case 'currentweek': {
                setSelectedDate([moment().startOf('week').add(1, 'day').format('YYYY-MM-DD'), moment().endOf('week').add(1, 'day').format('YYYY-MM-DD')]);
                setactiveShortcut("currentweek")
                break;
              }
              case 'currentmonth': {
                setSelectedDate([moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]);
                setactiveShortcut("currentmonth")
                break;
              }
              case 'currentquater': {
                setSelectedDate([moment().startOf('quarter').format('YYYY-MM-DD'), moment().endOf('quarter').format('YYYY-MM-DD')]);
                setactiveShortcut("currentquater")
                break;
              }
              case 'currentyear': {
                setSelectedDate([moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')]);
                setactiveShortcut("currentyear")
                break;
              }
              case 'previousmonth': {
                setSelectedDate([
                  moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                  moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                ]);
                setactiveShortcut("previousmonth")
                break;
              }
              case 'previousquater': {
                setSelectedDate([
                  moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
                  moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
                ]);
                setactiveShortcut("previousquater")
                break;
              }
              
              case 'previousweek': {
                setSelectedDate([
                  moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD'),
                  moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
                ]);
                setactiveShortcut("previousweek")
                break;
              }
              case 'last-month': {
                setSelectedDate([
                  moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                  moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                ]);
                setactiveShortcut("last-month")
                break;
              }
              case 'last-7-days': {
                setSelectedDate([
                  moment().subtract(6, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ]);
                setactiveShortcut("current month")
                break;
              }
              case 'last-30-days': {
                setSelectedDate([
                  moment().subtract(29, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD')
                ]);
                setactiveShortcut("current month")
                break;
              }
              default:{
                
                break;
              }
            }
          }
          setSelected(s);
        },
        [now, monday, selectedDate]
      );
      
  
    // const onDateChange = useCallback((ev: MbscDatepickerChangeEvent) => {
    //   const date = ev.value as MbscDateType[];
  
    //   console.log("Dateiiis",date)
    //   setDisabledInput(false);
    //   setSelected('custom');
    //   setSelectedDate(date);
    // }, []);

    // const onDateChangeHandler = useCallback((ev: MbscDatepickerChangeEvent) => {
    //     const date = ev.value as MbscDateType[];
    //     setDisabledInput(false);
    //     setSelected('custom');
    //     setSelectedDate(date);
    //     onDateChange?.(date[0] as string, (date[1] as string) || (date[0] as string));
    //   }, [onDateChange]);

    const onDateChangeHandler =(ev: any) => {
        let date:any = ev.value as any[];
        if((date[0] !=null || date[1] !=null) && dateSelection == "range"){
          setactiveShortcut("custom")
        }
        
        console.log("onDateChangeHandler",date)
        if(dateSelection == "range"){
          if(date.length > 0){
            if(date[1] ==  null) date[1] = date[0]
          }else{
            date = [];  
          }
        }
        
        
        setDisabledInput(false);
        console.log("activeshortcut",activeShortcut)
        // const selecteddatetype = myData?.filter((item:any)=>item.value == activeShortcut).map((item:any)=>item?.value)
        setSelected('custom');
        setSelectedDate(date);
        console.log("datepicker",date)
        if(date.length > 0)onDateChange(date);
      }
  
    const onClose = useCallback(() => {
      setisDattepickerOpen(false);
    }, []);
  
    useEffect(() => {
      if(dateSelection == "range"){
        if(selectedDate[0] != null)changeInputValue(`${selectedDate[0]}`, `${selectedDate[1]}`);
      }else{
        if(selectedDate != null) changeInputValue(`${selectedDate}`, `${selectedDate}`);
      }
      onDateChange
      if(dateSelection == "range"){
        if(selectedDate[0] != null ||  selectedDate[1] != null)applyClick()
        }else{
          if(selectedDate != null) applyClick()
      }
    },[selectedDate,applyClick]);
  

    console.log("Date Range Status : ",activeShortcut)    

    useEffect(() => {
      setDateRangeType(activeShortcut)
    },[activeShortcut])
    return (
        <div>
      

      <Page>
      <Tooltip title={inputValue.length == 0 ? "select date":inputValue}><TextField onKeyDown={(event:any) => {if (event.key === "ArrowDown")inputClick() }} onClick={inputClick} className="date-filter-input" defaultValue={inputValue} placeholder={`${inputValue.length == 0 ? "Date":""}`} value={inputValue} style={{fontSize:'13px'}} InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <IconButton>
                                  <CalenderIcon aria-describedby={id}/>
                                </IconButton>
                              </InputAdornment>
                            ),
                            style:{color:'#B3B5B7',height:'38px'}
                          }}/>
                          </Tooltip>
        <Popup isOpen={isDattepickerOpen} onClose={onClose} responsive={respPopup} cssClass={`${dateSelection == "date" ? "singleDatePicker" : ""} datePickerPopup`}>
          <div className="custom-date-filter-container">
            <div className="custom-date-filter-header">
              <div className="mbsc-col-sm-4 mbsc-push-sm-8 demo-date-filtering-dates" style={{display:'flex'}}>
                <div className="demo-date-filtering-inputs">
                  <div hidden={true}>
                    {/* <Select
                    data={myData}
                    label="Date range"
                    inputProps={{
                      labelStyle: 'stacked',
                      inputStyle: 'box',
                    }}
                    responsive={respSelect}
                    touchUi={true}
                    value={selected}
                    onChange={onChange}
                  /> */}
                  </div>
                  {dateSelection == "range" && <><Typography sx={{paddingBottom:'10px'}}>Date Type</Typography>
                  <Box className="date-range-buttons">
                        {myData.map((item:any) => (
                                <Button
                                    key={item?.value}
                                    className={`date-range-button ${activeShortcut === item?.value ? 'active' : 'date-range-buttonnormal'}`}
                                    onClick={() => onChange(item.value)}
                                >
                                    {item.text}
                                </Button>
                            ))}
                    </Box></>}

                  {/* <div className="custom-date-inputs">
                  <Input
                    ref={startRef}
                    disabled={disabledInput}
                    label="Start"
                    labelStyle="stacked"
                    inputStyle="box"
                    className="demo-date-filtering-range-input"
                    placeholder="Please Select..."
                  ></Input>
                  <Input
                    ref={endRef}
                    disabled={disabledInput}
                    label="End"
                    labelStyle="stacked"
                    inputStyle="box"
                    className="demo-date-filtering-range-input"
                    placeholder="Please Select..."
                  ></Input>
                  </div> */}

                  {dateSelection == "range" &&
                    <Box className="date-range-fields" style={{marginTop:'28px'}}>

                    <TextField
                        size="small"
                        name="fromdate"
                        // style={{ width: '100%', color:'#000',fontSize:'12px'}}
                        value={dateSelection == "range" ? selectedDate[0] != null ? moment(selectedDate[0]).format('MMM D, YYYY') : "": moment(selectedDate).format('MMM D, YYYY')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><span style={{fontSize:'12px', fontWeight:'400'}}>From:</span></InputAdornment>,
                          sx: {
                              color: '#000', // Text color
                              fontSize: '12px', // Font size
                              '&.Mui-disabled': {
                                  color: '#000', // Color for disabled state
                              },
                          },
                      }}
                      sx={{
                          width: '100%',
                      }}
                        disabled={true}
                    />

                    <TextField
                        size="small"
                        name="todate"
                        // style={{ width: '100%', color:'#000',fontSize:'12px'}}
                        value={dateSelection == "range" ? selectedDate[1] != null ? moment(selectedDate[1]).format('MMM D, YYYY') : "" : moment(selectedDate).format('MMM D, YYYY')}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">To:</InputAdornment>,
                        // }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><span style={{fontSize:'12px', fontWeight:'400'}}>To:</span></InputAdornment>,
                          sx: {
                              color: '#000', // Text color
                              fontSize: '12px', // Font size
                              '&.Mui-disabled': {
                                  color: '#000', // Color for disabled state
                              },
                          },
                      }}
                      sx={{
                          width: '100%',
                      }}
                        disabled={true}
                    />

                    </Box>
                  }
                </div>
              </div>
              <Box sx={{paddingX:'15px'}}>
              <div className="mbsc-col-sm-8 mbsc-pull-sm-4 daterangecontrolui">
                <Datepicker className="daterangecontrolui"
                  controls={['calendar']}
                  select={`${dateSelection == "range" ? "range" : "date"}`}
                  display="inline"
                  showRangeLabels={false}
                  pages={2}
                  startInput={start}
                  endInput={end}
                  returnFormat="iso8601"
                  showOnClick={false}
                  showOnFocus={false}
                  value={selectedDate}
                //   onChange={onDateChange}
                onChange={onDateChangeHandler}
                // touchUi={true}
                ></Datepicker>
                
              </div>
              </Box>

              {/* <div className="demo-date-filtering-desktop-buttons mbsc-button-group-justified" style={{display:'flex',justifyContent:'end'}}>
                  <Button className="apply-button" onClick={applyClick}>
                    Apply
                  </Button>
                  <Button className="cancel-button" onClick={cancelClick}>
                    Cancel
                  </Button>
                </div> */}
            </div>
          </div>
        </Popup>
      </Page>
      </div>
    );
  };
  
  export default DateRangePickerSelect;