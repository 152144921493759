import React, { useEffect, useState } from 'react'
import { Paper, Box, Typography, ToggleButtonGroup, ToggleButton, Stack, FormControl, Select, MenuItem, Pagination } from '@mui/material'
import { ReactComponent as TargetIcon } from '../../../assets/icons/target-legend.svg'
import { ReactComponent as GoodIcon } from '../../../assets/icons/going-good.svg'
import { ReactComponent as CriticalIcon } from '../../../assets/icons/critical-legend.svg'
import { ReactComponent as AttentionIcon } from '../../../assets/icons/attention-legend.svg'
import './ViewContainer.scss'
import DayView from '../DayView/DayView'
import WeekView from '../WeekView/WeekView'
import { getAllocatedMandateCount, getMandateByDetails } from '../../../services/WorkManagementService'
import moment from 'moment'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AppMandateDropdown from '../../AppComponents/AppMandateDropdown/AppMandateDropdown'
import { AppButton } from '../../AppComponents/AppButton'
import { PRIMARY2, TERTIARY12 } from '../../../utils/constants'
import BulkAllocationRecuriterTable from '../BulkAllocationViewContainer/BulkAllocationRecuriterTable'
import BulkAllocationPopup from '../BulkAllocationPopup/BulkAllocationPopup'
import { useAppSelector } from '../../../store/hooks'

const ViewContainer = ({ selectedDate, viewType, selectedMonth }: any) => {

  const [recruiterContent, setRecruiterContent] = useState<any[]>([])
  const [recruiterContentInfo, setRecruiterContentInfo] = useState<any>({})
  const [isMyTeam, setIsMyTeam] = useState<boolean>(false)
  const [recruiterReqObj, setRecruiterReqObj] = useState<any>({})
  const [stageId, setStageId] = useState<number>(3)
  const [bulkStageId, setBulkStageId] = useState<number>(3)
  const [searchFilterValue, setSearchFilterValue] = useState<string>("")
  const [initialState, setInitialState] = useState(false)
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
  const [paginationCountSize, setPaginationCountSize] = useState({
    pageSize: 10,
    pageCount: 1
  })

  const getAllocatedMandate = async () => {
    let reqObj:any = {}

    if(viewType === "W"){
      reqObj = {
        dayFilter: null,
        fromDate: selectedDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: selectedDate.clone().startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
        myTeam: isMyTeam,
        viewType: "W",
        stageId: (stageId == 4 || stageId == 11) ? 4 : stageId,        
      }
      if(stageId == 4 || stageId == 11)reqObj["ccm"] = stageId == 4 ? 1 : stageId == 11 ? 2 :"";
    } else if(viewType === "CW"){
      reqObj = {
        dayFilter: null,
        fromDate: selectedDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: selectedDate.clone().startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
        myTeam: isMyTeam,
      }
    }else if(viewType === "CM"){
      reqObj = {
        dayFilter: null,
        fromDate: moment().month(selectedMonth).date(1).format("YYYY-MM-DD"),
        endDate: moment().month(selectedMonth).date(1).endOf('month').format("YYYY-MM-DD"),
        myTeam: isMyTeam,
      }
    }else {
      reqObj = {
        dayFilter: moment(selectedDate).format('YYYY-MM-DD'),
        fromDate: null,
        endDate: null,
        myTeam: isMyTeam,
      }
    }
    reqObj["pageSize"] = paginationCountSize.pageSize
    reqObj["pageNumber"] = paginationCountSize.pageCount
    reqObj["searchKeyword"] = searchFilterValue
    setRecruiterReqObj({...reqObj})
    const response = await getAllocatedMandateCount(reqObj)
    if (response?.status === 200) {
      setRecruiterContent(response.data?.result?.list || [])
      setRecruiterContentInfo(response.data?.result)
    }
  }

  useEffect(() => {
    if(initialState)getAllocatedMandate()
  }, [selectedDate, selectedMonth, isMyTeam, stageId, paginationCountSize])

    const [typingTimer, setTypingTimer] = useState(null);
    const typingInterval = 1000; // Time in milliseconds (1 seconds)

    // Function to be called after the user stops typing
    const doneTyping = () => {
      getAllocatedMandate()
    };

    useEffect(() => {
      if(initialState){          
          // Clear the timer on every input change
          if (typingTimer) {
            clearTimeout(typingTimer);
          }
          // Set a new timer to call doneTyping after 2 seconds
          const timer:any = setTimeout(doneTyping, typingInterval);
          setTypingTimer(timer);
          // Cleanup function to clear the timer if the component unmounts or inputValue changes
          return () => clearTimeout(timer);
      }
      setInitialState(true)
       
    }, [searchFilterValue]);

  const [bulkAllocationChecked, setBulkAllocationChecked] = React.useState(false); 

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const [selectedMandate, setselectedMandate] = useState<any>()    
    const [bulkAllocationPopupStatus, setBulkAllocationPopupStatus] = useState(false);
    const [bulkAllocationList, setBulkAllocationList] = useState([]);
    const [callAllocationList, setCallAllocationList] = useState("")

    const getMandateAllocationList = async (manId:any)=> {    
      let reqObj:any = {}
      if(viewType === "W"){
        reqObj = {
          dayFilter: null,
          fromDate: selectedDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
          endDate: selectedDate.clone().startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
          myTeam: false,
          viewType: "W",
          stageId: (bulkStageId == 4 || bulkStageId == 11) ? 4 : bulkStageId,     
          "mandateId": manId   
        }
        if(bulkStageId == 4 || bulkStageId == 11)reqObj["ccm"] = bulkStageId == 4 ? 1 : bulkStageId == 11 ? 2 :"";    
      }else{
        reqObj = {
            "dayFilter": recruiterReqObj?.dayFilter,
            "fromDate": recruiterReqObj?.fromDate,
            "endDate": recruiterReqObj?.endDate,
            "myTeam": false,
            "mandateId": manId
        }
      }
        const response = await getMandateByDetails(reqObj)
        if(response?.status === 200){
            setBulkAllocationList(response?.data?.result?.list)
        }else{
            setBulkAllocationList([])
        }
      }


      useEffect(() => {
        if(!bulkAllocationPopupStatus){
          if(selectedMandate){
            getMandateAllocationList(selectedMandate?.value)
        }else{
            setBulkAllocationList([])
        }
        }
      },[selectedMandate,callAllocationList,bulkAllocationPopupStatus,recruiterReqObj,bulkStageId])
      useEffect(() => {
        if(!bulkAllocationChecked)getAllocatedMandate()
      },[bulkAllocationChecked])
  
  return (
    <>
          <Paper className='view-box-style' elevation={1}>
            <div id="scrollableContent">
            <Box display='flex' justifyContent='space-between' className='legend-box'>
              <Box className="allocationViewTopCardLeft">
                <h1>Users ({recruiterContentInfo?.totalRecords})</h1>
                <Box style={{display:"flex",alignItems:"center",gap:"100px"}}>
                  {/* <p>Recruiters (150)</p> */}
                  <Box style={{gap:"30px"}} className='legend-box-sub'>
                    <span className='legend-group'>
                      <span style={{backgroundColor:"#727376"}}></span>
                      <Typography className='legend-label'> Target</Typography>
                    </span>
                    <span className='legend-group'>
                      <span style={{backgroundColor:"#2BAD63"}}></span>
                      <Typography className='legend-label'> Going Good</Typography>
                    </span>
                    <span className='legend-group'>
                      <span style={{backgroundColor:"#f34334"}}></span>
                      <Typography className='legend-label'> Critical</Typography>
                    </span>
                    <span className='legend-group'>
                      <span style={{backgroundColor:"#fcb12b"}}></span>
                      <Typography className='legend-label'> Need Attention</Typography>
                    </span>
                  </Box>
                </Box>
              </Box>
              {(userPermissions?.work_management_module?.view?.bulk?.selected && userRole != "Admin") && <Stack direction="row" spacing={1} alignItems="center" className='workMagBulkAllBtn'>
                {/* <Typography>Off</Typography> */}
                <AntSwitch defaultChecked={bulkAllocationChecked}  onChange={(e:any) => {setBulkAllocationChecked(e.target.checked)}} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>Bulk Allocation</Typography>
              </Stack>}
              {(userRole == "Admin") && <Stack direction="row" spacing={1} alignItems="center" className='workMagBulkAllBtn'>
                {/* <Typography>Off</Typography> */}
                <AntSwitch defaultChecked={bulkAllocationChecked}  onChange={(e:any) => {setBulkAllocationChecked(e.target.checked)}} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>Bulk Allocation</Typography>
              </Stack>}
            </Box>
            {bulkAllocationChecked ? 
            <>
            <Box className="bulkAllocationBlk">
                <Box>
                  <AppMandateDropdown
                          label=''
                          required={false}
                          value={selectedMandate}
                          onChange={(e: any) => {
                              setselectedMandate(e)
                          } } 
                      />
                </Box>
                <AppButton
                  variant='contained'
                  disabled={!selectedMandate}
                  bgcolor={PRIMARY2}
                  margin={5}
                  textColor={TERTIARY12}
                  className='class-new-button'
                  onClick={() => {setBulkAllocationPopupStatus(true)}}
                  tooltip='Manage Allocation'
                >
                  Manage Allocation
                </AppButton>
              </Box>
              <Box>
                <BulkAllocationRecuriterTable bulkAllocationList={bulkAllocationList} setCallAllocationList={setCallAllocationList} selectedMandateId={selectedMandate} selectedDate={selectedDate} setBulkStageId={setBulkStageId} viewType={viewType}/>
              </Box>              
            </> :
            <>
              { viewType == 'W' ? 
                <WeekView selectedDate={selectedDate} setStageId={setStageId} viewType={viewType} recruiterContent={recruiterContent} getAllocatedMandate={getAllocatedMandate} isMyTeam={isMyTeam} setIsMyTeam={setIsMyTeam} recruiterReqObj={recruiterReqObj} setSearchFilterValue={setSearchFilterValue} searchFilterValue={searchFilterValue}/>
                : <DayView selectedDate={selectedDate} viewType={viewType} recruiterContent={recruiterContent} getAllocatedMandate={getAllocatedMandate} isMyTeam={isMyTeam} setIsMyTeam={setIsMyTeam} recruiterReqObj={recruiterReqObj} setSearchFilterValue={setSearchFilterValue} searchFilterValue={searchFilterValue}/>
              }
            </>
            }
            {bulkAllocationPopupStatus && <BulkAllocationPopup bulkAllocationPopupStatus={bulkAllocationPopupStatus} setBulkAllocationPopupStatus={setBulkAllocationPopupStatus} selectedMandateId={selectedMandate}/>}
            </div>
            <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={paginationCountSize.pageSize}
									label="Age"
									onChange={(event:any) => {setPaginationCountSize(prevState => ({...prevState,["pageSize"]: event.target.value}))}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={recruiterContentInfo.totalPages} page={paginationCountSize.pageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setPaginationCountSize(prevState => ({...prevState,["pageCount"]: pageNumber}))}}/>
					</Box>
          </Paper>
    </>
  )
}

export default ViewContainer
