/** @jsxImportSource @emotion/react */
import { Box, Button, Grid } from '@mui/material'
import { Breadcrumb } from '../components/Breadcrumb'
import DashboardEvents from '../components/Dashboard/OverviewDashboard/DashboardEvents/DashboardEvents'
import "../App.scss"
import { useAppDispatch, useAppSelector } from '../store/hooks'
import GppBadIcon from '@mui/icons-material/GppBad';
import { useEffect, useState } from 'react'
import { mandateUnAuthorized } from '../features/mandateDetail/DrawerReducer'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

function WelcomeScreen() {
  const welcomeCardData = useAppSelector((state: any) => state.SystemConfig.welcomeConfigData);
  const userFirstName = useAppSelector((state: any) => state.UserDetail.firstName);
  const userId = useAppSelector((state: any) => state.UserDetail.userId);
  const unAuthorizedMessage = useAppSelector((state) => state.mandatedrawer.unAuthorizedError);
  const [hideUnAuthorized, setHideUnAuthorized] = useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [cdm, setCdm] = useState<boolean>(false);
  setTimeout(() => {
    setHideUnAuthorized(false)
    dispatch(mandateUnAuthorized({ unAuthorizedError: "" }))
  }, 5000);
  useEffect(() => {
    if (userId) {
      setCdm(true);
    }
  }, [userId])  
  if (!cdm) {
    return <div>Loading..</div>
  }

  return (
    <div className='dashboardScreenSect'>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-2Q9QZKWW6B"></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-2Q9QZKWW6B',{
          'user_id': '${userId}'
        });
        `}
        </script>
      </Helmet>
      {(hideUnAuthorized && (unAuthorizedMessage && unAuthorizedMessage.length > 0)) && <p className='unAuthorizedMessage'><GppBadIcon />{unAuthorizedMessage}</p>}
      <Box className="welcomeCardSect marb0">        
        {welcomeCardData != undefined &&  welcomeCardData?.length >0 && <div dangerouslySetInnerHTML={{__html: welcomeCardData[0]?.configValue?.replace("{loggedInUserName}", userFirstName)}}></div>}
      </Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm:1, md: 2 }}>
        <Grid item xs={12} lg={6}>
          <DashboardEvents/>
        </Grid>
      </Grid>
    </div>
  )
}

export default WelcomeScreen
