import { ReactComponent as MandatesIcon } from '../assets/icons/mandates.svg'
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboardIcon.svg'
import {ReactComponent as CandidateSearchIcon} from '../assets/icons/candidate-search.svg'
import { ReactComponent as MyTasksIcon } from '../assets/icons/my-task.svg'
import { ReactComponent as SchedulerIcon } from '../assets/icons/scheduler.svg'
import { ReactComponent as ReportsIcon } from '../assets/icons/report.svg'
import { ReactComponent as UserManagementIcon } from '../assets/icons/user-management.svg'
import { ReactComponent as SettingIcon } from '../assets/icons/setting.svg'
import { ReactComponent as ChatGPTIcon } from '../assets/icons/chatgpt-icon.svg'
import { ReactComponent as WorkManagementIcon } from '../assets/icons/work_manag.svg'
import { ReactComponent as OverAllReport } from '../assets/icons/OverallReportNew.svg'

import { Route } from '../types/Route'

const routesConfig: Array<Route> = [
  {
    key: 'router-dashboard',
    title: 'Dashboard',
    description: 'Dashboard',
    isEnabled: false,
    hideMenu: true,
    expanded: true,
    staticMenu: true,
    menuType: 2,
    subRoutes: [   
      {
        key: 'router-submenu-dashboard',
        title: 'Dashboard',
        description: 'Dashboard',
        isEnabled: true,
        expanded: true,
        icon: DashboardIcon,
        appendDivider: false,
        parentKey: "router-Dashboard",
        childList: ["dashboard","jobOrderAgeing"],
        subRoutes: [ {
          key: 'router-overall-dashboard',
          title: 'Overview Dashboard',
          description: 'Overview Dashboard',
          path: '/dashboard',
          isEnabled: true,
          appendDivider: false
        },
        {
          key: 'application-usage',
          title: 'Application Usage',
          description: 'Application Usage',
          path: '/applicationUsage',
          isEnabled: true,
          appendDivider: false
        }
        ]
      }
    ],
  },
  {
    key: 'router-ws',
    title: 'Work station',
    description: 'Work station',
    isEnabled: false,
    expanded: true,
    staticMenu: true,
    menuType: 2,
    subRoutes: [
      {
        key: 'router-mandates',
        title: 'Mandates',
        description: 'Mandates',
        path: '/mandates',
        isEnabled: true,
        icon: MandatesIcon,
        appendDivider: false,
        menuHideLable:"mandates"
      },
      {
        key: 'router-candidate-listing',
        title: 'Candidate Search',
        description: 'Candidate Search',
        path: '/candidate-listing',
        isEnabled: true,
        icon: CandidateSearchIcon,
        appendDivider: false,
        menuHideLable:"candidate_search"
      },
      {
        key: 'router-work-management',
        title: 'Work Management',
        description: 'My Tasks',
        path: '/work-management',
        isEnabled: true,
        icon: WorkManagementIcon,
        appendDivider: false,
        menuHideLable:"work_management"
      },
        
      {
        key: 'router-my-tasks',
        title: 'My Tasks',
        description: 'My Tasks',
        path: '/my-tasks',
        isEnabled: true,
        icon: MyTasksIcon,
        appendDivider: false,
        menuHideLable:"my_tasks"
      },
      {
        key: 'router-scheduler',
        title: 'Scheduler',
        description: 'Scheduler',
        path: '/scheduler',
        isEnabled: true,
        icon: SchedulerIcon,
        appendDivider: false,
        menuHideLable:"scheduler"
      },
      {
        key: 'router-chatgpt',
        title: 'ChatGPT',
        description: 'ChatGPT',
        path: '/chatgpt',
        isEnabled: true,
        icon: ChatGPTIcon,
        appendDivider: false,
        menuHideLable:"chatgpt"
      },
    ],
  },
  {
    key: 'router-submenu-data-source',
    title: 'Data Source',
    description: 'Data Source',
    isEnabled: false,
    expanded: true,
    staticMenu: true,
    menuType: 2,
    subRoutes: [   
      {
        key: 'router-submenu-reports',
        title: 'Reports',
        description: 'Reports',
        isEnabled: true,
        expanded: false,
        icon: ReportsIcon,
        appendDivider: false,
        parentKey: "router-data-source",
        childList: ["candidatePipeline","analyticsSpace"],
        subRoutes: [ {
          key: 'router-overall-reports',
          title: 'Analytics Space',
          description: 'Analytics Space',
          path: '/analyticsSpace',
          isEnabled: true,
          appendDivider: false,
          menuHideLable:"analytics_space"
        },
        {
          key: 'router-reports',
          title: 'Candidate Pipeline Report',
          description: 'Reports',
          path: '/candidatePipeline',
          isEnabled: true,
          appendDivider: false,
          menuHideLable:"candidate_pipeline_report"
        }  ]
      },
      {
        key: 'router-user-management',
        title: 'User Management',
        description: 'User Management',
        path: '/user-management',
        isEnabled: true,
        expanded: false,
        icon: UserManagementIcon,
        appendDivider: false,
        parentKey: "router-user-management",
        childList: ["users","roleAccess"],
        subRoutes: [ {
          key: 'router-user-management',
          title: 'Artemis Users',
          description: 'Users',
          path: '/users',
          isEnabled: true,
          appendDivider: false,
          menuHideLable:"artemis_users"
        },
        {
          key: 'router-reports',
          title: 'Roles & Access',
          description: 'RoleAccess',
          path: '/roleAccess',
          isEnabled: true,
          appendDivider: false,
          menuHideLable:"roles_access"
        }
      ]
      },
      // {
      //   key: 'router-configuration',
      //   title: 'Configuration',
      //   description: 'Configuration',
      //   path: '/configuration',
      //   isEnabled: true,
      //   icon: SettingIcon,
      //   appendDivider: false,
      //   menuHideLable:"configuration"
      // },
    ],
  },
]

export default routesConfig
