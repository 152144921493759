import {Box, Paper, Typography ,List,ListItem,ListItemIcon,ListItemText, Tooltip} from '@mui/material'
import React from 'react'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon.svg'
import { ReactComponent as WorkManagementIcon } from '../../../assets/icons/work-management.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { ReactComponent as AllocationCalenderIcon } from '../../../assets/icons/allocation-calender.svg'

import '../MandateTable/Allocation/Allocation.scss'

import moment from 'moment'
import { jsonDataFormater } from '../../../utils/common-utils'

const dateComp = (dueDate:any) => {
  const todayDate = moment().format("YYY-MM-DD")
  const dataDate = moment(dueDate).format("YYY-MM-DD")
  return todayDate > dataDate
}

const BulkAllocationDetailCard = ({eachData}:any) => {
  const locationData = jsonDataFormater(eachData?.allocationLocations) || []
  const locString = locationData.map((loc:any) => loc.primary_location).join(',')
  return (
   <Box>
    <List className='allocation-list'>
      <ListItem disablePadding>
        <ListItemIcon className='allocation-card-list-icon'>
        <Tooltip title={"Location"} placement="left" arrow ><LocationIcon /></Tooltip>
        </ListItemIcon>
        <Tooltip title={locationData.length > 0 ? locString : "-"} placement="right" arrow >
        <Box className={`mandateTableLocationCardSect ${locString.length > 20 ? "ellipseText":""}`}>
            <Box className="mandateTableLocationCard">
              {locationData.map((locationItem:any, locIndex:number) => {
                return (
                  <ListItemText key={locIndex} className={locationItem?.status == 65 ? "unassignLocStyle" : ""} primary={locationItem?.primary_location} />
                )
              })}              
            </Box> 
            {locString.length > 20 && <span>...</span>}  
          </Box>
        </Tooltip>
        {/* <Tooltip title={eachData?.allocationLocations ? eachData?.allocationLocations : ""} placement="right" arrow >
          <ListItemText className={eachData?.status == 65 ? "unassignLocStyle" : ""} primary={eachData?.allocationLocations ? eachData?.allocationLocations : "-"} />
        </Tooltip> */}
      </ListItem>

      <ListItem disablePadding>
        <ListItemIcon className={`allocation-card-list-icon ${dateComp(eachData?.dueDate) ? "due-date" : ""}`}>
        <Tooltip title={"Due Date"} placement="left" arrow ><AllocationCalenderIcon /></Tooltip>
        </ListItemIcon>
        <Tooltip title={eachData?.dueDate ? eachData?.dueDate: ""} placement="right" arrow >
          <ListItemText primary={`${eachData?.dueDate ? "("+eachData?.dueDate+")" : "-"}`} />
        </Tooltip>
      </ListItem>

      
      <ListItem disablePadding>
        <ListItemIcon className='allocation-card-list-icon work-icon'>
        <Tooltip title={"Allocated by"} placement="left" arrow ><WorkManagementIcon/></Tooltip>
          </ListItemIcon>
          <Tooltip title={eachData?.allocatedByName} placement="right" arrow >
            <ListItemText primary={eachData?.allocatedByName} />
          </Tooltip>
      </ListItem>

      <ListItem disablePadding>
        <ListItemIcon className='allocation-card-list-icon calender'>
        <Tooltip title={"Allocated Date"} placement="left" arrow ><CalenderIcon/></Tooltip>
          </ListItemIcon>
          <Tooltip title={eachData?.allocatedDate ? moment(eachData?.allocatedDate).format("DD MMM YYYY"): ""} placement="right" arrow >
            <ListItemText primary={eachData?.allocatedDate ? moment(eachData?.allocatedDate).format("DD MMM YYYY"): "-"} />
          </Tooltip>
      </ListItem>      
    </List>

   </Box>
  )
}

export default BulkAllocationDetailCard