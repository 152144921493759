/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback, useRef } from 'react'
import { Breadcrumb } from '../components/Breadcrumb'
import { MandatesFilter } from '../components/Mandates/Filter'
import { GridView } from '../components/Mandates/GridView'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import DrawerData from '../components/Mandates/MandateDetail/DrawerData/DrawerData'
import { getUserDetails } from '../services/UserService'
import { useForm, FormProvider } from 'react-hook-form'
import { MandateService } from '../services/MandateService'
import _ from 'lodash'
import { Box } from '@mui/material'
import { Loading } from './Loading'
import { useLocation } from 'react-router'
import { drawerclose, listStatus, expanddrawer, mandateUnAuthorized } from '../features/mandateDetail/DrawerReducer'
import {openNotification, resetNotification} from "../features/notification/notificationReducer"
import ListView from '../components/Mandates/ListView/ListView'
import { TaskManagementService} from '../services/TaskService'
import { useNavigate } from 'react-router-dom'
import { leftdrawertoggleclose } from '../features/toggle/ToggleReducer'

export interface Mandatedetail {
  createdByName: string
  xphenoId: string
  id: number
  clientName: string
  title: string
  clientSpoc: string
  requestStartDate: string | null
  requestEndDate: string | null
  createdAt: string | null
  mandateId: string | null
  billRate: number
  jobTitle: string
  clientId: string
  clientRequestNo: null | string
  education: string | null
  jobDescription: string | null
  secondarySkills: string | null
  targetSubmission: number | null
  shift: number
  Experience: string
  Mode: string | null
  createdBy: string
  expRangeStart: number
  expRangeEnd: number
  fileName: string
  jobMode: string | null
  jobOrders: number | null
  jobType: string | null
  payRate: null | string
  primarySkills: string | null
  status: number
  updatedBy: string | null
  locations: string
  totalMandateLocationCount: number
  collaborators: any
}
export interface ListState {
  page: number
  size: number
  filters: any
  commonFilterText: string,
  sortColumn?:any,
  sortDirection?:any
}

interface ISelectForm {
  commonFilterText: string
}

export const Mandates = () => {
  const methods = useForm()
  const defaultValues: ISelectForm = {
    commonFilterText: '',
  }
  async function getUserData (){
    const responseData = await getUserDetails()
    const  recruiter= responseData?.roleSlug?.toLowerCase().trim()=== 'recruiter'
    setIsRecruiter(recruiter)
  }
  const navigate = useNavigate()
  
  const manadateDrawerExpand = useAppSelector((state) => state.mandatedrawer.FullPage)
  const listStatusData = useAppSelector((state) => state.mandatedrawer.isApproval)
  const recruiterStatus = useAppSelector((state) => state.mandatedrawer.isRecruiter)
  const hidelayout = useAppSelector((state) => state.mandatedrawer.hideGridlayout)
  const openNotificationState = useAppSelector((state)=>state?.notificationState)
  const isApprovalState = useAppSelector((state)=>state?.mandatedrawer?.approvalMandate)
  const uploadMandateStatus = useAppSelector((state) => state.mandatedrawer.uploadMandate)
  const location =useLocation();
  const pathname = useLocation()?.pathname?.split('/')[1]
  const search = useLocation()?.search
  const mId: any = new URLSearchParams(search).get('id')
  const notificationState = location?.state
  const [mandateRedirectState,setMandateRedirectState] = useState<any>(location)
  const mandateTableType = useAppSelector((state:any) => state?.profilePersistor.mandateTableType)
  const [searchvalues, setSearchvalues] = useState(defaultValues)
  const [mandatelist, setMandateList] = useState<Mandatedetail[]>([])
  const [mandateCount, setMandateCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingNextPage, setLoadingNextPage] = useState(false)
  const [drawerData, setdrawerData] = useState({})
  const [noParse, setNoParse] = useState(false)
  const [parsemsg, setParsemsg] = useState('')
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const showLoadingNextPage = () => setLoadingNextPage(true)
  const hideLoadingNextPage = () => setLoadingNextPage(false)
  const dispatch = useAppDispatch()
  const [viewtype, setViewType] = useState((mandateTableType != undefined && mandateTableType.length > 0) ? mandateTableType : (mandateRedirectState.state != null && typeof mandateRedirectState.state === 'object') ? mandateRedirectState.state.viewType : "grid")
  // const localStoreApproval:any = localStorage?.getItem('persist:root') 
  // console.log(JSON.parse(JSON.parse(localStoreApproval)["mandatedrawer"]).isApproval)
  const [isApprovalList,setIsApprovalList]= useState(listStatusData === null ? false : listStatusData)
  const [isRecruiter, setIsRecruiter] = useState(recruiterStatus === null ? false : recruiterStatus)
  const [apicalled, setApicalled]= useState(false)
  const [horizontalMemberList, setHorizontalMemberList] = useState<any[]>([])
  const [verticalMemberList, setVerticalMemberList] = useState<any[]>([])
  const [listState, setListState] = useState<ListState>({
    page: 0,
    size: 10,
    filters: { reqStartDate: '', reqEndDate: '', commonFilterText: '', clientName: null ,approver: !isApprovalList ? 0:1,status:'' },
    commonFilterText: '',
    sortColumn: '',
    sortDirection: ''
  })
  const mandateTableHeaderColumn = useAppSelector((state:any) => state?.profilePersistor.mandateTableColumn)
  const [columnFields, setColumnFields] = useState<any>((mandateRedirectState.state != null && typeof mandateRedirectState.state === 'object') ? mandateRedirectState.state.columnFields : (mandateTableHeaderColumn != undefined && mandateTableHeaderColumn.length > 0) ? mandateTableHeaderColumn : {
    "Client Name": true,
    "Created By": true,
    "Created On": true,
    "Job Orders": false,
    "Assignees": true,
    "Due Date": false,
    "Status": false
  })
  const [pageCountStatus, setPageCountStatus] = useState(1)
  useEffect(() => {
    if(manadateDrawerExpand == false && mId == null && notificationState == "allocationRedirection")setIsApprovalList(false)
  },[uploadMandateStatus])
  useEffect(()=>{
    if(manadateDrawerExpand == false){
      getUserData()
      setApicalled(true)
      getAssigneeList()
    }
    
  },[manadateDrawerExpand])

  useEffect(() => {
      if(mandatelist?.length === 0 || notificationState == "notificationRedirection"){
        if (mId) {
          handledetial(mId, notificationState)
          // manadateDrawerExpand ? dispatch(expanddrawer()) : dispatch(draweropen({FullPage: false}))
          dispatch(expanddrawer())
          dispatch(leftdrawertoggleclose())
        } else {
          dispatch(drawerclose())
          dispatch(leftdrawertoggleclose())
        }
      }else{
        if(mId == null) dispatch(drawerclose())
      }
      dispatch(resetNotification())
    
  }, [mId])

  const loadFirstPage = useCallback(async (flag='') => {
    await MandateService.getMandateList({
      page: 0,
      size: listState.size,
      filters: {approver: isRecruiter? !isApprovalList ? 'recuiter':'pending' :'',},
      pathname
    }).then((res) => {
      const mandates = _.get(res, 'data.result.list', []) || []
      setMandateCount(_.get(res, 'data.result.totalRecords', 0))

      if(flag==="mandate-detail"){
        setMandateList([...mandates])
      }else{
        const existingIds = _.map(mandatelist, 'id')
        const newList = _.filter(mandates, (mandate: any) => {
          return !_.includes(existingIds, mandate.id)
        })
        setMandateList([...newList, ...mandatelist])
      }
    })
  },[mandateCount,mandatelist])
  
  useEffect(() => {
    const { page } = listState
    if (page > 0) {
      showLoadingNextPage()
    } else {
      showLoading()
    }
    const gettasklist = async () => {
    const filterData ={...listState,filters:{...listState.filters, approver: !isApprovalList ? 1 :0},page: viewtype === "list" ? (page - 1) : page, sortColumn: viewtype === "list" ? listState?.sortColumn : "", sortDirection: viewtype === "list" ? listState?.sortDirection : "",
    pathname}

      await MandateService.getMandateList(filterData).then((res) => {
        const mandates = _.get(res, 'data.result.list', []) || []
        hideLoading()
        hideLoadingNextPage()
        setMandateCount(_.get(res, 'data.result.totalRecords', 0))
        if (page === 0) {
          setMandateList(mandates)
        } else {
          viewtype === "list" ? setMandateList(mandates) : setMandateList([...mandatelist, ...mandates])
        }
      })
    }
    const timeoutId = setTimeout(() => {
      apicalled && gettasklist()
    }, 1000);
    return () => clearTimeout(timeoutId);    
  }, [listState,isRecruiter,isApprovalState])

  useEffect(() => {
    setListState((listState) => {
      return {
        ...listState,
        page: 0,
        size: 10,
        sortColumn: "",
        sortDirection: ""
      }
    })
  },[isApprovalList])

  const onCreateSuccess = useCallback(() => {
    loadFirstPage()
  }, [mandatelist])

  const loadNextPage = () => {
    setListState((listState) => {
      return {
        ...listState,
        page: listState.page + 1,
      }
    })
  }

  const loadNextPageList = (tableObj:any) => {
    setListState((listState) => {
      return {
        ...listState,
        page: tableObj.pageCount,
        size: tableObj.pageSize,
        sortColumn: tableObj.sortColumn,
        sortDirection: tableObj.sortDirection
      }
    })
  }

  const onFilterChange = useCallback(
    (filters: any) => {
      if (JSON.stringify(filters) === JSON.stringify(listState.filters)) return
      setListState((listState) => {
        return {
          ...listState,
          page: 0,
          filters: filters,
        }
      })
    },
    [listState],
  )

  const handledetial = useCallback(async (id: number, notificationType:any) => {
    setParsemsg('')
    setdrawerData({})
    MandateService.getMandateDetail(id)
      .then((res) => {
        if (res.data === 'Mandate in parser queue') {
          console.log('Mandate in parser Queue')
          setNoParse(true)
          setParsemsg(res.data)
        } else {
          setNoParse(false)
          setParsemsg('')
          setdrawerData(res.data?.result?.data || {})
          if(new URLSearchParams(search).get('source') != null || notificationType == "notificationRedirection"){
            if(res.data?.result?.data.approvers != null && res.data?.result?.data.approvalStatus != 1){
              setIsApprovalList(true)
            }else{
              setIsApprovalList(false)
            }
          }          
        }
      })
      .catch((error) => {
        console.log('error', error.message)
        if(error?.response?.status == 403){
          dispatch(mandateUnAuthorized({unAuthorizedError: error?.response?.data?.error?.message})) 
          navigate(`/dashboard`)
        }
      })
  },[noParse,parsemsg,drawerData])
  useEffect(() => {
    dispatch(listStatus({isApproval:isApprovalList,isRecruiter}))
  }, [isApprovalList,isRecruiter])

  const getAssigneeList = async () => {

    const res:any = await TaskManagementService.getAssignedToUsers()
    if(res?.status === 200 && res?.data?.length >0){
      setVerticalMemberList(res?.data)
    }

    const horizonRes = await TaskManagementService.getHorizontalAssignedToUsers()
    if(horizonRes?.status === 200 && horizonRes?.data?.length >0){
      const data = horizonRes?.data?.filter((ele:any,index:number,self:any[])=>index===self.findIndex(x=>x.id === ele.id))
      setHorizontalMemberList(data)
    }
  }
  return (
    <>
      {!hidelayout ? (
        <>
          <Box className="mandateTitleSect">
            <Breadcrumb title={`Mandates (${mandateCount})`} />
            <Breadcrumb title={`Job Orders (${mandatelist[0]?.totalMandateLocationCount ?? 0})`} />
          </Box>
          <FormProvider {...methods}>
            <MandatesFilter
              isRecruiter={isRecruiter}
              listState={listState}
              onFilterChange={onFilterChange}
              totalRecords={mandateCount}
              onCreateSuccess={onCreateSuccess}
              setsearchvalue={setSearchvalues}
              viewtype={viewtype}
              setViewType={setViewType}
              isApprovalList={isApprovalList}
              setIsApprovalList={setIsApprovalList}
              filteredColumns={setColumnFields}
              columnFieldsData={columnFields}
            />
          </FormProvider>
          {isLoading && (
            <Box className='task-loading-container'>
              <Loading />
            </Box>
          )}
          {!isLoading && (viewtype === "grid") && (
            <GridView
              isLoadingNextPage={isLoadingNextPage}
              totalCount={mandateCount}
              data={mandatelist}
              handlePage={loadNextPage}
              handledetial={handledetial}
              drawerdata={drawerData}
              noParse={noParse}
              isApprovalList={isApprovalList}
              setIsApprovalList = {setIsApprovalList}
              searchValue={searchvalues.commonFilterText}
              parsemsg={parsemsg}
              horizontalMemberList={horizontalMemberList}
              verticalMemberList={verticalMemberList}
              loadFirstPage={loadFirstPage}
              openNotification={openNotificationState?.mandateOpen===true?openNotificationState:null}
            />
          )}
          {!isLoading && (viewtype === "list") && (
            <ListView
              isLoadingNextPage={isLoadingNextPage}
              totalCount={mandateCount}
              data={mandatelist}
              handlePage={loadNextPageList}
              handledetial={handledetial}
              drawerdata={drawerData}
              isApprovalList={isApprovalList}
              noParse={noParse}
              searchValue={searchvalues.commonFilterText}
              parsemsg={parsemsg}
              horizontalMemberList={horizontalMemberList}
              verticalMemberList={verticalMemberList}
              loadFirstPage={loadFirstPage}
              columnFields={columnFields}
              setPageCountStatus={setPageCountStatus}
              pageCountStatus={pageCountStatus}
            />
          )}
        </>
      ) : (
        <DrawerData 
          isApprovalList={isApprovalList} 
          data={drawerData} 
          noParse={noParse}  
          parsemsg={parsemsg} 
          isRecruiter={isRecruiter}
          horizontalMemberList={horizontalMemberList}
          verticalMemberList={verticalMemberList}
          handledetial={handledetial}
          loadFirstPage={loadFirstPage}
        />
      )}
    </>
  )
}

export default Mandates
