import { createSlice } from '@reduxjs/toolkit'

export interface FilterState {
  persistFilter: any;
  filterPersistance: any;
  apiTimeOutStatus: boolean
}

const initialFilterState: FilterState = {
  persistFilter: {},
  filterPersistance: {
    tab0:"",
    tab1:"",
    tab2:"",
    tab3:""
  },
  apiTimeOutStatus: false,
}

const persistFilterSlice = createSlice({
    name: 'persistFilter',
    initialState: initialFilterState,
    reducers: {
      setPersistFilter: (state,action) => {
        state.persistFilter = action.payload
      },
      resetPersistFilter: (state) => {
        state.persistFilter = {}
      },
      setFilterPersist: (state,action) => {
        state.filterPersistance = action.payload
      },
      resetFilterPersistAll: (state) => {
        return {
          ...state,
          ...initialFilterState,
        }
      },
      setCandidateApiGatewayStatus: (state,action) => {
        state.apiTimeOutStatus = action.payload
      },
    }
})

export const { 
  setPersistFilter,
  resetPersistFilter,
  setFilterPersist,
  resetFilterPersistAll,
  setCandidateApiGatewayStatus
 } = persistFilterSlice.actions
export default persistFilterSlice.reducer